
import { PresignedURLRequest, UploadFileRequest } from './UploadInfoForm'
import { getS3PresignedURL, invoiceUploadFile } from '../../api'

const UploadRequest = async (
  uploadFileRequest: UploadFileRequest,
  file: File
) => {
    const data = await uploadViaPresignedURL(file)
    if (data) {
      uploadFileRequest.s3Info = { s3ObjectKey: file.name, s3BucketName: data.bucketName }
      return invoiceUploadFile(uploadFileRequest)
    }
    //return error result of call
    return invoiceUploadFile(uploadFileRequest)
}

const uploadViaPresignedURL = async (
  file: File
) => {
  let request: PresignedURLRequest = {
    fileName: file.name,
    fileType: file.type
  }
  const { data } = await getS3PresignedURL(request)
  //make sure that data is non-null and that the fileName returned by the presigned URL lamdda matches the fileName we sent
  if (data && (data.fileName === file.name)) {
    let response = await fetch(data.url, {method:"PUT", body: file})
    if (response.ok) {
      return data
    }
  }
  return null
}

export default UploadRequest