import React, { useState } from 'react'
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control'
import * as ionjs from "ion-js"

import './JSONViewer.scss'

const IonViewer = ({ ion }: { ion: any }) => {
  const [showInteractive, setShowInteractive] = useState(true)
  const control = (
    <SegmentedControl
      selectedId={showInteractive + ''}
      ariaLabel="Default segmented control"
      options={[
        { text: 'View Tree', id: 'true' },
        { text: 'View Ion', id: 'false' },
      ]}
      onChange={(e) => setShowInteractive(e.detail.selectedId === 'true')}
    ></SegmentedControl>
  )

  if (showInteractive && isObjectOrArray(ion))
    return (
      <div>
        {control}
        <pre className="json-viewer">
          <div>
            {`${getAnnotations(ion)}{`}
            <CollapsableNode ion={ion} />
            {'}'}
          </div>{' '}
        </pre>
      </div>
    )
  else
    return (
      <div>
        {control}
        <pre>{ionjs.dumpPrettyText(ion)}</pre>
      </div>
    )
}

const CollapsableNode = ({ ion }: any) => {
  let keys: any[] = []
  if (isArray(ion)) {
    for (let i = 0; i < ion.length; i++) {
        const map = new Map()
        map.set(i, ion[i])
        keys.push(map);
    }
  } else {
    keys = ion.fields();
  }
  const [show, setShow] = useState(keys.map(() => false))
  return (
    <div>
      {keys.map(([fieldName, value]: any, i: any) => {
        const shouldShow = show[i]
        const onClick = () => {
          const nextShow = show.concat()
          nextShow[i] = !shouldShow
          setShow(nextShow)
        }
        if (!isObjectOrArray(value))
          return (
            <div key={i} className="json-indent">
              {`"${fieldName}":${getAnnotations(value)}`} {isString(value)? `"${value}"` : `${value}`}
              {i !== keys.length - 1 ? ',' : ''}
            </div>
          )
        return (
          <div key={i} className="json-indent">
            <Toggle value={shouldShow} onClick={onClick} />
            {`"${fieldName}": ${getAnnotations(value)}`}
            {isArray(value) ? '[' : '{'}
            {shouldShow ? (
              <CollapsableNode ion={value} />
            ) : (
              <span onClick={onClick} className="link">
                ...
              </span>
            )}
            {isArray(value) ? ']' : '}'}
            {i !== keys.length - 1 ? ',' : ''}
          </div>
        )
      })}
    </div>
  )
}

const Toggle = ({ value, onClick }: { value: boolean; onClick: any }) => (
  <button onClick={onClick} className="json-expand-button">
    {value ? '-' : '+'}
  </button>
)

const isObjectOrArray = (node: any): boolean =>
    node !== null && node._ionType.isContainer

const isArray = (node: any): boolean =>
    node !== null && node._ionType.name === 'list'

const isString = (node: any): boolean =>
    node !== null && node._ionType.name === 'string'

const getAnnotations = (node: any): string =>
    node._ionAnnotations.reduce((acc: string, annotation: string) => `${acc}'${annotation}'::`, "");

export default IonViewer