import React, { useState } from 'react'
import {
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Tooltip,
} from '@amzn/awsui-components-react/polaris'

import { Address, SharedStates } from '../../../types'
import DataField from '../../../Common/DataField'
import { deactivateAddress } from '../../../api'
import PendingModal from '../Modals/PendingModal'
import FailureModal from '../Modals/FailureModal'
import { addressTypes, getField } from '../../../utils'
import { DEACTIVATE_ADDRESS_TEXT } from '../../../constants'
export interface DeactivateAddressRequest {
  marketplaceId: any
  cimarronAccountId: string
  customerId: string
  ticketNumber: string
  addressId: string | undefined
}

export interface DeactivateAddressResponse {
  trackingId: string
  errors?: Error[]
  message?: string
}

export default function DeactivateAddress({
  item,
  shareStates,
}: {
  item: Address
  shareStates: () => SharedStates
}) {
  const {
    customerData: { cimarronAccounts, customerId },
  } = shareStates()
  const [showDeactivateAddressModal, setShowActivateAddressModal] =
    useState(false)
  const [ticketNumber, setTicketNumber] = useState('')
  const [validateTicketNumber, setValidateTicketNumber] = useState('')
  const deactivationModalMessage =
    'Are you sure you want to deactivate following address?'
  const reactivationMessage = 'Note: Deactivated address cannot be reactivated.'
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [trackingId, setTrackingId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const deactivateAddressErrorText = 'Deactivate address failed.'
  const isDeactivateAddressDisabled = getField('primarySite', item)
  const deactivateAddressTooltipText =
    isDeactivateAddressDisabled && DEACTIVATE_ADDRESS_TEXT
  const resetDeactivateAddressModal = () => {
    setTicketNumber('')
    setValidateTicketNumber('')
    setShowActivateAddressModal(false)
  }

  const deactivateAddressRequest = async () => {
    if (ticketNumber === '') {
      setValidateTicketNumber('Ticket number is required to proceed.')
      return
    }
    const deactivateAddressRequestData: DeactivateAddressRequest = {
      marketplaceId: cimarronAccounts[0].displayableAddressMarketplaceId,
      cimarronAccountId: cimarronAccounts[0].cimarronAccountId,
      customerId,
      ticketNumber,
      addressId: item.addressId,
    }

    const result = await deactivateAddress(deactivateAddressRequestData)

    const { data, error } = result
    if (error) {
      setErrorMessage(` Error: ${data?.message}`)
    } else {
      setTrackingId(data?.trackingId || '')
    }
    resetDeactivateAddressModal()
    setShowConfirmationModal(true)
    return
  }
  return (
    <div>
      <Tooltip text={deactivateAddressTooltipText} position="top">
        <Button
          icon="status-negative"
          onClick={() => setShowActivateAddressModal(true)}
          disabled={isDeactivateAddressDisabled}
        >
          Deactivate
        </Button>
      </Tooltip>
      {showDeactivateAddressModal && (
        <Modal
          header={` Deactivate Address`}
          visible={showDeactivateAddressModal}
          onDismiss={() => {
            setShowActivateAddressModal(false)
            resetDeactivateAddressModal()
          }}
          footer={
            <span className="awsui-util-f-r">
              <Button
                variant="link"
                onClick={() => {
                  setShowActivateAddressModal(false)
                  resetDeactivateAddressModal()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  deactivateAddressRequest()
                }}
              >
                Deactivate Address
              </Button>
            </span>
          }
        >
          {
            <>
              <div>
                <h3> {deactivationModalMessage}</h3>
                <br />
              </div>
              <ColumnLayout columns={1} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Address Id">
                      <div>{getField('addressId', item)}</div>
                    </DataField>
                  </div>
                </div>
              </ColumnLayout>
              <h4 className="awsui-util-mt-l awsui-util-mb-s">Contact info:</h4>
              <ColumnLayout columns={3} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Name">
                      <div>
                        {getField('firstName', item)} &nbsp;
                        {getField('lastName', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Primary email">
                      <div>{getField('contactEmail', item)}</div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Primary phone number">
                      <div>{getField('primaryPhoneNumber', item)}</div>
                    </DataField>
                  </div>
                </div>
              </ColumnLayout>

              <h4 className="awsui-util-mt-l awsui-util-mb-s">Address info:</h4>
              <ColumnLayout columns={4} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Address">
                      <div>
                        {getField('addressLine1', item)} &nbsp;
                        {getField('addressLine2', item)} &nbsp;
                        {getField('addressLine3', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="City & State">
                      <div>
                        {getField('city', item)} &nbsp;
                        {getField('state', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Postal code & Country">
                      <div>
                        {getField('postalCode', item)} &nbsp;
                        {getField('country', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Address type">
                      <div>
                        {
                          addressTypes.find(
                            (site) => site.id === getField('siteUseCode', item)
                          )?.label
                        }
                      </div>
                    </DataField>
                  </div>
                </div>
              </ColumnLayout>
              <br />
              <FormField label="Ticket number" errorText={validateTicketNumber}>
                <Input
                  value={ticketNumber}
                  placeholder={'Write your ticket number here'}
                  onChange={(e) => setTicketNumber(e.detail.value)}
                />
              </FormField>
              <br />
              <i> {reactivationMessage}</i>
            </>
          }
        </Modal>
      )}
      <Modal
        header={`Deactivate Address`}
        visible={showConfirmationModal}
        onDismiss={() => {
          setShowConfirmationModal(false)
          resetDeactivateAddressModal()
        }}
      >
        {trackingId !== '' ? (
          <PendingModal
            content={`Address deactivation is pending...`}
            trackingId={trackingId}
          />
        ) : (
          <FailureModal
            errorText={`${deactivateAddressErrorText}${errorMessage} `}
          />
        )}
      </Modal>
    </div>
  )
}
