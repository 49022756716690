import React, { useState } from 'react'
import {
  Form,
  FormField,
  ColumnLayout,
  DatePicker,
  Button,
  Select,
  Popover,
} from '@amzn/awsui-components-react/polaris'
import Autosuggest from '@amzn/awsui-components-react/polaris/autosuggest'
import { usePersistedState } from '../../utils'

import './AuditSettings.scss'

// This file renders Eventlog data

const messageTypeOptions = [
  { id: 'ALL', label: 'All' },
  { id: 'INVOICE', label: 'Invoice' },
  { id: 'CREDIT_MEMO', label: 'Credit Memo' },
  { id: 'CUSTOMER', label: 'Customer'},
]
const statusOptions = [
  { id: 'ALL', label: 'All' },
  { id: 'PENDING', label: 'Pending' },
  {id: 'PARTIALLYSUCCEEDED', label:'Partially Succeeded'},
  { id: 'SUCCEEDED', label: 'Succeeded' },
  { id: 'FAILED', label: 'Failed' },
]

const AuditSettings = ({
  initialParams,
  onSearch,
}: {
  initialParams: any
  onDownloadResults: () => void
  onSearch: (params: any) => void
}) => {
  const [query, setQuery] = useState(initialParams.query)
  const [status, setStatus] = useState(initialParams.status)
  const [messageType, setMessageType] = useState(initialParams.messageType)
  const [before, setBefore] = useState(initialParams.before)
  const [after, setAfter] = useState(initialParams.after)
  const [stage] = useState(initialParams.stage)

  const [recentQueries, setRecentQueries] = usePersistedState([], 'recent-queries')
  const onSubmit = (e: any) => {
    setRecentQueries(
      [query, ...recentQueries].filter((q, i, queries) => queries.indexOf(q) === i).slice(0, 100)
    )
    onSearch({ query, status, messageType, before, after, stage })
    e.preventDefault()
    return false
  }

  return (
    <form onSubmit={onSubmit} className="search-settings">
      <Form>
        <ColumnLayout reset-filter-link columns={3}>
          <div data-awsui-column-layout-root="true">
            <FormField label="Query">
              <div className="stack-checkboxes">
                <Autosuggest
                  id="quicksearch"
                  placeholder="Search by tracking ID"
                  autofocus={true}
                  ariaRequired={true}
                  disableBrowserAutocorrect={true}
                  value={query}
                  onChange={(e) => setQuery(e.detail.value)}
                  options={recentQueries.map((q: string) => ({ value: q })).slice(0, 20)}
                  empty="No matching recent queries"
                  onKeydown={(e) => {
                    if (e.detail.keyCode === 13) onSubmit(e)
                  }}
                />
              </div>
            </FormField>
            <FormField label="Message Type">
              <div className="stack-checkboxes">
                <Select
                  selectedOption={messageTypeOptions.find((type) => type.id === messageType)}
                  options={messageTypeOptions}
                  onChange={(e) => setMessageType(e.detail.selectedId)}
                />
              </div>
            </FormField>
            <FormField label="Status">
              <div className="toggle-group">
                <Select
                  selectedOption={statusOptions.find((type) => type.id === status)}
                  options={statusOptions}
                  onChange={(e) => setStatus(e.detail.selectedId)}
                />
              </div>
            </FormField>
            <FormField
              label={
                <Popover content="Filters by transaction date if available or ingestion date if not.">
                  Between Dates
                </Popover>
              }
            >
              <div className="date-range-group">
                <DatePicker
                  placeholder="YYYY/MM/DD"
                  todayLabel="Today"
                  nextMonthLabel="Next month"
                  previousMonthLabel="Previous month"
                  onChange={(e) => {
                    setAfter(e.detail.value)
                    if (before && e.detail.value > before) setBefore(e.detail.value)
                  }}
                  value={after}
                />
                <div className="date-range-group-spacer" />
                <DatePicker
                  placeholder="YYYY/MM/DD"
                  todayLabel="Today"
                  nextMonthLabel="Next month"
                  previousMonthLabel="Previous month"
                  onChange={(e) => {
                    setBefore(e.detail.value)
                    if (after && e.detail.value < after) setAfter(e.detail.value)
                  }}
                  value={before}
                />
              </div>
            </FormField>
            <div></div>
            <div className="search-button-container">
              <Button
                variant="primary"
                onClick={() =>
                  onSearch({
                    query, 
                    status,
                    messageType,
                    before,
                    after,
                    stage,
                  })
                }
              >
                Search
              </Button>
            </div>
          </div>
        </ColumnLayout>
      </Form>
    </form>
  )
}

export default AuditSettings