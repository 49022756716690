import React  from 'react'
import { InvoiceLine } from '../../../types'
import Button from '@amzn/awsui-components-react/polaris/button'
import Table from '@amzn/awsui-components-react/polaris/table'
import TableFiltering from '@amzn/awsui-components-react/polaris/table-filtering'
import TablePagination from '@amzn/awsui-components-react/polaris/table-pagination'
import TableSorting from '@amzn/awsui-components-react/polaris/table-sorting'
import TablePreferences from '@amzn/awsui-components-react/polaris/table-preferences'
import TablePageSizeSelector from '@amzn/awsui-components-react/polaris/table-page-size-selector'
import TableWrapLines from '@amzn/awsui-components-react/polaris/table-wrap-lines'
import TableContentSelector from '@amzn/awsui-components-react/polaris/table-content-selector'

type LinesProps = {
  lines?: InvoiceLine[] | any
  columnDefinitionsOptional?: any
  header?: string
}

const CimarronLines = ({ lines, columnDefinitionsOptional, header }: LinesProps) => {
  if (!lines) return null

  let columnDefinitions = !columnDefinitionsOptional
    ? columnDefinitionsInvoice
    : columnDefinitionsOptional

  return (
    <div>
      <h4>{header}</h4>
      <Table
        columnDefinitions={columnDefinitions}
        items={lines}
        wrapLines
        resizableColumns
        variant="borderless"
        features={['filtering', 'pagination', 'sorting']}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No lines</b>
            </div>
            <p className="awsui-util-mb-s">This invoice does not contain any lines.</p>
          </div>
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">We can’t find a match.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
      >
        <TableFiltering
          filteringPlaceholder="Search in lines"
          // filteringFunction returns items where all search terms are present in the item
          // it does this by reducing the item to a single string and then checking each search term
          filteringFunction={(item: InvoiceLine, filteringText: string) => {
            const searchTerms = filteringText.toLowerCase().split(' ')
            const itemString = Object.keys(item)
              .reduce((str: string, k: string) => str + item[k]! + ' ', '')
              .toLowerCase()
            return searchTerms.every((s) => itemString.indexOf(s) !== -1)
          }}
          filteringCountTextFunction={(count) => `${count} ${count === 1 ? 'line' : 'lines'}`}
        />
        <TablePagination pageSize={10} />
        <TableSorting
          sortableColumns={sortableColumns}
          sortingColumn="invoiceLineNumber"
          sortingDescending={true}
        />
        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
          <TableWrapLines
            label="Wrap text in lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          <TableContentSelector
            title="Visible Columns"
            options={[
              {
                label: 'Content',
                options: columnDefinitions.map((c: any) => ({
                  id: c.id,
                  label: c.header,
                  visible: true,
                })),
              },
            ]}
          />
          <TablePageSizeSelector
            title="Lines Per Page"
            options={[
              { value: 10, label: '10 lines' },
              { value: 25, label: '25 lines' },
              { value: 100, label: '100 lines' },
            ]}
          />
        </TablePreferences>
      </Table>
    </div>
  )
}

const columnDefinitionsInvoice = [
  {
    id: 'invoiceLineNumber',
    header: 'Line Number',
    cell: (item: InvoiceLine) => item.number,
  },
  {
    id: 'unitSellingPrice',
    header: 'Unit Price',
    cell: (item: InvoiceLine) =>
      item.unitPrice !== undefined ? Number(item.unitPrice).toFixed(2) : null,
  },
  {
    id: 'amount',
    header: 'Amount',
    cell: (item: InvoiceLine) => (item.amount !== undefined ? Number(item.amount).toFixed(2) : null),
  },
  {
    id: 'quantity',
    header: 'Quantity',
    cell: (item: InvoiceLine) => item.quantity,
  },
  {
    id: 'description',
    header: 'Description',
    cell: (item: InvoiceLine) => item.description,
    allowLineWrap: true,
    minWidth: '120px',
  },
  {
    id: 'revenueType',
    header: 'Revenue Type',
    cell: (item: InvoiceLine) => item.customAttributes.revenue_type,
    allowLineWrap: true,
  },
  {
    id: 'taxLines',
    header: 'Taxes',
    cell: (item: InvoiceLine) =>
      (item.taxLines || []).map((line: any, i: number) => (
        <div key={i}>
          Amount: {line.amount} Rate: {line.rate} Type: {line.type}
        </div>
      )),
  },
].map((c) => ({
  ...c,
  // generate label functions based on column attributes
  label: (sortState: TableSorting.SortingState) => {
    const columnIsSorted = sortState.sortingColumn === c.id
    const ascending = !sortState.sortingDescending
    return `${c.header}, ${
      columnIsSorted ? `sorted ${ascending ? 'ascending' : 'descending'}` : 'not sorted'
    }.`
  },
}))

const stringToFloatComparator = (a: any, b: any): number => {
  const parsedA = parseFloat(a)
  const parsedB = parseFloat(b)
  if (!isNaN(parsedA) && !isNaN(parsedB)) return parsedA - parsedB
  else if (isNaN(parsedA)) return -1
  else if (isNaN(parsedB)) return 1
  else return 0
}

const sortableColumns = [
  { id: 'invoiceLineNumber', comparator: stringToFloatComparator },
  { id: 'unitSellingPrice', comparator: stringToFloatComparator },
  { id: 'amount', comparator: stringToFloatComparator },
  { id: 'quantity', comparator: stringToFloatComparator },
]

export default CimarronLines