import React, { useEffect, useState } from 'react'
import Authentication from '../../Common/Authentication'
import { permissions } from '../../Common/Authentication'
import Restricted from '../../Common/Restricted'
import { Spinner, Table } from '@amzn/awsui-components-react'
import EntitySearchTable from './EntitySearchTable'
import { SharedStates } from './RequestResponseConstants'
import { dateStringToTimestamp } from '../../utils'
import columnDefinitionsMigration from './MigrationSearchColumnDefinitions'
import EntitySearchParams from './EntitySearchParams'

const EntityStatusPage = () => {
  const [permissionSet, setPermissionSet] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)
  const [modalData, setModalData] = useState<Table.Item | undefined>(undefined)
  const shareStates = (): SharedStates => {
    return {
      modalData,
      setModalData,
    }
  }

  useEffect(() => {
    async function getPermissionSet() {
      const data = await Authentication()
      setPermissionSet(data)
      setLoading(false)
    }
    getPermissionSet()
  }, [])

  if (loading) {
    return (
      <div className="awsui-util-container awsui-util-t-c">
        <Spinner />
      </div>
    )
  } else {
    if (
      permissionSet &&
      (permissionSet.includes(permissions.READ_SEARCH_SCREEN) ||
        permissionSet.includes(permissions.PRIVILEGED_ACCESS))
    )
      return (
        <div className="awsui-grid awsui-util-p-m">
          <h1>Migration Search Engine</h1>
          <EntitySearchTable
          getColumnDefinitions={columnDefinitionsMigration(shareStates)}
          getSearchParams={getSearchParams}
          getSettings={getSettings}/>
        </div>
      )
    else {
      return Restricted()
    }
  }
}
export default EntityStatusPage

const getSearchParams = (queryParams: any) => {
  const getBefore = (before: any) => {
    if (before) {
      let date = dateStringToTimestamp(before)
      if (date) date += 24 * 60 * 60 - 1
      return date
    }
  }

  return {
    ofaEntityId: queryParams.ofaEntityId || '',
    before: getBefore(queryParams.before) || undefined,
    after: dateStringToTimestamp(queryParams.after) || undefined,
    migrationStatus:
      queryParams.migrationStatus === 'ALL' || !queryParams.migrationStatus
        ? undefined
        : queryParams.migrationStatus,
    entityType:
      queryParams.entityType === 'ALL' || !queryParams.entityType
        ? undefined
        : queryParams.entityType
  }
}

const getSettings = (queryParams: any, history: any) => {
  let initialParams = {
    ofaEntityId: queryParams.ofaEntityId || '',
    before: queryParams.before || '',
    after: queryParams.after || '',
    migrationStatus: queryParams.migrationStatus || '',
    entityType: queryParams.entityType || '',
  }

  return (
    <EntitySearchParams
      initialParams={initialParams}
      onSearch={(queryParams) => {
        const newParams = new URLSearchParams(window.location.search)
        Object.entries(queryParams).forEach(([key, value]) =>
          newParams.set(key, ('' + value) as string)
        )
        history.push(`${window.location.pathname}?${newParams.toString()}`)
      }}
      />
    
  )
}
