import Spinner from '@amzn/awsui-components-react/polaris/spinner'
import React, { useEffect, useState } from 'react'
import Authentication, { permissions } from '../../Common/Authentication'
import Restricted from '../../Common/Restricted'
import CustomerDetails from './Customer/CustomerDetails'

const CustomerDetailsPage = () => {
  const [permissionSet, setPermissionSet] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getPermissionSet() {
      const data = await Authentication()
      setPermissionSet(data)
      setLoading(false)
    }
    getPermissionSet()
  }, [])

  if (loading) {
    return (
      <div className="awsui-util-container awsui-util-t-c">
        <Spinner />
      </div>
    )
  } else {
    if (
      permissionSet &&
      (permissionSet.includes(permissions.READ_CUSTOMER_SCREEN) ||
        permissionSet.includes(permissions.PRIVILEGED_ACCESS))
    )
      return (
        <div className="awsui-grid awsui-util-p-m">
          <CustomerDetails />
        </div>
      )
    else {
      return Restricted()
    }
  }
}

export default CustomerDetailsPage
