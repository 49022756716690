import React from 'react'
import { Link } from 'react-router-dom'
import { isProd } from '../../../utils'

const PendingModal = ({
  content,
  trackingId,
}: {
  content: string
  trackingId: string
}) => {
  return (
    <div className="awsui-util-container">
      {`${content} Our tracking ID is: ${trackingId}. You may track the
      processing`}
      <Link
        to={`/audit/?query=${encodeURIComponent(
          trackingId
        )}&stage=${encodeURIComponent(isProd ? 'prod' : 'beta')}`}
      >
        {'here.'}
      </Link>
      <br />
      <i>
        {`Please note that existing information will continue to be displayed until changes have been processed.
      Please track the status and refresh the page after success.`}
      </i>
    </div>
  )
}

export default PendingModal
