import React, { useState } from 'react'
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control'

import './JSONViewer.scss'

const JSONViewer = ({ json }: { json: any }) => {
  const [showInteractive, setShowInteractive] = useState(true)

  const control = (
    <SegmentedControl
      selectedId={showInteractive + ''}
      ariaLabel="Default segmented control"
      options={[
        { text: 'View Tree', id: 'true' },
        { text: 'View JSON', id: 'false' },
      ]}
      onChange={(e) => setShowInteractive(e.detail.selectedId === 'true')}
    ></SegmentedControl>
  )

  if (showInteractive && isObjectOrArray(json))
    return (
      <div>
        {control}
        <pre className="json-viewer">
          <div>
            {'{'}
            <CollapsableNode json={json} />
            {'}'}
          </div>{' '}
        </pre>
      </div>
    )
  else
    return (
      <div>
        {control}
        <pre>{JSON.stringify(json, null, 2)}</pre>
      </div>
    )
}
interface CollapsableNodeProps {
  json: {
    [key: string]: any
  }
}
const CollapsableNode = ({ json }: CollapsableNodeProps) => {
  const keys = Object.keys(json).sort((a, b) => a.localeCompare(b))
  const [show, setShow] = useState(keys.map((k) => false))

  return (
    <div>
      {keys.map((key, i) => {
        const node = json[key]
        const shouldShow = show[i]
        const onClick = () => {
          const nextShow = show.concat()
          nextShow[i] = !shouldShow
          setShow(nextShow)
        }
        if (!isObjectOrArray(node))
          return (
            <div key={i} className="json-indent">
              {Array.isArray(json) ? i : `"${key}"`}:{' '}
              {JSON.stringify(json[key], null, 2)}
              {i !== keys.length - 1 ? ',' : ''}
            </div>
          )
        return (
          <div key={i} className="json-indent">
            <Toggle value={shouldShow} onClick={onClick} />
            {Array.isArray(json) ? i : `"${key}"`}:{' '}
            {Array.isArray(node) ? '[' : '{'}
            {shouldShow ? (
              <CollapsableNode json={json[key]} />
            ) : (
              <span onClick={onClick} className="link">
                ...
              </span>
            )}
            {Array.isArray(node) ? ']' : '}'}
            {i !== keys.length - 1 ? ',' : ''}
          </div>
        )
      })}
    </div>
  )
}
const Toggle = ({ value, onClick }: { value: boolean; onClick: any }) => (
  <button onClick={onClick} className="json-expand-button">
    {value ? '-' : '+'}
  </button>
)
const isObjectOrArray = (json: any): boolean =>
  typeof json === 'object' && json !== null
export default JSONViewer
