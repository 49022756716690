import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import reduxStore from './store'
import { Auth } from '@aws-amplify/auth'
import Layout from './Layout'
import { AwsRum, AwsRumConfig } from 'aws-rum-web'

const configureRum = () => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn:
        'arn:aws:iam::254467414780:role/DashboardGlobalStack-UnauthenticatedRumRole54B0B6A-HCPKOKE0PBTM',
      identityPoolId: 'us-west-2:00449b51-f1c1-472a-bb97-a51687cde731',
      endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
      telemetries: ['errors', 'performance', 'http'],
      allowCookies: true,
      enableXRay: false,
    }

    const APPLICATION_ID: string = '67a30da3-e0fb-46d6-9db3-8e2b837d5270'
    const APPLICATION_VERSION: string = '1.0.0'
    const APPLICATION_REGION: string = 'us-west-2'

    new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

Auth.configure({
  region: 'us-west-2',
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_URL,
    scope: ['openid'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code',
  },
  storage: sessionStorage,
})

const REDIRECT_URL_LOCAL_STORAGE_KEY = 'redirectUrlAfterAuthentication'

const App = () => {
  const [signedIn, setSignedIn] = useState(false)
  const [error, setError] = useState<string | null>(null)

  console.log('Local change working')

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setSignedIn(true)
        setError(null)
        console.log('signed in')
        configureRum()

        const redirectUrl = sessionStorage.getItem(
          REDIRECT_URL_LOCAL_STORAGE_KEY
        )
        if (redirectUrl) {
          sessionStorage.removeItem(REDIRECT_URL_LOCAL_STORAGE_KEY)
          window.location.assign(redirectUrl)
        }
      })
      .catch(() => {
        setSignedIn(false)
        setError(null)

        // if a user needs to be authenticated, store the URL they were attempting to
        // access so we can redirect them to it after authentication
        sessionStorage.setItem(
          REDIRECT_URL_LOCAL_STORAGE_KEY,
          window.location.href
        )

        Auth.federatedSignIn({
          customProvider: process.env.REACT_APP_COGNITO_PROVIDER_NAME as string,
        })
      })
  }, [])

  if (error) return <div>Error: {error}</div>
  if (!signedIn) return <div>Signing in...</div>

  return (
    <Provider store={reduxStore}>
      <Router>
        <Layout />
      </Router>
    </Provider>
  )
}

export default App
