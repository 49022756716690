import React, { useEffect, useState } from 'react'
import { Message } from '../../types'
import { isProd, printDateTime } from '../../utils'
import { Modal, Button, Popover } from '@amzn/awsui-components-react/polaris'
import './ProcessLog.scss'
import useFetch, { CachePolicies } from 'use-http'
import {
  useMessageArtifacts,
  usePrintInvoice,
  useSubmitInvoice,
} from '../../api'
import { useParams } from 'react-router-dom'

export interface SubmitInvoiceRequest {
  billingHubMessageId?: string
  clientId?: string
  marketplaceId?: string
  batchSourceName?: string
  sellerOfRecord?: string
}

export interface SubmitInvoiceResponse {
  status: string
  errorMessage: string
  ofaRequestId: string
}

export interface PrintInvoiceRequest {
  billingHubMessageId?: string
  clientId?: string
  marketplaceId?: string
  ofaInvoiceNumber?: string
  batchSourceName?: string
  sellerOfRecord?: string
}

export interface PrintInvoiceResponse {
  status: string
  errorMessage: string
  ofaRequestId: string
}

interface ModalEvent {
  detail?: string
  description?: string
}
const ProcessLog = ({
  message,
  canReadRestrictedData,
}: {
  message?: Message
  loading: boolean
  canReadRestrictedData: boolean
}) => {
  const [modalData, setModalData] = useState<ModalEvent | undefined>(undefined)
  const [importInvoice, setImportInvoice] = useState(false)
  const [printInvoice, setPrintInvoice] = useState(false)
  const [batchSourceName, setBatchSourceName] = useState('')
  const [marketplaceId, setMarketplaceId] = useState('')
  const [sellerOfRecord, setSellerOfRecord] = useState('')
  const [printPopupContent, setPrintPopupContent] = useState('')
  const [importPopupContent, setImportPopupContent] = useState('')
  const [ofaInvoiceNumber, setOfaInvoiceNumber] = useState()

  const { messageId, stage } = useParams<{ messageId: string; stage: string }>()
  const eInvoiceRequestStatus = {
    success: 'Success',
    error: 'Error',
  }
  const eInvoicePopUpStatus = {
    success_pending:
      'Request has processed. Please wait a few minutes for process log to update with final status. OFA Tracking ID: ',
    error: 'Request has failed. Error Message: ',
    processing: 'Request is being processed.',
  }

  const { data: invoiceData, loading: loadingInvoiceData } = useFetch(
    message?.invoiceUri || '',
    { cachePolicy: CachePolicies.NO_CACHE },
    [message]
  )
  let jsonData = invoiceData
  const { data: clientData, loading: loadingClientData } = useFetch(
    message?.clientRequestUri || '',
    { cachePolicy: CachePolicies.NO_CACHE },
    [message]
  )
  const { data: artifacts, loading: loadingArtifacts } = useMessageArtifacts(
    messageId,
    stage,
    message?.ofaTrxNumber || message?.trxNumber || '',
    !canReadRestrictedData
  )
  useEffect(() => {
    //Updates Status if Invoice PDF has been generated
    if (message?.messageLog) {
      message?.messageLog.map(
        (row, i) =>
          row.status === 'IMPORT_INVOICE' &&
          artifacts?.invoicePdfUri &&
          message.messageStatusRaw === 'IMPORT_INVOICE'
            ? (setPrintInvoice(true),
              (row.status = 'DOCUMENT_UPLOAD_SUCCESSFUL'))
            : row.status === 'IMPORT_INVOICE' &&
              message.messageStatusRaw === 'IMPORT_INVOICE'
            ? (setImportInvoice(true),
              setOfaInvoiceNumber(getJSONObj(row.detail)?.ofaInvoiceNumber))
            : importInvoice,
        printInvoice
      )
      //when data finishes loading update batchSourceName, marketplaceId, and sellerOfRecord
      setBatchSourceName(jsonData?.invoiceHeader?.batchSourceName || '')
      setMarketplaceId(clientData?.obfuscatedMarketplaceId || '')
      setSellerOfRecord(clientData?.header?.businessUnit?.sellerOfRecord || '')
    }
  }, [
    message,
    printInvoice,
    importInvoice,
    clientData,
    jsonData,
    artifacts,
    loadingArtifacts,
    loadingClientData,
    loadingInvoiceData,
  ])

  const SubmitInvoiceCall = async (request: SubmitInvoiceRequest) => {
    setImportPopupContent(eInvoicePopUpStatus.processing)
    useSubmitInvoice(request).then(({ data }) => {
      if (!data) {
        setImportPopupContent(
          eInvoicePopUpStatus.error + 'System Error, Request Failed to Process.'
        )
        setImportInvoice(false)
      }
      if (data?.status === eInvoiceRequestStatus.success) {
        setImportPopupContent(
          eInvoicePopUpStatus.success_pending + data.ofaRequestId
        )
        setPrintInvoice(true)
      } else {
        setImportPopupContent(eInvoicePopUpStatus.error + data?.errorMessage)
        setImportInvoice(false)
      }
    })
  }

  const PrintInvoiceCall = async (request: PrintInvoiceRequest) => {
    setPrintPopupContent(eInvoicePopUpStatus.processing)
    usePrintInvoice(request).then(({ data }) => {
      if (!data) {
        setPrintPopupContent(
          eInvoicePopUpStatus.error + 'System Error, Request Failed to Process.'
        )
        setPrintInvoice(false)
      }
      if (data?.status === eInvoiceRequestStatus.success) {
        setPrintPopupContent(
          eInvoicePopUpStatus.success_pending + data.ofaRequestId
        )
        setPrintInvoice(true)
      } else {
        setPrintPopupContent(eInvoicePopUpStatus.error + data?.errorMessage)
        setPrintInvoice(false)
      }
    })
  }

  //Validates Inputs and triggers api to import invoice to OFA
  const OnSubmitInvoice = () => {
    const submitInvoiceRequest: SubmitInvoiceRequest = {
      billingHubMessageId: message?.messageId,
      clientId: message?.clientId,
      marketplaceId: marketplaceId,
      batchSourceName: batchSourceName,
      sellerOfRecord: sellerOfRecord,
    }
    if (validateRequest(submitInvoiceRequest)) {
      SubmitInvoiceCall(submitInvoiceRequest)
    }
  }

  //Validates Inputs and triggers api to generate PDF in OFA.
  const OnPrintInvoice = () => {
    const printInvoiceRequest: PrintInvoiceRequest = {
      billingHubMessageId: message?.messageId,
      clientId: message?.clientId,
      marketplaceId: marketplaceId,
      ofaInvoiceNumber: ofaInvoiceNumber,
      batchSourceName: batchSourceName,
      sellerOfRecord: sellerOfRecord,
    }
    if (validateRequest(printInvoiceRequest)) {
      PrintInvoiceCall(printInvoiceRequest)
    }
  }

  return (
    <div className="col-6">
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Process Log</h2>
        </div>
        <div className="process-log">
          {((message && message.messageLog) || [])
            .concat()
            .reverse()
            .map((logEntry, index) => (
              <div className="process-log-row" key={index}>
                <div className="process-log-timestamp">
                  {logEntry.time
                    ? printDateTime(new Date(logEntry.time * 1000))
                    : 'Missing'}
                </div>
                <div className="process-log-description">
                  <span
                    className={
                      logEntry.detail || logEntry.description ? 'link' : ''
                    }
                    onClick={() =>
                      logEntry.detail || logEntry.description
                        ? setModalData(logEntry)
                        : null
                    }
                  >
                    {logEntry.status}
                  </span>
                </div>
                {!isProd && stage !== 'prod' && (
                  <div className="process-log-self-service-button">
                    {index === 0 &&
                    (logEntry.status === 'MESSAGE_UPLOAD_COMPLETE' ||
                      logEntry.status === 'IMPORT_INVOICE_ERROR') ? (
                      <Popover
                        triggerType="custom"
                        content={importPopupContent}
                      >
                        <Button
                          disabled={importInvoice}
                          onClick={OnSubmitInvoice}
                        >
                          Submit Invoice to OFA
                        </Button>
                      </Popover>
                    ) : logEntry.status === 'IMPORT_INVOICE' ? (
                      <Popover triggerType="custom" content={printPopupContent}>
                        <Button
                          disabled={printInvoice}
                          onClick={OnPrintInvoice}
                        >
                          Generate Invoice PDF
                        </Button>
                      </Popover>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      {modalData && (
        <Modal
          visible={true}
          header={
            <>
              <div>Event Detail</div>
            </>
          }
          onDismiss={() => setModalData(undefined)}
          footer={
            <span className="awsui-util-f-r">
              <Button variant="primary" onClick={() => setModalData(undefined)}>
                Close
              </Button>
            </span>
          }
        >
          <p>{modalData?.description}</p>
          {modalData.description && modalData.detail && <hr />}
          <pre>{prettyPrintJSON(modalData.detail)}</pre>
        </Modal>
      )}
    </div>
  )
}

const prettyPrintJSON = (detail?: string) => {
  if (!detail) return ''
  try {
    return JSON.stringify(JSON.parse(detail), null, 2)
  } catch (e) {
    return detail
  }
}

const getJSONObj = (detail?: string) => {
  if (!detail) return ''
  try {
    return JSON.parse(detail)
  } catch (e) {
    return ''
  }
}

const validateRequest = (request: Record<string, any>) => {
  const invalidFields: string[] = []
  for (const key in request) {
    const value = request[key]
    if (!value && key !== 'sellerOfRecord') {
      invalidFields.push(key)
    }
  }

  if (invalidFields.length > 0) {
    const errorMessage = 'Invalid Fields: ' + invalidFields.join(', ')
    alert(errorMessage)
    return false
  } else {
    return true
  }
}

export default ProcessLog
