import React, { useState } from 'react'
import {
  Button,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Input,
  Modal,
  Select,
  Tooltip,
} from '@amzn/awsui-components-react/polaris'

import { Address, SharedStates } from '../../../types'
import DataField from '../../../Common/DataField'
import { updateAddress } from '../../../api'
import PendingModal from '../Modals/PendingModal'
import FailureModal from '../Modals/FailureModal'
import { FlashbarProps, addressTypes, getField, isEmpty } from '../../../utils'

export interface UpdateAddressRequest {
  marketplaceId: any
  cimarronAccountId: string
  customerId: string
  ticketNumber: string
  issuerAccountId: string
  addressId: string
  siteUseCode: string
  displayableMarketplaceId: string
  primarySite: boolean
  status: string
  firstName: string
  lastName: string
  primaryPhoneNumber: string
  contactEmail: string
  secondaryPhoneNumber: string
  secondaryEmails: string[]
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  county: string
  state: string
  postalCode: string
  country: string
}

export interface UpdateAddressResponse {
  trackingId: string
  errors?: Error[]
  message?: string
}

export default function UpdateAddress({
  item,
  shareStates,
}: {
  item: Address
  shareStates: () => SharedStates
}) {
  const {
    customerData: { cimarronAccounts, customerId },
    countryOptions,
    stateToCountryMap,
  } = shareStates()
  const [showUpdateAddressModal, setShowUpdateAddressModal] = useState(false)
  const [ticketNumber, setTicketNumber] = useState('')
  const [validateTicketNumber, setValidateTicketNumberMessage] = useState('')
  const updationModalMessage = `Are you sure, you want to update following address?`
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [trackingId, setTrackingId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [missingRequiredFields, setMissingRequiredFields] = useState(false)
  const [bannerItems, setBannerItems] = useState<FlashbarProps[]>([])
  const isPrimaryAddress = getField('primarySite', item)
  const updateAddressErrorText = 'Update Address Failed.'
  const validateTicketNumberText =
    'Enter Ticket Number, ticket Number is required to proceed.'
  const ADDRESS_TYPE_TOOLTIP_TEXT =
    isPrimaryAddress &&
    'You cannot update Bill To to Ship To, as this is a primary address.'

  const [address, setAddress] = useState(item)
  const onDismiss = () => setMissingRequiredFields(false)
  const flashBarMessage = {
    header: '',
    type: '',
    content: '',
    dismissible: true,
    onDismiss: onDismiss,
  }
  const resetUpdateAddressModal = () => {
    setTicketNumber('')
    setValidateTicketNumberMessage('')
    setAddress(item)
    setShowUpdateAddressModal(false)
    setBannerItems([])
  }

  const convertNullValuesToEmptyString = (address) => {
    for (const element in address) {
      // To support backend API contract, frontend needs to pass empty strings instead of null values
      if (address['secondaryEmails'] === null) address['secondaryEmails'] = []
      if (address[element] === null) address[element] = ''
    }
  }

  const validateEmptyFields = (address) => {
    if (
      isEmpty(address['firstName']) ||
      isEmpty(address['contactEmail']) ||
      isEmpty(address['addressLine1']) ||
      isEmpty(address['postalCode']) ||
      isEmpty(address['country']) ||
      isEmpty(address['city'])
    ) {
      flashBarMessage.header = 'Missing Required Fields!'
      flashBarMessage.content =
        'Please add data to fields where optional is not mentioned.'
      flashBarMessage.type = 'error'
      setBannerItems([flashBarMessage])
      return true
    }
    setBannerItems([])
    return false
  }

  const updateAddressRequest = async () => {
    if (isEmpty(ticketNumber)) {
      setValidateTicketNumberMessage(validateTicketNumberText)
      return
    }
    const isMissingFields = validateEmptyFields(address)
    setMissingRequiredFields(isMissingFields)
    if (!isMissingFields) {
      convertNullValuesToEmptyString(address)
      const updateAddressRequestData: any = {
        marketplaceId: cimarronAccounts[0].displayableAddressMarketplaceId,
        cimarronAccountId: cimarronAccounts[0].cimarronAccountId,
        ...address,
        customerId,
        ticketNumber,
      }

      const result = await updateAddress(updateAddressRequestData)

      const { data, error } = result
      if (error) {
        setErrorMessage(` Error: ${data?.message}`)
      } else {
        setTrackingId(data?.trackingId || '')
      }
      resetUpdateAddressModal()
      setShowConfirmationModal(true)
      return
    }
  }

  return (
    <div>
      <Button icon="edit" onClick={() => setShowUpdateAddressModal(true)}>
        Update
      </Button>
      {showUpdateAddressModal && (
        <Modal
          size="large"
          header={` Update Address`}
          visible={showUpdateAddressModal}
          onDismiss={() => {
            setShowUpdateAddressModal(false)
            resetUpdateAddressModal()
          }}
        >
          <h4>{updationModalMessage}</h4>
          <>
            <Form
              actions={
                <div>
                  <Button
                    variant="link"
                    onClick={() => {
                      setShowUpdateAddressModal(false)
                      resetUpdateAddressModal()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      updateAddressRequest()
                    }}
                  >
                    Update Address
                  </Button>
                </div>
              }
            >
              {missingRequiredFields && <Flashbar items={bannerItems} />}
              <ColumnLayout columns={1} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Address id">
                      <div>{getField('addressId', address)}</div>
                    </DataField>
                  </div>
                </div>
              </ColumnLayout>
              <h4 className="awsui-util-mt-l awsui-util-mb-s">Contact info:</h4>
              <ColumnLayout columns={3} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <FormField label="First name">
                        <Input
                          value={getField('firstName', address)}
                          placeholder={'First name'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              firstName: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label={
                          <span>
                            Last name <i> - optional </i>
                          </span>
                        }
                      >
                        <Input
                          value={getField('lastName', address)}
                          placeholder={'Last name'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              lastName: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <FormField label="Primary email">
                        <Input
                          type="email"
                          value={getField('contactEmail', address)}
                          placeholder={'Primary email'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              contactEmail: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label={
                          <span>
                            Secondary email <i> - optional </i>
                          </span>
                        }
                      >
                        <Input
                          type="email"
                          value={
                            getField('secondaryEmails', address)
                              ? String(getField('secondaryEmails', address))
                              : ''
                          }
                          placeholder={'Secondary email'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              secondaryEmails: [e.detail.value],
                            })
                          }
                        />
                      </FormField>
                    </div>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <FormField
                        label={
                          <span>
                            Primary phone number <i> - optional </i>
                          </span>
                        }
                      >
                        <Input
                          value={getField('primaryPhoneNumber', address)}
                          placeholder={'Primary phone number'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              primaryPhoneNumber: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label={
                          <span>
                            Secondary phone number <i> - optional </i>
                          </span>
                        }
                      >
                        <Input
                          value={getField('secondaryPhoneNumber', address)}
                          placeholder={'Secondary phone number'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              secondaryPhoneNumber: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                  </div>
                </div>
              </ColumnLayout>

              <h4 className="awsui-util-mt-l awsui-util-mb-s">Address info:</h4>
              <ColumnLayout columns={3} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <FormField label="Address line 1">
                        <Input
                          value={getField('addressLine1', address)}
                          placeholder={'Address line 1'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              addressLine1: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label={
                          <span>
                            Address line 2 <i> - optional </i>
                          </span>
                        }
                      >
                        <Input
                          value={getField('addressLine2', address)}
                          placeholder={'Address line 2'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              addressLine2: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label={
                          <span>
                            Address line 3 <i> - optional </i>
                          </span>
                        }
                      >
                        <Input
                          value={getField('addressLine3', address)}
                          placeholder={'Address line 3'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              addressLine3: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField label="Postal code">
                        <Input
                          value={getField('postalCode', address)}
                          placeholder={'Postal code'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              postalCode: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <FormField label="Country">
                        <Select
                          selectedOption={countryOptions.find(
                            (country: { id: string }) =>
                              country.id === getField('country', address)
                          )}
                          options={countryOptions}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              city: '',
                              state: '',
                              county: '',
                              country: e.detail.selectedId,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label={
                          <span>
                            State <i> - optional </i>
                          </span>
                        }
                      >
                        {stateToCountryMap
                          .get(getField('country', address))
                          .includes(getField('state', address)) ||
                        stateToCountryMap.get(getField('country', address))
                          .length > 0 ? (
                          <Select
                            selectedOption={
                              stateToCountryMap
                                .get(getField('country', address))
                                .map((state) => ({
                                  id: state,
                                  label: state,
                                }))
                                .find(
                                  (item: { id: string }) =>
                                    item.id === getField('state', address)
                                ) || null
                            }
                            options={
                              stateToCountryMap
                                .get(getField('country', address))
                                .map((state) => ({
                                  id: state,
                                  label: state,
                                })) || []
                            }
                            onChange={(e) => {
                              setAddress({
                                ...address,
                                state: e.detail.selectedOption.label,
                              })
                            }}
                          />
                        ) : (
                          <Input
                            value={getField('state', address)}
                            placeholder={'State'}
                            onChange={(e) =>
                              setAddress({
                                ...address,
                                state: e.detail.value,
                              })
                            }
                          />
                        )}
                      </FormField>
                    </div>
                    <div>
                      <FormField label="City">
                        <Input
                          value={getField('city', address)}
                          placeholder={'City'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              city: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField
                        label={
                          <span>
                            County <i> - optional </i>
                          </span>
                        }
                      >
                        <Input
                          value={getField('county', address)}
                          placeholder={'County'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              county: e.detail.value,
                            })
                          }
                        />
                      </FormField>
                    </div>
                  </div>

                  <div className="awsui-util-spacing-v-s">
                    <div>
                      <FormField label="Address type">
                        <Tooltip
                          text={ADDRESS_TYPE_TOOLTIP_TEXT}
                          position="top"
                        >
                          <Select
                            disabled={isPrimaryAddress}
                            selectedOption={addressTypes.find(
                              (site) =>
                                site.id === getField('siteUseCode', address)
                            )}
                            options={addressTypes}
                            onChange={(e) =>
                              setAddress({
                                ...address,
                                siteUseCode: e.detail.selectedId,
                              })
                            }
                          />
                        </Tooltip>
                      </FormField>
                    </div>
                  </div>
                </div>
              </ColumnLayout>
              <br />
              <FormField label="Ticket number" errorText={validateTicketNumber}>
                <Input
                  value={ticketNumber}
                  placeholder={'Write your ticket number here'}
                  onChange={(e) => setTicketNumber(e.detail.value)}
                />
              </FormField>
              <br />
            </Form>
          </>
        </Modal>
      )}
      <Modal
        header={`Update Address`}
        visible={showConfirmationModal}
        onDismiss={() => {
          setShowConfirmationModal(false)
          resetUpdateAddressModal()
        }}
      >
        {trackingId !== '' ? (
          <PendingModal
            content={`Address Update is pending...`}
            trackingId={trackingId}
          />
        ) : (
          <FailureModal
            errorText={`${updateAddressErrorText}${errorMessage} `}
          />
        )}
      </Modal>
    </div>
  )
}
