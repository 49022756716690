// shows a progress bar that fills in about a second that calls the onComplete handler
// The progress bar fills based on a timer and not based on any actual data processing.

import React, { useState, useEffect } from 'react'
import { ProgressBar } from '@amzn/awsui-components-react/polaris'

const FakeProgressBar = ({ onComplete }: { onComplete: () => void }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((progress) => progress + 5)

      // wait until progress is 130 instead of 100 so the progress bar shows 100%
      // for a little longer. It's more satisfying
      if (progress >= 130) onComplete()
    }, 30)
    return () => clearInterval(interval)
  })

  return <ProgressBar status="in-progress" value={progress} />
}

export default FakeProgressBar
