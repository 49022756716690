import { createStore, applyMiddleware, Middleware } from 'redux'
import reducer from './reducer'
import { rehyrdateState as rehyrdateStateAction } from './actions'

const LOCALSTORAGE_KEY = 'CARD_REDUX_STATE'
const whitelist = ['token', 'stage'] // list of state properties to persist

// after each state change, persist the new state to localstorage
const persistor: Middleware = (store) => (next) => (action) => {
  const result = next(action)
  const stateToPersist: any = {}
  whitelist.forEach((key) => (stateToPersist[key] = store.getState()[key]))
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(stateToPersist))
  return result
}

const reduxStore = createStore(reducer, applyMiddleware(persistor))

export const rehyrdateState = () => {
  try {
    const state = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) as string)
    reduxStore.dispatch(rehyrdateStateAction(state))
  } catch (e) {
    // do nothing, the state was not previously saved
  }
}

// try to rehyrdate the persisted state when initializing the store
rehyrdateState()

export default reduxStore
