const handleInputChange = (
  val: any,
  fieldName: string,
  setFormInputs: any,
  formInputs: any
): void => {
  let inputs = { ...formInputs }
  inputs[fieldName] = val
  setFormInputs(inputs)
}

export default handleInputChange