import React from 'react'

// Creating Central Error modal for all error messages

const FailureModal = ({ errorText }: { errorText: string }) => {
  return (
    <div className="awsui-util-container">
      {errorText}
      {` If this is a bug, please`}
      {' '}
          <a
            href="https://sim.amazon.com/issues/create?template=e8425949-0eab-4762-a81f-ab2b82c70647"
            target="_blank"
            rel="noopener noreferrer"
          >
            submit a ticket
          </a>
          .
    </div>
  )
}

export default FailureModal