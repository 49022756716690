import {Table, TableContentSelector, TablePageSizeSelector, TablePagination, TablePreferences, TableSorting, TableWrapLines } from '@amzn/awsui-components-react'
import React, { useEffect, useState } from 'react'
import { useSearchMigration } from '../../api';
import { useQueryParams } from '../../utils';
import { useHistory } from 'react-router-dom'

const EntitySearchTable = ({
  getColumnDefinitions,
  getSearchParams,
  getSettings
}: {
  getColumnDefinitions: any
  getSearchParams: any
  getSettings: any
}) =>
{

const queryParams = useQueryParams()
const searchParams: any = getSearchParams(queryParams)
const [sortBy, setSortBy] = useState('OfaEntityId')
const [sortAscending, setSortAscending] = useState(true)
const [page, setPage] = useState(1)
const history = useHistory()
const [pageSize, setPageSize] = useState(25);
const [pagesCount, setPagesCount] = useState(1);
const pageOptions = [
    { value: 10, label: '10 lines' },
    { value: 25, label: '25 lines' },
    { value: 100, label: '100 lines' },
  ]
const [results, setResults] = useState<any>([]);
const [loading, setLoading] = useState(true);

useEffect(() => {
  setLoading(true);
  
  // eslint-disable-next-line
  useSearchMigration({
    before: searchParams.after,
    after: searchParams.before,
    migrationStatus: searchParams.migrationStatus,
    entityType: searchParams.entityType,
    ofaEntityId: searchParams.ofaEntityId,
    start: pageSize * (page - 1),
    limit: pageSize,
    sortBy,
    sortAscending,
  }).then(({ data }) => {
    if (!data) {
      setLoading(false)
      return
    }
    setLoading(false)
    setResults(data!.results || [])
    setPagesCount(data ? Math.ceil(data.count / pageSize) : 1)
  })
}, [
  searchParams.before,
  searchParams.after,
  searchParams.ofaEntityId,
  searchParams.entityType,
  searchParams.migrationStatus,
  sortBy,
  sortAscending,
  page,
  pageSize,
])

    return (
        <Table
            header={getSettings(queryParams, history)}
            columnDefinitions={getColumnDefinitions}
            items={results}
            wrapLines={false}
            features={['pagination', 'sorting']}
            loading={loading}
            loadingText="Loading resources"
            empty={
                <div className="awsui-util-t-c">
                  <div className="awsui-util-pt-s awsui-util-mb-xs">
                    <b>No matches</b>
                  </div>
                </div>
              }
            >
            <TablePagination 
              pageSize={pageSize}
              pagesCount={pagesCount}
              onPaginationChange={(e) => {
                setPage(e.detail.currentPageIndex)
                setPageSize(e.detail.pageSize)
              }}
              />
            <TableSorting
              sortingColumn={sortBy}
              sortableColumns={getColumnDefinitions.map((c: any) => ({
                id: c.id as string,
              }))}
              onSortingChange={(e) => {
                setSortBy(e.detail.sortingColumn)
                setSortAscending(!e.detail.sortingDescending)
              }}
            />
            <TablePreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
            >
                <TablePageSizeSelector title="Page size" options={pageOptions}/>
                <TableContentSelector
                title="Visible Columns"
                options={[
                {
                    label: 'Content',
                    options: getColumnDefinitions.map((c: any) => ({
                    id: c.id as string,
                    label: c.header as string,
                    visible: 
                    c.id !== 'currency' &&
                    c.id !== 'invoiceDate' 
                } ))
                }
                ]}
                />
                <TableWrapLines
                label="Wrap lines"
                description="Enable to wrap table cell content, disable to truncate text."
                />
            </TablePreferences>
            </Table>
    )
}

export default EntitySearchTable
