// Add Issues/Feedback link on each render
import React from 'react'

import './Logo.scss'

export default () => {
  return (
    <div className="logo-feedback">
      <a
        href="https://tiny.amazon.com/17zqwpyr8"
        target="_blank"
        rel="noopener noreferrer"
      >
        Issues/Feedback
      </a>
    </div>
  )
}
