import React from 'react'
import { ColumnLayout } from '@amzn/awsui-components-react/polaris'
import { InvoiceHeader, Message as MessageType } from '../../../types'
import { printDate, printCurrency } from '../../../utils'
import TransmissionDetailsField from './TransmissionDetailsField'
import DataField from '../../../Common/DataField'
import { useMessageArtifacts } from '../../../api'
import { useParams } from 'react-router-dom'

const strToDate = (str: string): Date => new Date(parseInt(str))

const HeaderOFA = ({
  header,
  message,
  canReadRestrictedData,
}: {
  header?: InvoiceHeader
  message?: MessageType
  canReadRestrictedData: boolean
}) => {
  const { messageId, stage } = useParams<{ messageId: string; stage: string }>()
  const { data: artifacts, loading: loadingArtifacts } = useMessageArtifacts(
    messageId,
    stage,
    message?.ofaTrxNumber || '',
    !canReadRestrictedData
  )

  if (!message) return null

  return (
    <ColumnLayout columns={3} variant="text-grid">
      <div data-awsui-column-layout-root="true">
        <div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Billing Hub Message ID">
              {message?.messageId}
            </DataField>
            <DataField label="Invoice Header ID">
              {header?.invoiceHeaderId}
            </DataField>
            <DataField label="OFA Invoice Number">
              {message?.ofaTrxNumber}
            </DataField>
            <DataField label="OFA Organization">{message?.ofaOrg}</DataField>
          </div>
        </div>
        <div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Transaction Date">
              {printDate(strToDate(header?.trxDate))}
            </DataField>
            <DataField label="Transaction Amount">
              {renderTotal(header || {})}
            </DataField>
            {message.messageType !== 'CREDIT_MEMO' && (
              <DataField label="Terms">{header?.termName}</DataField>
            )}
            <DataField label="Comments">{header?.comments}</DataField>
          </div>
        </div>
        <div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Batch Source">
              {header?.batchSourceName}
            </DataField>
            <DataField label="Customer Transaction Type Name">
              {header?.custTrxTypeName}
            </DataField>
            <DataField label="Category">{header?.attributeCategory}</DataField>
            {canReadRestrictedData && (
              <>
                <DataField label="Invoice PDF">
                  {loadingArtifacts ? (
                    'Loading'
                  ) : !artifacts?.invoicePdfUri ? (
                    'Not Available'
                  ) : (
                    <a
                      href={artifacts?.invoicePdfUri}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Available
                    </a>
                  )}
                </DataField>
                <DataField label="Transmission Details">
                  <TransmissionDetailsField
                    transmissionDetails={artifacts?.transmissionDetails}
                    loading={loadingArtifacts}
                  />
                </DataField>
              </>
            )}
          </div>
        </div>
      </div>
    </ColumnLayout>
  )
}

export const renderTotal = ({
  currencyCode,
  controlTotalLineAmount: lineAmount,
  controlTotalTaxAmount: taxAmount,
}: {
  currencyCode?: string
  controlTotalLineAmount?: number
  controlTotalTaxAmount?: number
}) => {
  if (!lineAmount) return null
  if (!taxAmount) return printCurrency(lineAmount, currencyCode)

  return `${printCurrency(
    lineAmount + taxAmount,
    currencyCode
  )} (${printCurrency(lineAmount, currencyCode)} line items, ${printCurrency(
    taxAmount,
    currencyCode
  )} taxes)`
}

export default HeaderOFA