import React, { useState } from 'react'
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout'
import Spinner from '@amzn/awsui-components-react/polaris/spinner'
import Icon from '@amzn/awsui-components-react/polaris/icon'
import { Message as MessageType } from '../../types'
import JSONViewer from '../../Common/JSONViewer'
import useFetch, { CachePolicies } from 'use-http'

type MessageProps = {
  message?: MessageType
  loading: boolean
}
const Message = ({ message, loading }: MessageProps) => {
  const [showRaw, setShowRaw] = useState(false)

  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>
              Client <Icon name="angle-right" /> Billing Hub
            </h2>
          </div>
          <div className="awsui-util-action-stripe-action">
            <div className="awsui-util-container-header-description">
              {message?.clientRequestUri && (
                <span
                  className="link link-bold"
                  onClick={() => setShowRaw(!showRaw)}
                >
                  {showRaw ? 'View Parsed Data' : 'View Raw Data'}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="awsui-util-container awsui-util-t-c">
          <Spinner />
        </div>
      ) : !message ? null : showRaw && message?.clientRequestUri ? (
        <RawClientRequest uri={message.clientRequestUri} />
      ) : (
        <ColumnLayout columns={3} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">Client Request ID</div>
                  <div>{message.clientRequestId}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">ClientID</div>
                  <div>{message.clientId}</div>
                </div>
              </div>
            </div>
            <div>
              <div className="awsui-util-spacing-v-s">
                <div>
                  <div className="awsui-util-label">Message Type</div>
                  <div>{message.messageType}</div>
                </div>
              </div>
            </div>
          </div>
        </ColumnLayout>
      )}
    </div>
  )
}

const RawClientRequest = ({ uri }: { uri: string }) => {
  const { data, loading } = useFetch(
    uri,
    { cachePolicy: CachePolicies.NO_CACHE },
    [uri]
  )
  return loading ? <Spinner /> : <JSONViewer json={data} />
}

export default Message
