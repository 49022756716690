import React, { useState } from 'react'
import { useWebBillingFilesLog } from '../../api'
import {
  Table,
  TablePropertyFiltering,
  TablePagination,
  TableSorting,
  Button,
} from '@amzn/awsui-components-react/polaris'
import { printDateTime } from '../../utils'
import { Link, useHistory } from 'react-router-dom'

import './FilesLog.scss'
import { AuditLogFile } from '@amzn/card-dashboard-backend/src/types'

const FilesLog = () => {
  const [filteringTokens, setFilteringTokens] = useState<
    TablePropertyFiltering.FilteringToken[]
  >([defaultMonthFilter])

  const monthFilter =
    filteringTokens.find((token) => token.propertyKey === 'month') ||
    defaultMonthFilter

  const { data, loading } = useWebBillingFilesLog({
    month: monthFilter.value,
  })
  const items = data?.files || []
  const filteringOptions = getFilteringOptions(items)

  const history = useHistory()

  return (
    <div className="awsui-grid awsui-util-p-m util-full-width files-log">
      <Table
        header={
          <div className="files-log-table-header">
            <h2>Uploaded Files</h2>
            <div className="button-container">
              <Button onClick={() => history.push('/webbilling/wizard')}>
                Upload a File
              </Button>
            </div>
          </div>
        }
        columnDefinitions={columnDefinitions}
        items={data?.files || []}
        loading={loading}
        wrapLines
        features={['pagination', 'sorting', 'propertyFiltering']}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No uploaded files were found.</b>
            </div>
          </div>
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">No match found.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
      >
        <TablePropertyFiltering
          tokens={filteringTokens}
          filteringOptions={filteringOptions}
          groupPropertiesText="Properties"
          clearFiltersText="Clear filter"
          placeholder="Filter jobs by property"
          onPropertyFilteringChange={(e) => {
            // Only keep the last filter token in the array for each property key.
            // When a user adds a filter token that overlaps with an existing filter
            // token, the old filter token will be removed.
            // Make sure that there is always a month filter.
            const nextTokens = [defaultMonthFilter, ...e.detail.tokens].filter(
              (token, i, tokens) =>
                tokens
                  .map((t) => t.propertyKey)
                  .lastIndexOf(token.propertyKey) === i
            )
            setFilteringTokens(nextTokens)
          }}
          allowFreeTextFiltering={true}
          hideOperations
        />
        <TablePagination pageSize={25} />
        <TableSorting
          sortableColumns={columnDefinitions
            .filter((def) => !!def.id)
            .map((def) => ({
              id: def.id as string,
              field: def.id as string,
            }))}
          sortingColumn="statusTimestamp"
          sortingDescending={true}
        />
      </Table>
    </div>
  )
}

const columnDefinitions: Table.ColumnDefinition<AuditLogFile>[] = [
  {
    id: 'statusTimestamp',
    header: 'Date',
    cell: (file) => printDateTime(new Date(file.statusTimestamp)),
  },
  {
    id: 'filename',
    header: 'Filename',
    cell: (file) => file.filename,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (file) => file.status,
  },
  {
    id: 'uploadedBy',
    header: 'Uploaded By',
    cell: (file) => (
      <a href={`https://phonetool.amazon.com/users/${file.uploadedBy}`}>
        {file.uploadedBy}
      </a>
    ),
  },
  {
    id: 'fileId',
    header: 'File ID',
    cell: (file) => file.fileId,
  },
  {
    header: 'Billing Hub Messages',
    cell: (file) => (
      <Link to={`/billinghub/search?query=${file.fileId}`}>View</Link>
    ),
  },
]

// generate a list of month strings: ['2020-08', '2020-07', ...]
const months: string[] = []
for (
  let date = new Date();
  date.getFullYear() >= 2020;
  date.setMonth(date.getMonth() - 1)
)
  months.push(
    `${date.getFullYear()}/${(date.getMonth() + 1 + '').padStart(2, '0')}`
  )

const defaultMonthFilter: TablePropertyFiltering.FilteringToken = {
  propertyKey: 'month',
  propertyLabel: 'Month',
  value: months[0],
  label: months[0],
  negated: false,
}

const getFilteringOptions = (
  items: AuditLogFile[]
): TablePropertyFiltering.Option[] => {
  // gets a list of unique values to filter by for a given log entry key
  const getUniqueValues = (key: keyof AuditLogFile): string[] => {
    const uniqueValues = Array.from(
      items.reduce((set, item: AuditLogFile) => set.add(item[key]), new Set())
    ) as string[]
    return uniqueValues.sort((a, b) => a.localeCompare(b))
  }

  return [
    {
      propertyLabel: 'Month',
      propertyKey: 'month',
      groupValuesLabel: 'Months',
      values: months,
    },
    {
      propertyLabel: 'Status',
      propertyKey: 'status',
      groupValuesLabel: 'Statuses',
      values: ['Succeeded', 'Failed'],
    },
    {
      propertyLabel: 'Uploaded By',
      propertyKey: 'uploadedBy',
      groupValuesLabel: 'Aliases',
      values: getUniqueValues('uploadedBy'),
    },
    {
      propertyLabel: 'Bulk ID',
      propertyKey: 'bulkId',
      groupValuesLabel: 'Bulk IDs',
      values: [],
    },
  ]
}

export default FilesLog
