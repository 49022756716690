// Add Wiki link on each render
import React from 'react'
import { Link } from 'react-router-dom'
import { isProd } from '../utils'

import './Tabs.scss'

export default () => {
  return (
    <div className="tabs-container">
      <Link to="/" className="tabs-tab">
        Search
      </Link>
      <Link to="/createcustomer" className="tabs-tab">
        Create Customer
      </Link>
      <Link to="/spreadsheet-upload" className="tabs-tab">
        Spreadsheet Upload
      </Link>
      <Link to="/audit" className="tabs-tab">
        Event Log
      </Link> 
      {!isProd &&
         <Link to = "/entity-status" className="tabs-tab">
        Migration Search Engine
      </Link>}
    </div>
  )
}
