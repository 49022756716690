// this second step to the WebBilling wizard displays the data and validation status
// from the drag-and-dropped file. If the data is valid, it also handles uploading
// the file to S3.

import React, { useState } from 'react'
import {
  Table,
  TableSorting,
  TablePagination,
  Button,
  Icon,
  Alert,
  ProgressBar,
} from '@amzn/awsui-components-react/polaris'
import {
  columns as templateColumns,
  isColumnRequired,
  validateRow,
} from './uploadTemplate'
import { CSVRow } from './index'
import { webBillingRequestUpload } from '../../../api'

interface Step2Props {
  rows: CSVRow[]
  file: any
  onPrevious: () => void
  onComplete: () => void
}
const Step2 = ({ rows, file, onPrevious, onComplete }: Step2Props) => {
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [uploadProgress, setUploadProgress] = useState(0)

  // add this extra "isValid" attribute so that the Table component can easily
  // handle the validation status for filtering and sorting
  const rowsWithValidation = rows.map((row) => ({
    ...row,
    isValid: validateRow(row),
  }))
  const allValid = rowsWithValidation.every((row) => row.isValid)

  const onSubmit = async () => {
    setUploading(true)
    setError(null)
    try {
      // get a presigned S3 url to upload to
      const { data } = await webBillingRequestUpload({ filename: file.name })
      const url = data?.url
      if (!url) throw new Error('Failed to get an upload URL')

      await uploadFile({ url, file, onUploadProgress: setUploadProgress })
      setUploading(false)
      onComplete()
    } catch (e) {
      setUploading(false)
      setError('There was an error uploading the file.')
      console.error(e)
    }
    setUploading(false)
  }

  return (
    <>
      {error && (
        <Alert type="error" dismissible>
          {error}
        </Alert>
      )}
      <Table
        items={rowsWithValidation}
        features={['sorting', 'pagination']}
        columnDefinitions={[
          {
            id: 'isValid',
            header: 'Valid',
            cell: (row) =>
              row.isValid ? (
                <Icon variant="success" name="status-positive" />
              ) : (
                <Icon variant="error" name="status-negative" />
              ),
          },
          ...templateColumns.map((column) => ({
            id: column.id,
            header: column.id,
            cell: (row: { [columnName: string]: any }) => {
              const isMissing = !row[column.id] && isColumnRequired(column.id)

              return (
                <>
                  {isMissing && <Icon variant="error" name="status-negative" />}
                  {row[column.id]}
                </>
              )
            },
          })),
        ]}
        header={
          uploading ? (
            <ProgressBar
              description="Uploading to server"
              value={uploadProgress}
            />
          ) : (
            <div className="awsui-util-t-r">
              <Button onClick={onPrevious}>Go back</Button>
              <Button variant="primary" disabled={!allValid} onClick={onSubmit}>
                Submit
              </Button>
            </div>
          )
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              File does not have any rows of data.
            </div>
          </div>
        }
      >
        <TableSorting
          sortableColumns={[
            { id: 'isValid', field: 'isValid' },
            ...templateColumns.map((column) => ({
              id: column.id,
              field: column.id,
            })),
          ]}
        />
        <TablePagination pageSize={25} />
      </Table>
    </>
  )
}

// using the older XHR request method allows you to get upload progress updates
// this helper wraps everything in a more modern promise
const uploadFile = ({
  url,
  file,
  onUploadProgress,
}: {
  url: string
  file: File
  onUploadProgress: (percentage: number) => void
}) =>
  new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', url)
    xhr.upload.addEventListener('progress', (e) =>
      onUploadProgress((e.loaded / e.total) * 100)
    )
    xhr.addEventListener('loadend', () => {
      if (xhr.status >= 200 && xhr.status < 400) resolve()
      else reject()
    })
    xhr.send(file)
  })

export default Step2
