import React, { useState, useEffect } from 'react'
import { Spinner } from '@amzn/awsui-components-react/polaris'
import Lines from '../../Message/BillingHubRequest/Lines'
import HeaderCustomer from './HeaderCustomer'
import columnDefinitionsAddress from '../Address/AddressLinesColumnDefinitions'
import Table from '@amzn/awsui-components-react/polaris/table'
import {
  ActivateDeactivateCustomerInterface,
  AddressStore,
  GetCustomerRequest,
  SharedStates,
} from '../../../types'
import {
  getAllPreDefinedValues,
  getCustomerDetailsViewActivity,
} from '../../../api'
import { useLocation, useParams } from 'react-router-dom'
import CreateAddress from '../Address/CreateAddress'
import EditCustomerHeaderInfo from './EditCustomer'
import { aobIdToBusinessChannel } from '../../../Common/PredefinedValues'
import ActivateDeactivateCustomer from '.'
import { CUSTOMER_STATUS } from '../../../constants'
import FailureModal from '../Modals/FailureModal'

enum PopUpView {
  Off,
  Create,
  Pending,
}
const CustomerDetails = () => {
  const { trackingId } = useParams<{ trackingId: string }>()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  // Enables dev environemnt only features
  // Pass query params ?debug=true to see values helpful for debugging to any URL in CARD UI
  const isDevEnvironment = Boolean(params.get('debug'))

  const initialAddressData: AddressStore = {
    siteUseCode: '',
    defaultCurrency: '',
    paymentTerms: '',
    vatIdentificationNumber: '',
    displayableMarketplaceId: '',
    issuerAccountId: '',
    firstName: '',
    lastName: '',
    primaryPhoneNumber: '',
    contactEmail: '',
    secondaryPhoneNumber: '',
    secondaryEmails: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    county: '',
    state: '',
    postalCode: '',
    country: '',
    primarySite: 'false',
  }

  const [modalData, setModalData] = useState<Table.Item | undefined>(undefined)
  const [customerData, setCustomerData] = useState<any>(undefined)
  const [cimarronData, setCimarronData] = useState<any>(undefined)
  const [addressData, setAddressData] = useState<any>(undefined)
  const [addressEditData, setAddressEditData] = useState<any>(undefined)
  const [createAddressData, setCreateAddressData] = useState(initialAddressData)
  const [isEditing, setIsEditing] = useState(false)
  const [editedData, setEditedData] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)
  const [popUpData, setPopUpData] = useState(PopUpView.Off)
  const [addressTrackingId, setAddressTrackingId] = useState('')
  const [requestId, setRequestId] = useState('')
  const [termsetIDOptions, setTermsetIDOptions] = useState<any>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [aobToAobIdMap, setAobToAobIdMap] = useState<any>([])
  const [stateToCountryMap, setStateToCountryMap] = useState<any>([])
  const [countryOptions, setCountryOptions] = useState<any>([])
  const [issuerIDOptions, setIssuerIDOptions] = useState<any>([])
  const [marketplaceIDOptions, setMarketplaceIDOptions] = useState<any>([])
  const [showCreateAddressPopup, setShowCreateAddressPopup] = useState(false)
  const toggleShowCreateAddressPopup = () =>
    setShowCreateAddressPopup(!showCreateAddressPopup)
  const customerNotFoundErrorMessage =
    'Error fetching the customer. Check the customer ID and try again.'
  const customerNotFoundText = 'Customer Not Found'

  const shareStates = (): SharedStates => {
    return {
      modalData,
      setModalData,
      isEditing,
      setIsEditing,
      addressEditData,
      setAddressEditData,
      customerData,
      setCustomerData,
      cimarronData,
      setCimarronData,
      addressData,
      setAddressData,
      createAddressData,
      setCreateAddressData,
      editedData,
      setEditedData,
      popUpData,
      setPopUpData,
      addressTrackingId,
      setAddressTrackingId,
      requestId,
      setRequestId,
      termsetIDOptions,
      errorMessage,
      aobToAobIdMap,
      stateToCountryMap,
      countryOptions,
      issuerIDOptions,
      marketplaceIDOptions,
      isDevEnvironment,
    }
  }

  const activateDeactivateCustomer =
    (): ActivateDeactivateCustomerInterface => {
      return {
        customerData,
        marketplaceIDOptions,
        issuerIDOptions,
      }
    }

  useEffect(() => {
    async function getCustomerDetails() {
      try {
        const customerRequest: GetCustomerRequest = {
          trackingId: trackingId,
        }
        const { data, error } = await getCustomerDetailsViewActivity(
          customerRequest
        )
        if (!data || error) {
          throw new Error('Failed to fetch customer information!')
        }
        setCustomerData(data!)
        setCimarronData(data!.cimarronAccounts)
        setAddressData(data!.addresses)
        setEditedData(data!)
      } catch (e) {
        setLoading(false)
      }
      setLoading(false)
    }
    getCustomerDetails()
  }, [trackingId])

  useEffect(() => {
    async function getFormOptions() {
      try {
        const { data } = await getAllPreDefinedValues()
        if (!data) {
          throw new Error('Failed to fetch values')
        }

        const valuesMap = new Map(Object.entries(data!.predefinedValuesMap))
        const aobIssuerMap = new Map(
          Object.entries(data!.aobToIssuerAccountIdMap)
        )
        const stateCountryMap = new Map(Object.entries(data!.countryToStateMap))
        const aobTermsetMap = new Map(Object.entries(data!.aobToTermsetIdMap))
        const marketplaceIDList = valuesMap.get('DisplayableMarketplaceIdList')

        setAobToAobIdMap(aobIdToBusinessChannel)
        setStateToCountryMap(stateCountryMap)

        let countryOptionsList: { id: string; label: string }[] = []
        let issuerIDOptionsList: { id: string; label: string }[] = []
        let marketplaceIDOptionsList: { id: string; label: string }[] = []
        let termsetOptionsList: { id: string; label: string }[] = []

        if (customerData?.aob) {
          for (let termset of aobTermsetMap.get(customerData.aob)) {
            termsetOptionsList.push({ id: termset, label: termset })
          }
          setTermsetIDOptions(termsetOptionsList)
        }

        stateCountryMap.forEach(function (value, key) {
          countryOptionsList.push({ id: key, label: key })
        })
        setCountryOptions(countryOptionsList)

        if (aobIssuerMap && customerData && customerData.aob !== '') {
          let issuers = aobIssuerMap.get(customerData.aob)!
          issuers.forEach((issuer: string) =>
            issuerIDOptionsList.push({ id: issuer, label: issuer })
          )
          setIssuerIDOptions(issuerIDOptionsList)
        }

        for (let marketplaceID of marketplaceIDList) {
          marketplaceIDOptionsList.push({
            id: marketplaceID,
            label: marketplaceID,
          })
        }

        setMarketplaceIDOptions(marketplaceIDOptionsList)
      } catch (e) {
        setErrorMessage('There was an error fetching form values')
        console.error(e)
      }
    }
    getFormOptions()
    // force recall on hook since customerData may or may not be undefined since it is fetched from async function
    // eslint-disable-next-line
  }, [customerData])

  return (
    <div className="awsui-util-container">
      {loading && (
        <div className="awsui-util-container awsui-util-t-c">
          <Spinner />
        </div>
      )}
      {!loading && customerData && (
        <>
          <div className="awsui-util-container-header">
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>{`Customer (${customerData?.customerId})`}</h2>
              </div>
              <div className="awsui-util-action-stripe-group">
                <EditCustomerHeaderInfo shareStates={shareStates} />
                <ActivateDeactivateCustomer
                  activateDeactivateCustomer={activateDeactivateCustomer}
                />
              </div>
            </div>
          </div>
          <HeaderCustomer shareStates={shareStates} />
          <Lines
            lines={addressData}
            columnDefinitionsOptional={columnDefinitionsAddress(shareStates)}
            showCreateAddressPopup={toggleShowCreateAddressPopup}
          />
        </>
      )}
      {!loading && !customerData && (
        <FailureModal
          errorText={`${customerNotFoundText}${customerNotFoundErrorMessage} `}
        />
      )}
      {showCreateAddressPopup &&
        customerData.status !== CUSTOMER_STATUS.SUSPENDED && (
          <CreateAddress
            shareStates={shareStates}
            setShowCreateAddressPopup={toggleShowCreateAddressPopup}
            showCreateAddressPopup={showCreateAddressPopup}
          />
        )}
    </div>
  )
}

export default CustomerDetails
