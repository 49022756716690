import { Alert } from "@amzn/awsui-components-react"
import React from "react"

const Restricted = () => {
  return (
  <Alert header="Access Denied" type="error">
    You do not have proper permissions to view this page. To request permissions, please submit a request to {' '}
    <a
      href="https://sim.amazon.com/issues/create?template=63437e7b-13fe-4280-bbcd-2c317c4be39d"
      target="_blank"
      rel="noopener noreferrer"
    >
      submit a ticket
    </a>
    .
  </Alert>
)
}

export default Restricted