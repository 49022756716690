import React from 'react'
import { ColumnLayout } from '@amzn/awsui-components-react/polaris'
import DataField from '../../../Common/DataField'

import { aobIdToBusinessChannel } from '../../../Common/PredefinedValues'
import { SharedStates } from '../../../types'

const HeaderCustomer = ({
  shareStates,
}: {
  shareStates: () => SharedStates
}) => {
  const {
    customerData: {
      customerName,
      customerId,
      primaryEmail,
      status,
      aob,
      maxOpenToBuyCurrency,
      maxOpenToBuyValue,
      termsetId,
    },
    cimarronData,
    isDevEnvironment,
  } = shareStates()

  return (
    <ColumnLayout columns={3} variant="text-grid">
      <div data-awsui-column-layout-root="true">
        <div className="awsui-util-spacing-v-s">
          <DataField label="Customer name">{customerName}</DataField>
          <DataField label="Customer ID">{customerId}</DataField>
          <DataField label="Marketplace">
            {cimarronData[0].displayableAddressMarketplaceId}
          </DataField>
        </div>
        <div className="awsui-util-spacing-v-s">
          <DataField label="Customer primary email address">
            {primaryEmail}
          </DataField>
          <DataField label="Max open to buy value">
            {maxOpenToBuyValue}
          </DataField>
          <DataField label="Payment terms">{termsetId}</DataField>
        </div>
        <div className="awsui-util-spacing-v-s">
          <DataField label="Channel">
            {aobIdToBusinessChannel.get(aob)}
          </DataField>
          <DataField label="Default currency">{maxOpenToBuyCurrency}</DataField>

          <DataField label="Status">
            <h4>
              <div className="capitalize">{status}</div>
            </h4>
          </DataField>
        </div>
        {isDevEnvironment && (
          <div>
            <div className="awsui-util-spacing-v-s">
              <DataField label="Cimarron account ID">
                {cimarronData[0].cimarronAccountId}
              </DataField>
              <DataField label="Issuer account ID">
                {cimarronData[0].issuerAccountId}
              </DataField>
            </div>
          </div>
        )}
      </div>
    </ColumnLayout>
  )
}

export default HeaderCustomer
