import React, { useState } from 'react'
import { Wizard } from '@amzn/awsui-components-react/polaris'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

import './index.scss'

export interface CSVRow {
  [key: string]: string
}
const UploadWizard = () => {
  const [rows, setRows] = useState<CSVRow[]>([])
  const [file, setFile] = useState<any>(undefined)
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  return (
    <div className="awsui-grid awsui-util-p-m upload-wizard">
      <div className="awsui-util-container awsui-util-p-m">
        <Wizard
          activeStepIndex={activeStepIndex}
          steps={[
            {
              title: 'Upload a File',
              content: (
                <Step1
                  setRows={setRows}
                  setFile={setFile}
                  onNext={() => setActiveStepIndex((prev) => prev + 1)}
                />
              ),
            },
            {
              title: 'Validation',
              content: (
                <Step2
                  rows={rows}
                  file={file}
                  onPrevious={() => setActiveStepIndex((prev) => prev - 1)}
                  onComplete={() => setActiveStepIndex((prev) => prev + 1)}
                />
              ),
            },
            {
              title: 'Complete',
              content: <Step3 />,
            },
          ]}
          i18nStrings={{
            nextButton: 'Next',
            previousButton: 'Previous',
            cancelButton: 'Cancel',
            collapsedStepsLabel: (stepNumber, stepCount) =>
              `${stepNumber}/${stepCount}`,
            optional: 'Optional',
            stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
            submitButton: 'Submit',
          }}
        />
      </div>
    </div>
  )
}

export default UploadWizard
