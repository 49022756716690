import React from 'react'
import TableSorting from '@amzn/awsui-components-react/polaris/table-sorting'
import { Badge, Button } from '@amzn/awsui-components-react/polaris'
import { Modal } from '@amzn/awsui-components-react'
import ViewAddressDetails from './ViewAddressDetails'

import { Address, SharedStates } from '../../../types'
import { ADDRESS_STATUS, CUSTOMER_STATUS } from '../../../constants'
import DeactivateAddress from './DeactivateAddress'
import UpdateAddress from './UpdateAddress'
import EditAddress from './SetAsPrimaryAddress'
import { addressTypes, getField } from '../../../utils'

const columnDefinitionsAddress = (shareStates: () => SharedStates) => {
  const {
    modalData,
    setModalData,
    addressData,
    customerData: { status: customerAccountStatus },
  } = shareStates()

  const columns = [
    {
      id: 'address',
      header: 'Address',
      cell: (item: Address) => (
        <div>
          {getField('addressLine1', item)} &nbsp;
          {getField('addressLine2', item)} &nbsp;
          {getField('addressLine3', item)} &nbsp;
          <br />
          {getField('city', item)} &nbsp;
          {getField('state', item)} &nbsp;
          {getField('postalCode', item)} &nbsp;
          <br />
          {getField('country', item)}
        </div>
      ),
      width: 400,
    },
    {
      id: 'contactInfo',
      header: 'Contact info',
      cell: (item: Address) => (
        <div>
          {getField('firstName', item)} &nbsp;
          {getField('lastName', item)} <br />
          {getField('contactEmail', item)} <br />
          {getField('primaryPhoneNumber', item)}
        </div>
      ),
      width: 300,
    },
    {
      id: 'siteUseCode',
      header: 'Address type',
      cell: (item: Address) => (
        <div>
          {
            addressTypes.find(
              (site) => site.id === getField('siteUseCode', item)
            )?.label
          }
        </div>
      ),
      width: 150,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item: Address) => (
        <div>
          <Badge
            color={
              getField('status', item) === ADDRESS_STATUS.INACTIVE
                ? 'red'
                : 'blue'
            }
          >
            {getField('status', item)}
          </Badge>
          <br />
          <br />
          {getField('primarySite', item) && (
            <Badge color={'green'}>Primary address</Badge>
          )}
        </div>
      ),
      width: 150,
    },
    {
      id: 'view',
      cell: (item: Address) => (
        <div>
          <Button onClick={() => setModalData(item)}>View</Button>
          {modalData && item.addressId === modalData.addressId && (
            <Modal
              header="Customer Address Details"
              visible={modalData !== undefined}
              onDismiss={() => setModalData(undefined)}
            >
              <ViewAddressDetails
                addressData={addressData?.find(
                  (elem: any) => elem.addressId === modalData.addressId
                )}
                getField={getField}
              />
            </Modal>
          )}
        </div>
      ),
      width: 150,
    },
    {
      id: 'update',
      cell: (item: Address) =>
        (customerAccountStatus !== CUSTOMER_STATUS.SUSPENDED &&
          getField('status', item)) !== ADDRESS_STATUS.INACTIVE && (
          <UpdateAddress item={item} shareStates={shareStates} />
        ),
      width: 200,
    },
    {
      id: 'edit',
      cell: (item: Address) =>
        customerAccountStatus !== CUSTOMER_STATUS.SUSPENDED &&
        getField('status', item) !== ADDRESS_STATUS.INACTIVE && (
          <EditAddress item={item} shareStates={shareStates} />
        ),
      width: 200,
    },
    {
      id: 'deactivate',
      cell: (item: Address) =>
        customerAccountStatus !== CUSTOMER_STATUS.SUSPENDED &&
        getField('status', item) === ADDRESS_STATUS.INACTIVE ? null : (
          <DeactivateAddress item={item} shareStates={shareStates} />
        ),
      width: 200,
    },
  ]

  return columns.map((c) => ({
    ...c,
    // generate label functions based on column attributes
    label: (sortState: TableSorting.SortingState) => {
      const columnIsSorted = sortState.sortingColumn === c.id
      const ascending = !sortState.sortingDescending
      return `${c.header}, ${
        columnIsSorted
          ? `sorted ${ascending ? 'ascending' : 'descending'}`
          : 'not sorted'
      }.`
    },
  }))
}

export default columnDefinitionsAddress
