import { getUserPermission } from '../api'

export interface ValidateAuthTokenResponse {
  isValid: boolean
  userAlias: string
  permissionSet: string[]
}

export const permissions = {
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CREATE_INVOICE: 'CREATE_INVOICE',
  CREATE_CREDIT_MEMO: 'CREATE_CREDIT_MEMO',
  UPLOAD_INVOICE: 'UPLOAD_INVOICE',
  READ_CUSTOMER_SCREEN: 'READ_CUSTOMER_SCREEN',
  READ_EVENT_LOG_SCREEN: 'READ_EVENT_LOG_SCREEN',
  READ_SEARCH_SCREEN: 'READ_SEARCH_SCREEN',
  PRIVILEGED_ACCESS: 'PRIVILEGED_ACCESS',
}

const Authentication = async () => {
  try {
    const { data } = await getUserPermission()
    if (!data) {
      throw new Error('Failed to fetch user permissions')
    }
    console.log(data!.permissionSet)
    return data!.permissionSet
  } catch (e) {
    console.log(e)
    return []
  }
}

export default Authentication
