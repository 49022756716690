import React, { useState } from 'react'
import {
  Form,
  FormField,
  ColumnLayout,
  DatePicker,
  Button,
  Select,
  Popover,
  Checkbox,
} from '@amzn/awsui-components-react/polaris'
import Autosuggest from '@amzn/awsui-components-react/polaris/autosuggest'
import { STAGES, getStage, usePersistedState } from '../../utils'

import './Settings.scss'

const messageTypeOptions = [
  { id: 'ALL', label: 'All' },
  { id: 'INVOICE', label: 'Invoices' },
  { id: 'CREDIT_MEMO', label: 'Credit Memos' },
]
const statusOptions = [
  { id: 'ALL', label: 'All' },
  { id: 'PENDING', label: 'Pending' },
  { id: 'SUCCEEDED', label: 'Succeeded' },
  { id: 'FAILED', label: 'Failed' },
]
const stages = [
  { id: 'prod', label: 'Prod' },
  { id: 'gamma', label: 'Gamma' },
  { id: 'beta', label: 'Beta' },
]

const devStages = [
  { id: 'gamma', label: 'Gamma' },
  { id: 'beta', label: 'Beta' },
]

const SearchSettings = ({
  initialParams,
  onDownloadResults,
  onSearch,
}: {
  initialParams: any
  onDownloadResults: () => void
  onSearch: (params: any) => void
}) => {
  const [query, setQuery] = useState(initialParams.query)
  const [status, setStatus] = useState(initialParams.status)
  const [messageType, setMessageType] = useState(initialParams.messageType)
  const [before, setBefore] = useState(initialParams.before)
  const [after, setAfter] = useState(initialParams.after)
  const [stage, setStage] = useState(initialParams.stage)
  const [queryParamsAdded, setQueryParamsAdded] = useState(false)
  const [isIngestionDate, setIsIngestionDate] = useState(false)
  const [recentQueries, setRecentQueries] = usePersistedState(
    [],
    'recent-queries'
  )
  const onSubmit = (e: any) => {
    setRecentQueries(
      [query, ...recentQueries]
        .filter((q, i, queries) => queries.indexOf(q) === i)
        .slice(0, 100)
    )
    onSearch({
      query,
      status,
      messageType,
      before,
      after,
      stage,
      isIngestionDate,
    })
    e.preventDefault()
    setQueryParamsAdded(true)
    return false
  }

  return (
    <form onSubmit={onSubmit} className="search-settings">
      <Form>
        <ColumnLayout reset-filter-link columns={3}>
          <div data-awsui-column-layout-root="true">
            <FormField label="Query">
              <div className="stack-checkboxes">
                <Autosuggest
                  id="quicksearch"
                  placeholder="Search for message ID, request ID, transaction number, or file ID"
                  autofocus={true}
                  ariaRequired={true}
                  disableBrowserAutocorrect={true}
                  value={query}
                  onChange={(e) => setQuery(e.detail.value)}
                  options={recentQueries
                    .map((q: string) => ({ value: q }))
                    .slice(0, 20)}
                  empty="No matching recent queries"
                  onKeydown={(e) => {
                    if (e.detail.keyCode === 13) onSubmit(e)
                  }}
                />
              </div>
            </FormField>
            <FormField label="Billing Hub Stage">
              <div className="stack-checkboxes">
                <Select
                  selectedOption={stages.find((s) => s.id === stage)}
                  options={getStage() === STAGES.PROD ? stages : devStages}
                  onChange={(e) => setStage(e.detail.selectedId)}
                />
              </div>
            </FormField>
            <FormField label="Message Types">
              <div className="stack-checkboxes">
                <Select
                  selectedOption={messageTypeOptions.find(
                    (type) => type.id === messageType
                  )}
                  options={messageTypeOptions}
                  onChange={(e) => setMessageType(e.detail.selectedId)}
                />
              </div>
            </FormField>
            <FormField label="Status">
              <div className="toggle-group">
                <Select
                  selectedOption={statusOptions.find(
                    (type) => type.id === status
                  )}
                  options={statusOptions}
                  onChange={(e) => setStatus(e.detail.selectedId)}
                />
              </div>
            </FormField>
            <FormField
              label={
                <Popover
                  content="Deafault search is by transaction date. 
                If data is not available, enable search by ingestion data"
                >
                  Between Dates
                </Popover>
              }
            >
              <div className="date-range-group">
                <DatePicker
                  placeholder="YYYY/MM/DD"
                  todayLabel="Today"
                  nextMonthLabel="Next month"
                  previousMonthLabel="Previous month"
                  onChange={(e) => {
                    setAfter(e.detail.value)
                    if (before && e.detail.value > before)
                      setBefore(e.detail.value)
                  }}
                  value={after}
                />
                <div className="date-range-group-spacer" />
                <DatePicker
                  placeholder="YYYY/MM/DD"
                  todayLabel="Today"
                  nextMonthLabel="Next month"
                  previousMonthLabel="Previous month"
                  onChange={(e) => {
                    setBefore(e.detail.value)
                    if (after && e.detail.value < after)
                      setAfter(e.detail.value)
                  }}
                  value={before}
                />
              </div>
              {
                <Checkbox
                  onChange={() => setIsIngestionDate(!isIngestionDate)}
                  checked={isIngestionDate}
                >
                  Search by Ingestion date
                </Checkbox>
              }
            </FormField>

            <div className="search-button-container">
              <Popover
                triggerType="custom"
                content={
                  !queryParamsAdded
                    ? 'Input search query to download results.'
                    : "Only the current page's results are included in the CSV file. Please adjust page preferences to expand results."
                }
              >
                <Button
                  disabled={!queryParamsAdded}
                  variant="link"
                  icon="download"
                  onClick={onDownloadResults}
                >
                  Download Results
                </Button>
              </Popover>
              <Button
                variant="primary"
                onClick={() =>
                  onSearch({
                    query,
                    status,
                    messageType,
                    before,
                    after,
                    stage,
                    isIngestionDate,
                  })
                }
              >
                Search
              </Button>
            </div>
          </div>
        </ColumnLayout>
      </Form>
    </form>
  )
}

export default SearchSettings
