// first step of the Web Billing wizard which allows users to select the client ID,
// download a CSV template, and drag and drop a file. When the file is dropped, a
// progress bar shows for a moment and then the wizard goes to the next step. This
// component also handles parsing the CSV file into JSON data that can be validated
// in the next step.

import React, { useState } from 'react'
import * as template from './uploadTemplate'
import { downloadString } from '../../../utils'
import { useDropzone } from 'react-dropzone'
import { parse } from 'papaparse'
import FakeProgressBar from './FakeProgressBar'
import { CSVRow } from './index'

import './Step1.scss'

interface Step1Props {
  setRows: (rows: CSVRow[]) => void
  setFile: (file: any) => void
  onNext: () => void
}
const Step1 = ({ setRows, setFile, onNext }: Step1Props) => {
  const [isParsing, setIsParsing] = useState(false)

  const onDrop = (files: any[]) => {
    parse(files[0], {
      header: true,
      error: console.log,
      complete: ({ data }) => {
        // this code makes sure that only data that fits the templates columns gets uploaded
        // and that the columns don't have the * appended to their names anymore
        const cleanedData = data.map((row) => {
          const newRow: CSVRow = {}
          template.columns.forEach((column) => {
            newRow[column.id] = row[column.id] || row[column.id + '*']
          })
          return newRow
        })

        setRows(cleanedData)
        setFile(files[0])
        setIsParsing(true)
      },
    })
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const downloadTemplate = () =>
    downloadString(`WebBilling-template.csv`, template.csv, 'text/csv')

  return (
    <div>
      <p>WebBilling allows you to manually send bulk request to Billing Hub.</p>
      <ol>
        <li>
          <span className="link" onClick={downloadTemplate}>
            Download this CSV template
          </span>
        </li>
        <li>
          Fill out the template making sure to include all required fields
        </li>
        <li>Drag the completed CSV file into the box below</li>
      </ol>
      <br />
      {isParsing ? (
        // Files load immediately. The straightforward approach is to go to
        // the next step immediately when the file is parsed. The transition
        // from dropping the file and the next step appearing happens too quickly
        // which feels jarring, unsatisfying, and suspicious. Showing a
        // progress bar for a moment feels satisfying and will give users
        // confidence that the UI is working correctly.
        <FakeProgressBar onComplete={onNext} />
      ) : (
        <div
          {...getRootProps({
            className: isDragActive ? 'dropzone dragging' : 'dropzone',
          })}
          data-testid="dropzone"
        >
          <input {...getInputProps()} data-testid="input" id="input" />
          Drag and drop the completed CSV file here or click to select the file
          from your computer.
        </div>
      )}
    </div>
  )
}

export default Step1
