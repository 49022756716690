import React from 'react'
import { Link } from 'react-router-dom'
import Routes from '../Routes'
import Feedback from '../Common/Feedback'
import IsItDown from '../Common/IsItDown'
import WikiLinks from '../Common/WikiLinks'
import Tabs from '../Common/Tabs'
import Flash from '@amzn/awsui-components-react/polaris/flash'
import { dismissError } from '../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../store/reducer'
import './index.scss'

const Layout = () => (
  <div
    className={`awsui awsui-motion whole-page`}
  >
    <Errors />
    <nav className={`website-navigation-top`}>
      <div className="website-navigation-top-logo">
        <Link to="/" className="logo-link">
          CARD
        </Link>
        <div className="logo-subtitle">
          Centralized Accounts Receivable Dashboard
        </div>
      </div>
      <Tabs />
      <div className="website-navigation-top-menu-spacer"></div>
      <WikiLinks />
      <Feedback />
      <IsItDown />
    </nav>
    <div className="website-content">
      <Routes />
    </div>
  </div>
)

export const Errors = () => {
  const errors = useSelector((state: State) => state.errors).slice(0, 10)
  const dispatch = useDispatch()
  return (
    <div className="error-box">
      {errors.map((error, i) => (
        <Flash
          dismissible={true}
          key={i}
          type="error"
          onDismiss={() => dispatch(dismissError(i))}
        >
          {error}
        </Flash>
      ))}
    </div>
  )
}

export default Layout
