import React, { useEffect, useState } from 'react'
import Form from '@amzn/awsui-components-react/polaris/form'
import Select from '@amzn/awsui-components-react/polaris/select'
import { Button, ColumnLayout, FormField } from '@amzn/awsui-components-react'
import FakeProgressBar from '../WebBilling/Wizard/FakeProgressBar'
import './NotificationForm.scss'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import { Email } from './index'
import { getAllBusinessChannelsAndMarketplaces } from '../../api'
import UploadRequest from './UploadRequest'
import { downloadString } from '../../utils'
import SampleSpreadsheet from './SampleSpreadsheet'
import { permissions } from '../../Common/Authentication'

export interface UploadFileRequest {
  email: {
    emailId?: string
    ccAlias?: string[]
  }
  uploadInfo: {
    businessChannel?: string
    messageType?: string
    marketplace?: string
    fileType?: string
  }
  s3Info: {
    s3ObjectKey?: string
    s3BucketName?: string
  }
}

export interface UploadFileResponse {
  trackingId: string
  errors: string[]
}

export interface PresignedURLRequest {
  fileName: string
  fileType: string
}

export interface PresignedURLResponse {
  fileName: string
  bucketName: string
  url: string
}

export interface BusinessChannelAndMarketplace {
  businessChannelMap: Map<string, string[]>
  errors: string[]
}

const UploadInfoForm = ({
  notificationFormInputs,
  permissionSet
}: {
  notificationFormInputs: Email,
  permissionSet: any
}) => {
  const messageTypeOptions:any = []
  if (permissionSet.includes(permissions.CREATE_INVOICE) || permissionSet.includes(permissions.PRIVILEGED_ACCESS)) {
    messageTypeOptions.push({ id: 'INVOICE', label: 'INVOICE' })
  }
  if (permissionSet.includes(permissions.CREATE_CREDIT_MEMO) || permissionSet.includes(permissions.PRIVILEGED_ACCESS)) {
    messageTypeOptions.push({ id: 'CREDIT_MEMO', label: 'CREDIT MEMO' })
  }

  const fileTypeOptions = [{ id: 'CSV', label: 'CSV' }]

  const onSubmit = async (e: any) => {
    setButtonLoading(true)
    e.preventDefault()
    if (
      !businessChannel ||
      !messageType ||
      !marketplace ||
      !fileType ||
      !file
    ) {
      setButtonLoading(false)
      setErrorMessage('Missing required fields')
      return
    }

    setErrorMessage('')

    const uploadFileRequest: UploadFileRequest = {
      email: {
        emailId: notificationFormInputs.emailId,
        ccAlias: !notificationFormInputs.ccAlias
          ? []
          : notificationFormInputs.ccAlias.split(/[ ,;]+/),
      },
      uploadInfo: {
        businessChannel: businessChannel,
        messageType: messageType,
        marketplace: marketplace,
        fileType: fileType,
      },
      s3Info: {
        s3ObjectKey: undefined,
        s3BucketName: undefined,
      },
    }

    let reg = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}')

    if (!reg.test(notificationFormInputs.emailId!)) {
      setErrorMessage('Improper email format!')
      setButtonLoading(false)
      return
    }

    if (!DomainCheck(notificationFormInputs.emailId!)) {
      setErrorMessage('Email domain not allowed')
      setButtonLoading(false)
      return
    }

    uploadFileRequest.email.ccAlias?.forEach((email) => {
      if (!reg.test(email)) {
        setErrorMessage('Improper cc email format!')
        setButtonLoading(false)
        return
      }
      if (!DomainCheck(email)) {
        setErrorMessage('Email domain not allowed')
        setButtonLoading(false)
        return
      }
    })

    const { data, error } = await UploadRequest(uploadFileRequest, file)
    const trackingId = data?.trackingId
    const errors = data?.errors
    if (errors) {
      var errorString = ''
      errors.forEach(function (errMessage: string) {
        errorString += errMessage
      })
      setErrorMessage(errorString)
      setButtonLoading(false)
      return
    }
    if (error || !trackingId) {
      console.log('error')
      setErrorMessage('Upload Request Failed!')
      setButtonLoading(false)
      return
    } else {
      setButtonLoading(false)
      history.push(
        '/spreadsheet-upload/confirmation/' + encodeURIComponent(trackingId)
      )
    }
    setErrorMessage('Valid')
    setButtonLoading(false)
  }

  const onDownload = () => {
    downloadString(
      `sample-spreadsheet-template.csv`,
      SampleSpreadsheet,
      'text/csv'
    )
  }

  const onDrop = (files: any[]) => {
    setIsParsing(true)
    setFile(files[0])
  }

  const onClear = () => {
    setIsParsing(false)
    setFile(undefined)
  }

  const history = useHistory()
  const [isParsing, setIsParsing] = useState(false)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const [businessMarketplaceMap, setBusinessMarketplaceMap] =
    useState<any>(undefined)
  const [businessChannelOptions, setBusinessChannelOptions] = useState<any>([])
  const [marketplaceOptions, setMarketplaceOptions] = useState<any>([])
  const [businessChannel, setBusinessChannel] = useState('')
  const [messageType, setMessageType] = useState('')
  const [marketplace, setMarketplace] = useState('')
  const [fileType, setFileType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [file, setFile] = useState<any>(undefined)

  useEffect(() => {
    async function getBusinessChannelOptions() {
      try {
        const { data } = await getAllBusinessChannelsAndMarketplaces()
        if (!data) {
          throw new Error('Failed to fetch Business Channels')
        }
        const businessObj = data!.businessChannelMap
        const businessMap = new Map(Object.entries(businessObj))
        setBusinessMarketplaceMap(businessMap)
        let businessChannelOptions: { id: string; label: string }[] = []
        businessMap.forEach(function (value, key) {
          businessChannelOptions.push({ id: key, label: key })
        })
        setBusinessChannelOptions(businessChannelOptions)
      } catch (e) {
        setErrorMessage('There was an error fetching Business Channels')
        console.error(e)
      }
    }
    getBusinessChannelOptions()
  }, [])

  useEffect(() => {
    function updateMarketplaceOptions(marketplaceMap: Map<string, string[]>) {
      if (marketplaceMap && businessChannel !== '') {
        let marketplaces = marketplaceMap.get(businessChannel)!
        let marketplaceOptions: { id: string; label: string }[] = []
        marketplaces.forEach((marketplace) =>
          marketplaceOptions.push({ id: marketplace, label: marketplace })
        )
        setMarketplaceOptions(marketplaceOptions)
      }
      return
    }
    updateMarketplaceOptions(businessMarketplaceMap)
    // Adding linter disabling to make dependency array clearer, only a change to businessChannel triggers rerender
    // eslint-disable-next-line
  }, [businessChannel])

  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>Upload Info</h2>
          </div>
        </div>
      </div>
      <ColumnLayout columns={2} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          <div>
            <div className="awsui-util-spacing-v-s">
              <form onSubmit={onSubmit}>
                <Form errorText={errorMessage}>
                  <FormField description="(required)" label="Business Channel">
                    <Select
                      ariaRequired={true}
                      options={businessChannelOptions}
                      onChange={(e) => {
                        setBusinessChannel(e.detail.selectedId)
                      }}
                      className="awsui-util-mv-s"
                    />
                  </FormField>
                  <FormField description="(required)" label="Message Type">
                    <Select
                      ariaRequired={true}
                      options={messageTypeOptions}
                      onChange={(e) => setMessageType(e.detail.selectedId)}
                      className="awsui-util-mv-s"
                    />
                  </FormField>
                  <FormField description="(required)" label="Marketplace">
                    <Select
                      ariaRequired={true}
                      options={marketplaceOptions}
                      onChange={(e) => setMarketplace(e.detail.selectedId)}
                      className="awsui-util-mv-s"
                    />
                  </FormField>
                  <FormField description="(required)" label="File Type">
                    <Select
                      ariaRequired={true}
                      options={fileTypeOptions}
                      onChange={(e) => setFileType(e.detail.selectedId)}
                      className="awsui-util-mv-s"
                    />
                  </FormField>
                </Form>
              </form>
              {isParsing ? (
                <FakeProgressBar onComplete={() => setIsParsing(false)} />
              ) : file === undefined ? (
                <div
                  {...getRootProps({
                    className: isDragActive ? 'dropzone dragging' : 'dropzone',
                  })}
                  data-testid="dropzone"
                >
                  <input {...getInputProps()} data-testid="input" id="input" />
                  Drag and drop the completed CSV file here or click to select
                  the file from your computer.
                </div>
              ) : (
                <Button onClick={onClear} icon="undo">{`${file.name}`}</Button>
              )}
            </div>
          </div>
        </div>
      </ColumnLayout>
      <Button variant="primary" onClick={onSubmit} loading={buttonLoading}>
        Submit
      </Button>
      <Button variant="link" icon="download" onClick={onDownload}>
        Download Sample Invoice Template
      </Button>
    </div>
  )
}

const DomainCheck = (email: string) => {
  //Can be edited to expand list of allowable domains
  const domainList = ['@amazon.com', '@amazon.co.uk']
  let emailFlag = false
  domainList.forEach((domain) => {
    if (email.endsWith(domain)) {
      emailFlag = true
    }
  })
  if (emailFlag) {
    return true
  }
  return false
}

export default UploadInfoForm
