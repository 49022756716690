import React, { useEffect } from 'react'
import Form from '@amzn/awsui-components-react/polaris/form'
import {
  ColumnLayout,
  FormField,
  Input,
} from '@amzn/awsui-components-react'
import './NotificationForm.scss'
import { Email } from './index'
import { getSession } from '../../api'
import handleInputChange from '../../Common/HandleInputChange'

const NotificationForm = ({
  formInputs,
  setFormInputs,
}: {
  formInputs: Email
  setFormInputs: any
}) => {

  const amazonDomain = '@amazon.com'
  const emailIdField = 'emailId'

  useEffect(() => {
    async function getAlias() {
      const session = await getSession()
      const alias = session.getIdToken().decodePayload().identities[0].userId
      const emailAlias = alias + amazonDomain
      handleInputChange(emailAlias, emailIdField, setFormInputs, formInputs)
    }
    getAlias()
  //Render once on initial page load, don't want to reset value on change to setFormInputs or formInputs
  //eslint-disable-next-line
  }, [])

  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>Notification</h2>
          </div>
        </div>
      </div>
      <ColumnLayout columns={2} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          <div>
            <div className="awsui-util-spacing-v-s">
              <Form>
                <FormField label="Email">
                  <Input
                    placeholder={
                      'Please enter an @amazon domain email for notification of upload status'
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e.detail.value,
                        'emailId',
                        setFormInputs,
                        formInputs
                      )
                    }
                    value={formInputs['emailId']}
                    className="awsui-util-mv-s"
                  />
                </FormField>
                <FormField label="CC Alias">
                  <Input
                    placeholder={
                      'Please enter any additional emails separated by commas or semi-colons'
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e.detail.value,
                        'ccAlias',
                        setFormInputs,
                        formInputs
                      )
                    }
                    value={formInputs['ccAlias']}
                    className="awsui-util-mv-s"
                  />
                </FormField>
              </Form>
            </div>
          </div>
        </div>
      </ColumnLayout>
    </div>
  )
}

export default NotificationForm
