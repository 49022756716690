import React, { useEffect, useState } from 'react'
import Table from '@amzn/awsui-components-react/polaris/table'
import {
  printDate,
  printCurrency,
  dateStringToTimestamp,
  isProd,
  getStage,
  STAGES,
} from '../../utils'
import { Link } from 'react-router-dom'
import Status from '../../Common/Status'
import { Message } from '../../types'
import { useSearch } from '../../api'
import SearchResults from './Search'
import SearchSettings from './Settings'
import Authentication, { permissions } from '../../Common/Authentication'
import Restricted from '../../Common/Restricted'
import Spinner from '@amzn/awsui-components-react/polaris/spinner'

export default function MessageSearch() {
  const [permissionSet, setPermissionSet] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getPermissionSet() {
      if (isProd) {
        setPermissionSet([permissions.READ_SEARCH_SCREEN])
        setLoading(false)
      } else {
        const data = await Authentication()
        setPermissionSet(data)
        setLoading(false)
      }
    }
    getPermissionSet()
  }, [])

  if (loading) {
    return (
      <div className="awsui-util-container awsui-util-t-c">
        <Spinner />
      </div>
    )
  } else {
    if (
      permissionSet &&
      (permissionSet.includes(permissions.READ_SEARCH_SCREEN) ||
        permissionSet.includes(permissions.PRIVILEGED_ACCESS))
    )
      return (
        <SearchResults
          getColumnDefinitions={getColumnDefinitions}
          useSearch={useSearch}
          getSearchParams={getSearchParams}
          searchSettings={getSettings}
        />
      )
    else {
      return Restricted()
    }
  }
}

const getSearchParams = (queryParams: any) => {
  const getBefore = (before: any) => {
    if (before) {
      let date = dateStringToTimestamp(before)
      if (date) date += 24 * 60 * 60 - 1
      return date
    }
  }

  return {
    query: queryParams.query || '',
    before: getBefore(queryParams.before) || undefined,
    after: dateStringToTimestamp(queryParams.after) || undefined,
    statuses:
      queryParams.status === 'ALL' || !queryParams.status
        ? undefined
        : [queryParams.status],
    messageTypes:
      queryParams.messageType === 'ALL' || !queryParams.messageType
        ? undefined
        : [queryParams.messageType],
    stage: queryParams.stage || 'prod',
    isIngestionDate: queryParams.isIngestionDate === 'true',
  }
}

const getSettings = (params: any, history: any, onDownloadResults: any) => {
  const initialParams = {
    query: params.query || '',
    before: params.before || '',
    after: params.after || '',
    status: params.status || 'ALL',
    messageType: params.messageType || 'ALL',
    stage: params.stage || (getStage() === STAGES.PROD ? 'prod' : 'beta'),
  }

  return (
    <SearchSettings
      initialParams={initialParams}
      onSearch={(params) => {
        const newParams = new URLSearchParams(window.location.search)
        Object.entries(params).forEach(([key, value]) =>
          newParams.set(key, ('' + value) as string)
        )
        history.push(`${window.location.pathname}?${newParams.toString()}`)
      }}
      onDownloadResults={onDownloadResults}
    />
  )
}

const getColumnDefinitions = (params: any): Table.ColumnDefinition[] => [
  {
    id: 'messageId',
    header: 'Message ID',
    cell: (item: Message) =>
      item.messageId && (
        <Link
          to={`/billinghub/message/${params.stage}/${encodeURIComponent(
            item.messageId
          )}`}
        >
          {item.messageId}
        </Link>
      ),
  },
  {
    id: 'clientRequestId',
    header: 'Client Request Id',
    cell: (item: Message) => item.clientRequestId,
  },
  {
    id: 'clientId',
    header: 'Client',
    cell: (item: Message) => item.clientId,
  },
  {
    id: 'messageType',
    header: 'Message Type',
    cell: (item: Message) => item.messageType,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item: Message) => <Status status={item.messageStatus} />,
  },
  {
    id: 'trxDate',
    header: 'Transaction Date',
    cell: (item: Message) =>
      item.trxDate && printDate(new Date(item.trxDate * 1000)),
  },
  {
    id: 'controlTotalLineAmount',
    header: 'Amount',
    cell: (item: Message) =>
      printCurrency(item.controlTotalLineAmount, item.currencyCode),
  },
  {
    id: 'ingestionTime',
    header: 'Ingestion Date',
    cell: (item: Message) =>
      item.ingestionTime && printDate(new Date(item.ingestionTime * 1000)),
  },
  {
    id: 'messageStatusRaw',
    header: 'Raw Message Status',
    cell: (item: Message) => item.messageStatusRaw,
  },
]
