export const CARDHostName = {
  PROD: 'card.fintech.amazon.dev',
  BETA: 'dev.card.fintech.amazon.dev',
  LOCALHOST: 'localhost',
}

export const CUSTOMER_STATUS = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
}

export const ADDRESS_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}

export const ADDRESS_TYPES = {
  BILL_TO: 'bill_to',
  SHIP_TO: 'ship_to',
}

export const ADDRESS_TYPES_LABELS = {
  BILL_TO: 'Bill To',
  SHIP_TO: 'Ship To',
}

export const PRIMARY_SITE = {
  YES: 'Yes',
  NO: 'No',
}

export const STATUS_ORDER = {
  Active: 1,
  Inactive: 2,
}

export const PRIMARY_ADDRESS_TEXT = 'This is a primary address'
export const SHIP_TO_ADDRESS_TEXT = 'You cannot set Ship To address as primary'
export const DEACTIVATE_ADDRESS_TEXT =
  'You cannot deactivate primary address, to deactivate please make another address primary'
