import React from 'react'
import { Link, useParams } from 'react-router-dom'

export default function ConfirmationPage() {
  const { trackingId } = useParams<{ trackingId: string }>()
  const isDevStage = window.location.hostname.toLowerCase().includes('dev.card')
  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>Confirmation</h2>
          </div>
        </div>
      </div>
      {`Your file has been succesfully uploaded and sent for processing. We will
      send you the update. Our tracking id is: ${trackingId}. You may track the
      processing at`}
      <Link
        to={`/?query=${encodeURIComponent(
          trackingId
        )}&stage=${encodeURIComponent(isDevStage ? 'gamma' : 'prod')}`}
      >
        {'here'}
      </Link>
    </div>
  )
}