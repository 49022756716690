import React, { useState } from 'react'
import {
  Form,
  FormField,
  ColumnLayout,
  Button,
  Select,
  Popover,
  DatePicker,
  Autosuggest
} from '@amzn/awsui-components-react/polaris'
import './Settings.scss'
import { usePersistedState } from '../../utils'


const migrationStatusOptions = [
  { id: 'SENT_TO_BHUB', label: 'In Progress' },
  { id: 'NEW', label: 'Pending' },
  { id: 'FAILED', label: 'Failed' },
  { id: 'PROCESSED_BY_CIMARRON', label: 'Success: Processed by Cimarron'},
  { id: '', label: 'All' }
]
const entityTypeOptions = [
  { id: 'CUSTOMER', label: 'Customer' },
  { id: 'INV', label: 'Invoice' },
  { id: 'RCPT', label: 'Receipts' },
  { id: 'ADJ', label: 'Adjustments' },
  { id: '', label: 'All' }
]

const EntitySearchParams = ({
  initialParams,
  onSearch }:{
  initialParams: any
  onSearch: (params: any) => void
  }
) => {
  const [ofaEntityId, setOfaEntityId] = useState(initialParams.ofaEntityId)
  const [entityType, setEntityType] = useState(initialParams.entityType)
  const [migrationStatus, setMigrationStatus] = useState(initialParams.migrationStatus)
  const [before, setBefore] = useState(initialParams.before)
  const [after, setAfter] = useState(initialParams.after)
  const [errorMessage, setErrorMessage] = useState('');
  const [recentQueries, setRecentQueries] = usePersistedState([], 'recent-queries')

  const onReset = () => {
    setAfter('');
    setBefore('');
    setOfaEntityId('');
    setMigrationStatus('');
    setEntityType('');
  }
  const onSubmit = (e: any) => {
    setRecentQueries(
      [ofaEntityId, ...recentQueries].filter((q, i, queries) => queries.indexOf(q) === i).slice(0, 100)
    )
    e.preventDefault()
    setErrorMessage('')
    onSearch({ofaEntityId, before, after, migrationStatus, entityType}) 
    return false
  }

  return (
    <form onSubmit={onSubmit} className="search-settings">
        <Form errorText={errorMessage}>
        <ColumnLayout reset-filter-link columns={3}>
        <div data-awsui-column-layout-root="true">
          <FormField stretch={true} label="OFA Entity ID">
              <div className="stack-checkboxes">
                <Autosuggest
                  id="quicksearch"
                  placeholder="Enter exact OFA entity ID."
                  autofocus={true}
                  ariaRequired={true}
                  disableBrowserAutocorrect={true}
                  value={ofaEntityId}
                  onChange={(e) => setOfaEntityId(e.detail.value)}
                  options={recentQueries.map((q: string) => ({ value: q })).slice(0, 20)}
                  empty="No matching recent queries"
                  onKeydown={(e) => {
                    if (e.detail.keyCode === 13) onSubmit(e)
                  }}
                />
              </div>
            </FormField>
            <FormField label="Migration Status">
              <div className="stack-checkboxes">
                <Select
                  selectedOption={migrationStatusOptions.find((type) => type.id === migrationStatus)}
                  options={migrationStatusOptions}
                  onChange={(e) => setMigrationStatus(e.detail.selectedId)}
                />
              </div>
            </FormField>
            <FormField label="Entity Type">
              <div className="stack-checkboxes">
                <Select
                  selectedOption={entityTypeOptions.find((type) => type.id === entityType)}
                  options={entityTypeOptions}
                  onChange={(e) => setEntityType(e.detail.selectedId)}
                />
              </div>
            </FormField>
            <FormField
              label={
                <Popover content="Filters by creation date.">
                  Between Dates
                </Popover>
              }
            >
              <div className="date-range-group">
                <DatePicker
                  placeholder="YYYY/MM/DD"
                  todayLabel="Today"
                  nextMonthLabel="Next month"
                  previousMonthLabel="Previous month"
                  onChange={(e) => {
                    setAfter(e.detail.value)
                    if (before && e.detail.value > before) setBefore(e.detail.value)
                  }}
                  value={after}
                />
                <div className="date-range-group-spacer" />
                <DatePicker
                  placeholder="YYYY/MM/DD"
                  todayLabel="Today"
                  nextMonthLabel="Next month"
                  previousMonthLabel="Previous month"
                  onChange={(e) => {
                    setBefore(e.detail.value)
                    if (after && e.detail.value < after) setAfter(e.detail.value)
                  }}
                  value={before}
                />
              </div>
            </FormField>
            <div></div>
            <div className="search-button-container">
              <Button onClick={onReset}>
                Reset
              </Button>
              <Button variant="primary">
                Search
              </Button>
            </div>
            </div>
        </ColumnLayout>
      </Form>
    </form>
  )
}

export default EntitySearchParams
