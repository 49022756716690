// Add Wiki link on each render
import React from 'react'

import './Logo.scss'

export default () => {
  return (
    <div className="logo-wikilinks">
      <a
        href="https://w.amazon.com/bin/view/FinTech/AR/PreBilling-Platform/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Wiki
      </a>
    </div>
  )
}
