import React, { useEffect, useState } from 'react'
import {
  Form,
  FormField,
  ColumnLayout,
  Input,
  Button,
  Select,
} from '@amzn/awsui-components-react/polaris'
import { useHistory } from 'react-router-dom'
import { createCustomer, getAllPreDefinedValues } from '../../api'
import { Address } from '../../types'
import { AobTransformation } from '../../utils'
import { ADDRESS_STATUS } from '../../constants'

export interface CustomerAndAddressRequest {
  customerName: string
  ticketNumber?: string
  customerId?: string
  aob: any
  primaryEmail: string
  customerType: string
  maxOpenToBuyValue: number
  maxOpenToBuyCurrency: string
  termsetId: string
  address: Address
  cimarronAccountId?: string
  customerChangeMap?: Map<string, string>
}

export interface CustomerAndAddressResponse {
  trackingId: string
  errors: Error[]
  message?: string
}

export interface PreDefinedValues {
  predefinedValuesMap: Map<string, string[]>
  aobToIssuerAccountIdMap: Map<string, string[]>
  aobToTermsetIdMap: Map<string, string[]>
  countryToStateMap: Map<string, string[]>
  aobToAobIdMap: Map<string, string>
}

const usageOptions = [{ id: 'BILL_TO', label: 'Bill To' }]

const CreateCustomer = () => {
  const [customerName, setCustomerName] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')
  const [termsetID, setTermsetID] = useState('')
  const [AOB, setAOB] = useState('')
  const [customerType, setCustomerType] = useState('')
  const [maxOpen, setMaxOpen] = useState('')
  const [maxOpenCurrency, setMaxOpenCurrency] = useState('')
  const [issuerID, setIssuerID] = useState('')
  //eslint-disable-next-line
  const [taxNumber, setTaxNumber] = useState('')
  const [marketplaceID, setMarketplaceID] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [secondaryEmails, setSecondaryEmails] = useState('')
  const [primaryPhone, setPrimaryPhone] = useState('')
  const [secondaryPhone, setSecondaryPhone] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [addressLine3, setAddressLine3] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [county, setCounty] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [usage, setUsage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [aobToIssuerIdMap, setAobToIssuerIdMap] = useState<any>([])
  const [aobToTermsetIdMap, setAobToTermsetIdMap] = useState<any>([])
  const [stateToCountryMap, setStateToCountryMap] = useState<any>([])
  const [aobToAobIdMap, setAobToAobIdMap] = useState<any>([])
  const [customerTypeOptions, setCustomerTypeOptions] = useState<any>([])
  const [countryOptions, setCountryOptions] = useState<any>([])
  const [stateOptions, setStateOptions] = useState<any>([])
  const [issuerIDOptions, setIssuerIDOptions] = useState<any>([])
  const [aobOptions, setAOBOptions] = useState<any>([])
  const [marketplaceIDOptions, setMarketplaceIDOptions] = useState<any>([])
  const [termsetIDOptions, setTermsetIDOptions] = useState<any>([])
  const [currencyOptions, setCurrencyOptions] = useState<any>([])
  const [buttonLoading, setButtonLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    async function getFormOptions() {
      try {
        const { data } = await getAllPreDefinedValues()
        if (!data) {
          throw new Error('Failed to fetch values')
        }

        //TODO: Change AOB to Channel in all terminologies, both frontend and backend
        const valuesMap = new Map(Object.entries(data!.predefinedValuesMap))
        const aobIssuerMap = new Map(
          Object.entries(data!.aobToIssuerAccountIdMap)
        )
        const aobTermsetMap = new Map(Object.entries(data!.aobToTermsetIdMap))
        const stateCountryMap = new Map(Object.entries(data!.countryToStateMap))
        const aobAobIdMap = new Map(Object.entries(data!.aobToAobIdMap))
        setAobToIssuerIdMap(aobIssuerMap)
        setAobToTermsetIdMap(aobTermsetMap)
        setStateToCountryMap(stateCountryMap)
        setAobToAobIdMap(aobAobIdMap)
        const customerTypeList = valuesMap.get('CustomerTypeList')
        const marketplaceIDList = valuesMap.get('DisplayableMarketplaceIdList')
        const currencyList = valuesMap.get('Currency')

        let customerTypeOptionsList: { id: string; label: string }[] = []
        let countryOptionsList: { id: string; label: string }[] = []
        let aobOptionsList: { id: string; label: string }[] = []
        let marketplaceIDOptionsList: { id: string; label: string }[] = []
        let currencyOptionsList: { id: string; label: string }[] = []

        for (let customer of customerTypeList) {
          customerTypeOptionsList.push({ id: customer, label: customer })
        }
        setCustomerTypeOptions(customerTypeOptionsList)
        //default to ORGANIZATION type once customertypes have been fetched
        setCustomerType('ORGANIZATION')

        stateCountryMap.forEach(function (value, key) {
          countryOptionsList.push({ id: key, label: key })
        })
        setCountryOptions(countryOptionsList)

        aobAobIdMap.forEach(function (value, key) {
          aobOptionsList.push({ id: value, label: key })
        })
        setAOBOptions(aobOptionsList)

        for (let marketplaceID of marketplaceIDList) {
          marketplaceIDOptionsList.push({
            id: marketplaceID,
            label: marketplaceID,
          })
        }
        setMarketplaceIDOptions(marketplaceIDOptionsList)

        for (let currency of currencyList) {
          currencyOptionsList.push({ id: currency, label: currency })
        }
        setCurrencyOptions(currencyOptionsList)
      } catch (e) {
        setErrorMessage('There was an error fetching form values')
        // console.error(e)
      }
    }
    getFormOptions()
  }, [])

  useEffect(() => {
    function updateIssuerIdOptions(aobToIssuerIdMap: Map<string, string[]>) {
      if (aobToIssuerIdMap && AOB !== '') {
        let issuers = aobToIssuerIdMap.get(AOB)!
        let issuerIdOptions: { id: string; label: string }[] = []
        issuers.forEach((issuer: string) =>
          issuerIdOptions.push({ id: issuer, label: issuer })
        )
        setIssuerIDOptions(issuerIdOptions)
      }
      return
    }
    updateIssuerIdOptions(aobToIssuerIdMap)
    // Adding linter disabling to make dependency array clearer, only a change to AOB triggers rerender
    // eslint-disable-next-line
  }, [AOB])

  useEffect(() => {
    function updateTermsetOptions(aobToTermsetIdMap: Map<string, string[]>) {
      if (aobToTermsetIdMap && AOB !== '') {
        let termsets = aobToTermsetIdMap.get(AOB)!
        let termsetIdOptions: { id: string; label: string }[] = []
        termsets.forEach((termset: string) =>
          termsetIdOptions.push({ id: termset, label: termset })
        )
        setTermsetIDOptions(termsetIdOptions)
      }
      return
    }
    updateTermsetOptions(aobToTermsetIdMap)
    // Adding linter disabling to make dependency array clearer, only a change to AOB triggers rerender
    // eslint-disable-next-line
  }, [AOB])

  useEffect(() => {
    function updateStateOptions(stateToCountryMap: Map<string, string[]>) {
      if (stateToCountryMap && country !== '') {
        let states = stateToCountryMap.get(country)!
        let stateOptions: { id: string; label: string }[] = []
        states.forEach((state: string) =>
          stateOptions.push({ id: state, label: state })
        )
        setStateOptions(stateOptions)
        setState('')
      }
      return
    }
    updateStateOptions(stateToCountryMap)
    // Adding linter disabling to make dependency array clearer, only a change to AOB triggers rerender
    // eslint-disable-next-line
  }, [country])

  const resetFields = () => {
    setIssuerID('')
    setTaxNumber('')
    setCustomerName('')
    setCustomerEmail('')
    setMarketplaceID('')
    setAOB('')
    setTermsetID('')
    setCustomerType('ORGANIZATION')
    setMaxOpen('')
    setMaxOpenCurrency('')
    setFirstName('')
    setLastName('')
    setContactEmail('')
    setSecondaryEmails('')
    setPrimaryPhone('')
    setSecondaryPhone('')
    setAddressLine1('')
    setAddressLine2('')
    setAddressLine3('')
    setCity('')
    setState('')
    setCounty('')
    setPostalCode('')
    setCountry('')
    setUsage('')
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()

    setButtonLoading(true)
    if (
      !customerName ||
      !customerEmail ||
      !termsetID ||
      !AOB ||
      !customerType ||
      !maxOpenCurrency ||
      !issuerID ||
      !marketplaceID ||
      !firstName ||
      !lastName ||
      !contactEmail ||
      !addressLine1 ||
      (stateOptions.length > 0 && !state) ||
      !country ||
      !city ||
      !postalCode ||
      !usage
    ) {
      setErrorMessage('Missing required fields')
      setButtonLoading(false)
      return
    }

    let reg = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}')

    if (!reg.test(customerEmail)) {
      setErrorMessage('Improper email format!')
      setButtonLoading(false)
      return
    }

    if (!reg.test(contactEmail)) {
      setErrorMessage('Improper email format!')
      setButtonLoading(false)
      return
    }

    var splitEmails = secondaryEmails.split(/,|;|\s/).filter(Boolean) //split on comma/semicolon/whitespace and remove empty entries
    console.log(splitEmails)
    for (const secondaryEmail of splitEmails) {
      //secondaryEmail is currently not required, will only validate if user enters value
      if (secondaryEmail) {
        if (!reg.test(secondaryEmail)) {
          setErrorMessage('Improper email format!')
          setButtonLoading(false)
          return
        }
      }
    }

    setErrorMessage('')

    const address: Address = {
      siteUseCode: usage,
      defaultCurrency: maxOpenCurrency,
      paymentTerms: termsetID,
      displayableMarketplaceId: marketplaceID,
      issuerAccountId: issuerID,
      firstName: firstName,
      lastName: lastName,
      primaryPhoneNumber: primaryPhone,
      secondaryPhoneNumber: secondaryPhone,
      contactEmail: contactEmail,
      secondaryEmails: splitEmails,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      city: city,
      county: county,
      state: state,
      country: country,
      postalCode: postalCode,
      status: ADDRESS_STATUS.ACTIVE,
    }

    const customerRequest: CustomerAndAddressRequest = {
      customerName: customerName,
      aob: AobTransformation(AOB, aobToAobIdMap),
      primaryEmail: customerEmail,
      customerType: customerType,
      maxOpenToBuyValue: maxOpen ? Number(maxOpen) : 9999999999999,
      maxOpenToBuyCurrency: maxOpenCurrency,
      termsetId: termsetID,
      address: address,
    }
    var trackingId = ''

    const { data, error } = await createCustomer(customerRequest)
    if (error || !data) {
      console.log('error')
      setErrorMessage('Bad request')
      setButtonLoading(false)
      return
    } else {
      trackingId = data?.trackingId
    }
    history.push('/createcustomerpending/' + encodeURIComponent(trackingId))
  }
  return (
    <div className="awsui-util-container">
      <form onSubmit={onSubmit} className="customer-creation">
        <Form errorText={errorMessage}>
          <div className="awsui-util-container awsui-util-t-c">
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>Customer Header Information</h2>
              </div>
            </div>
            <ColumnLayout columns={2} variant="text-grid">
              <div data-awsui-column-layout-root="true">
                <div>
                  <div className="awsui-util-spacing-v-s">
                    <FormField label="Customer Name" description="(required)">
                      <div className="stack-checkboxes">
                        <Input
                          id="customerName"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={customerName}
                          onChange={(e) => setCustomerName(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField
                      label="Customer Email Address"
                      description="(required)"
                    >
                      <div className="stack-checkboxes">
                        <Input
                          id="customerEmail"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={customerEmail}
                          onChange={(e) => setCustomerEmail(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Channel" description="(required)">
                      <div className="stack-checkboxes">
                        <Select
                          ariaRequired={true}
                          selectedOption={
                            aobOptions.find(
                              (s: { id: string }) => s.id === AOB
                            ) || null
                          }
                          options={aobOptions}
                          onChange={(e) => setAOB(e.detail.selectedId)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Payment Terms" description="(required)">
                      <div className="stack-checkboxes">
                        <Select
                          ariaRequired={true}
                          selectedOption={
                            termsetIDOptions.find(
                              (s: { id: string }) => s.id === termsetID
                            ) || null
                          }
                          placeholder="Please select Channel to populate Payment Term"
                          options={termsetIDOptions}
                          onChange={(e) => setTermsetID(e.detail.selectedId)}
                        />
                      </div>
                    </FormField>
                  </div>
                </div>
                <div>
                  <div className="awsui-util-spacing-v-s">
                    <FormField label="Customer Type" description="(required)">
                      <div className="stack-checkboxes">
                        <Select
                          ariaRequired={true}
                          selectedOption={
                            customerTypeOptions.find(
                              (s: { id: string }) => s.id === customerType
                            ) || null
                          }
                          options={customerTypeOptions}
                          onChange={(e) => setCustomerType(e.detail.selectedId)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Max Open To Buy">
                      <div className="stack-checkboxes">
                        <Input
                          id="maxOpen"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={maxOpen}
                          onChange={(e) => setMaxOpen(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Currency" description="(required)">
                      <div className="stack-checkboxes">
                        <Select
                          ariaRequired={true}
                          selectedOption={
                            currencyOptions.find(
                              (s: { id: string }) => s.id === maxOpenCurrency
                            ) || null
                          }
                          options={currencyOptions}
                          onChange={(e) =>
                            setMaxOpenCurrency(e.detail.selectedId)
                          }
                        />
                      </div>
                    </FormField>
                  </div>
                </div>
              </div>
            </ColumnLayout>
          </div>
          <div className="awsui-util-container awsui-util-t-c">
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>Customer Address Information</h2>
              </div>
            </div>
            <ColumnLayout columns={2} variant="text-grid">
              <div data-awsui-column-layout-root="true">
                <div>
                  <div className="awsui-util-spacing-v-s">
                    <FormField
                      label="Issuer Account ID"
                      description="(required)"
                    >
                      <div className="stack-checkboxes">
                        <Select
                          ariaRequired={true}
                          selectedOption={
                            issuerIDOptions.find(
                              (s: { id: string }) => s.id === issuerID
                            ) || null
                          }
                          placeholder="Please select Channel to populate Issuer Account ID"
                          options={issuerIDOptions}
                          onChange={(e) => setIssuerID(e.detail.selectedId)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Tax Registration Number">
                      <div className="stack-checkboxes">
                        <Input
                          id="taxNumber"
                          autocomplete={false}
                          disableBrowserAutocorrect={true}
                          value={taxNumber}
                          onChange={(e) => setTaxNumber(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Marketplace" description="(required)">
                      <div className="stack-checkboxes">
                        <Select
                          id="country"
                          ariaRequired={true}
                          selectedOption={
                            marketplaceIDOptions.find(
                              (s: { id: string }) => s.id === marketplaceID
                            ) || null
                          }
                          options={marketplaceIDOptions}
                          onChange={(e) =>
                            setMarketplaceID(e.detail.selectedId)
                          }
                        />
                      </div>
                    </FormField>
                    <h3>Contact Information</h3>
                    <FormField label="First Name" description="(required)">
                      <div className="stack-checkboxes">
                        <Input
                          id="firstName"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={firstName}
                          onChange={(e) => setFirstName(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Last Name" description="(required)">
                      <div className="stack-checkboxes">
                        <Input
                          id="lastName"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={lastName}
                          onChange={(e) => setLastName(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Primary Email" description="(required)">
                      <div className="stack-checkboxes">
                        <Input
                          id="primaryEmail"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Secondary Email">
                      <div className="stack-checkboxes">
                        <Input
                          id="secondaryEmail"
                          autocomplete={false}
                          ariaRequired={false}
                          placeholder={
                            'Please separate emails with commas or semicolons.'
                          }
                          disableBrowserAutocorrect={true}
                          value={secondaryEmails}
                          onChange={(e) => setSecondaryEmails(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Primary Phone Number">
                      <div className="stack-checkboxes">
                        <Input
                          id="primaryPhone"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={primaryPhone}
                          onChange={(e) => setPrimaryPhone(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Secondary Phone Number">
                      <div className="stack-checkboxes">
                        <Input
                          id="secondaryPhone"
                          autocomplete={false}
                          ariaRequired={false}
                          disableBrowserAutocorrect={true}
                          value={secondaryPhone}
                          onChange={(e) => setSecondaryPhone(e.detail.value)}
                        />
                      </div>
                    </FormField>
                  </div>
                </div>
                <div>
                  <div className="awsui-util-spacing-v-s">
                    <h3>Address Information</h3>
                    <FormField label="Address Line 1" description="(required)">
                      <div className="stack-checkboxes">
                        <Input
                          id="line1"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={addressLine1}
                          onChange={(e) => setAddressLine1(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Address Line 2">
                      <div className="stack-checkboxes">
                        <Input
                          id="line2"
                          autocomplete={false}
                          ariaRequired={false}
                          disableBrowserAutocorrect={true}
                          value={addressLine2}
                          onChange={(e) => setAddressLine2(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Address Line 3">
                      <div className="stack-checkboxes">
                        <Input
                          id="line3"
                          autocomplete={false}
                          ariaRequired={false}
                          disableBrowserAutocorrect={true}
                          value={addressLine3}
                          onChange={(e) => setAddressLine3(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="City" description="(required)">
                      <div className="stack-checkboxes">
                        <Input
                          id="city"
                          autocomplete={false}
                          ariaRequired={false}
                          disableBrowserAutocorrect={true}
                          value={city}
                          onChange={(e) => setCity(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Country" description="(required)">
                      <div className="stack-checkboxes">
                        <Select
                          id="country"
                          ariaRequired={true}
                          selectedOption={
                            countryOptions.find(
                              (s: { id: string }) => s.id === country
                            ) || null
                          }
                          options={countryOptions}
                          onChange={(e) => setCountry(e.detail.selectedId)}
                        />
                      </div>
                    </FormField>
                    {stateOptions.length === 0 ? (
                      <FormField label="State/Province">
                        <div className="stack-checkboxes">
                          <Input
                            id="state"
                            placeholder={
                              country === ''
                                ? 'Please select Country to populate State/Province'
                                : 'Enter State/Province manually'
                            }
                            ariaRequired={true}
                            value={state}
                            onChange={(e) => setState(e.detail.value)}
                          />
                        </div>
                      </FormField>
                    ) : (
                      <FormField label="State/Province">
                        <div className="stack-checkboxes">
                          <Select
                            id="state"
                            placeholder={
                              country === ''
                                ? 'Please select Country to populate State/Province'
                                : ''
                            }
                            ariaRequired={true}
                            selectedOption={
                              stateOptions.find(
                                (s: { id: string }) => s.id === state
                              ) || null
                            }
                            options={stateOptions}
                            onChange={(e) => setState(e.detail.selectedId)}
                          />
                        </div>
                      </FormField>
                    )}
                    <FormField label="County">
                      <div className="stack-checkboxes">
                        <Input
                          id="county"
                          autocomplete={false}
                          ariaRequired={false}
                          disableBrowserAutocorrect={true}
                          value={county}
                          onChange={(e) => setCounty(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Postal Code" description="(required)">
                      <div className="stack-checkboxes">
                        <Input
                          id="postalCode"
                          autocomplete={false}
                          ariaRequired={true}
                          disableBrowserAutocorrect={true}
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.detail.value)}
                        />
                      </div>
                    </FormField>
                    <FormField label="Address Type" description="(required)">
                      <div className="stack-checkboxes">
                        <Select
                          ariaRequired={true}
                          selectedOption={
                            usageOptions.find((s) => s.id === usage) || null
                          }
                          options={usageOptions}
                          onChange={(e) => setUsage(e.detail.selectedId)}
                        />
                      </div>
                    </FormField>
                  </div>
                </div>
              </div>
            </ColumnLayout>
          </div>
        </Form>
      </form>
      <Button variant="primary" onClick={onSubmit} loading={buttonLoading}>
        Create
      </Button>
      <Button formAction="none" variant="normal" onClick={resetFields}>
        Reset
      </Button>
    </div>
  )
}

export default CreateCustomer
