import { CSVRow } from './index'

// makes an array of increasing numbers (e.g. [3,4,5,6])
const range = (startInclusive: number, endInclusive: number) => {
  const arr:any = []
  for (let i = startInclusive; i <= endInclusive; i++) arr.push(i)
  return arr
}

export const columns: { id: string; required: boolean }[] = [
  { id: 'Client_ID', required: true },
  { id: 'Invoice_Number', required: true },
  { id: 'Invoice_Business_Customer_Reference', required: false },
  { id: 'Invoice_Business_Customer_Address_Reference', required: false },
  { id: 'Invoice_Comments', required: true },
  { id: 'Invoice_Marketplace', required: false },
  { id: 'Invoice_Business_Unit_Seller_Of_Record', required: false },
  { id: 'invoice_Business_Unit_Branch', required: false },
  { id: 'Invoice_Currency_Code', required: true },
  { id: 'Invoice_Transaction_Type', required: true },
  { id: 'Invoice_Date', required: true },
  { id: 'Invoice_Ship-To_Customer_Reference', required: false },
  { id: 'Invoice_Ship-To_Customer_Address_Reference', required: false },
  { id: 'Invoice_Ship-To_Customer_Contact_Reference', required: false },
  { id: 'Invoice_Ship-To_Customer_Number', required: false },
  { id: 'Invoice_Ship-To_Customer_Site_Number', required: false },
  { id: 'Invoice_Bill-To_Customer_Reference', required: false },
  { id: 'Invoice_Bill-To_Customer_Address_Reference', required: false },
  { id: 'Invoice_Bill-To_Customer_Contact_Reference', required: false },
  { id: 'Invoice_Bill-To_Customer_Number', required: true },
  { id: 'Invoice_Bill-To_Customer_Site_Number', required: true },
  { id: 'Invoice_GL_Date', required: false },
  { id: 'Invoice_Batch_Source', required: true },
  { id: 'Invoice_Purchase_Order', required: false },
  { id: 'Invoice_Purchase_Order_Date', required: false },
  { id: 'Invoice_Payment_Term', required: false },
  { id: 'Invoice_Rule_Name', required: false },
  { id: 'Invoice_Description', required: false },
  { id: 'Invoice_Attribute_Category', required: true },
  // attribute15 will be used by webbiling to store the fileId so only allow users to assign the first 14
  ...range(1, 14).map((v) => ({
    id: `Invoice_Attribute_${v}`,
    required: false,
  })),
  { id: 'Line_Type', required: true },
  { id: 'Line_Amount', required: true },
  { id: 'Line_Description', required: true },
  { id: 'Line_Number', required: true },
  { id: 'Line_Quantity', required: true },
  { id: 'Line_Unit_Price', required: true },
  { id: 'Line_Unit_Of_Measure_Code', required: true },
  { id: 'Line_Unit_Of_Measure_Name', required: false },
  { id: 'Line_Warehouse_FC_Name', required: false },
  { id: 'Line_Warehouse_FC_Code', required: false },
  { id: 'Line_Warehouse_FC_Location', required: false },
  { id: 'Line_Accounting_Rule_Name', required: false },
  { id: 'Line_Accounting_Rule_Duration', required: false },
  { id: 'Line_Accounting_Rule_Start_Date', required: false },
  { id: 'Line_Accouqnting_Rule_End_Date', required: false },
  { id: 'Line_Sales_Order', required: false },
  { id: 'Line_Sales_Order_Date', required: false },
  { id: 'Line_Sales_Order_Source', required: false },
  ...range(1, 15).map((v) => ({
    id: `Line_Attribute_${v}`,
    required: false,
  })),
]

export const csv = columns
  .map((column) => (column.required ? `"${column.id}*"` : `"${column.id}"`))
  .join(',')

// produces a object that looks like {Client_ID: true, Invoice_Marketplace: false, ...}
// for quicker lookups
const isColumnRequiredMap: { [columnName: string]: boolean } = {}
columns.forEach((column) => (isColumnRequiredMap[column.id] = column.required))
export const isColumnRequired = (columnName: string): boolean =>
  isColumnRequiredMap[columnName]

export const validateRow = (row: CSVRow): boolean =>
  columns.every((column) => !column.required || row[column.id])
