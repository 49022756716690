import React from 'react'
import { MigrationEntityListRow, SharedStates } from './RequestResponseConstants'
import Table from '@amzn/awsui-components-react/polaris/table'
import { TableSorting } from '@amzn/awsui-components-react'
import MigrationStatus from './MigrationStatus'

const columnDefinitionsMigration = (shareStates: () => SharedStates): Table.ColumnDefinition[] => {
let columns = [
    {
        id: 'OfaEntityId',
        header: 'OFA Entity ID',
        cell: (item: MigrationEntityListRow) => item.OfaEntityId,
    },
    {
        id: 'entityType',
        header: 'Entity Type',
        cell: (item: MigrationEntityListRow) => item.entityType,
    },
    {
        id: 'marketplace',
        header: 'Marketplace',
        cell: (item: MigrationEntityListRow) => item.marketplace,
    },
    {
        id: 'channel',
        header: 'Channel',
        cell: (item: MigrationEntityListRow) => item.billingChannel,
    },
    {
        id: 'cimarronAccountId',
        header: 'Cimarron Account ID',
        cell: (item: MigrationEntityListRow) => item.cimarronAccountId,
    },
    {
        id: 'migrationStatus',
        header: 'Migration Status',
        cell: (item: MigrationEntityListRow) => <MigrationStatus status={item.migrationStatus}/>,
    },
    {
        id: 'ingestionDate',
        header: 'Ingestion Timestamp',
        cell: (item: MigrationEntityListRow) => item.ingestionTimeStamp!==0 ? new Date(item.ingestionTimeStamp*1000000).toLocaleDateString(): '',
    },
    {
        id: 'currency',
        header: 'Currency',
        cell: '',
    },
    {
        id: 'invoiceDate',
        header: 'Invoice Date',
        cell: '',
    }
]
return columns.map((c) => ({
    ...c,
    // generate label functions based on column attributes
    label: (sortState: TableSorting.SortingState) => {
      const columnIsSorted = sortState.sortingColumn === c.id
      const ascending = !sortState.sortingDescending
      return `${c.header}, ${
        columnIsSorted ? `sorted ${ascending ? 'ascending' : 'descending'}` : 'not sorted'
      }.`
    },
  }))
}

export default columnDefinitionsMigration
