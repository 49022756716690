import React, { useState } from 'react'
import { Badge, Modal, Popover } from '@amzn/awsui-components-react/polaris'

import './AuditStatus.scss'
import { Link } from 'react-router-dom'
import { stage} from "../../utils";

const AuditStatus = ({
  status,
  trackingId,
  messageType,
  eventMetadata,
  aggregationFailedDisplayFlag
}: {
  status?: string
  trackingId: string
  messageType: string
  eventMetadata?: string
  aggregationFailedDisplayFlag?: boolean
}) => {
  const statuses = {
    pending: 'PENDING',
    succeeded: 'SUCCEEDED',
    failed: 'FAILED',
    partially_succeeded: 'PARTIALLYSUCCEEDED'

  }
  const messageTypes = {
    customer: 'CUSTOMER',
    invoice: 'INVOICE',
  }

  const [visible, setVisible] = useState(false)

  if (!status)
    return (
      <Badge color="grey" className="fixed-badge">
        Missing
      </Badge>
    )
  else if (status === statuses.pending)
    return (
      <Badge color="blue" className="fixed-badge">
        Pending
      </Badge>
    )
  else if (
    status === statuses.succeeded &&
    messageType === messageTypes.customer
  )
    return (
      <Badge color="green" className="fixed-badge">
        <Link
          to={`/customer/${encodeURIComponent(trackingId)}`}
          target={ '_blank'}
          style={{ color: '#FFF' }}
        >
          {'Succeeded'}
        </Link>
      </Badge>
    )
  else if (
      status === statuses.partially_succeeded &&
      messageType === messageTypes.customer
  )
      return (
          <Badge color="grey" className="fixed-badge">
              <Link
                  to={`/customer/${encodeURIComponent(trackingId)}`}
                  target={ '_blank'}
                  style={{ color: '#FFF' }}
              >
                  {'Partially Succeeded'}
              </Link>
          </Badge>
      )
  else if (status === statuses.failed && messageType === messageTypes.customer)
    return (
      <div>
        <Badge color="red" className="fixed-badge">
          <button
            className="audit-badge"
            onClick={() => setVisible(true)}
          >
            Failed
          </button>
        </Badge>
        <Modal
          header="Event Metadata"
          visible={visible}
          onDismiss={() => setVisible(false)}
        >
            {eventMetadata || "Error Fetching Metadata" }
        </Modal>
      </div>
    )
  else if (
    status === statuses.succeeded &&
    messageType === messageTypes.invoice
  )
    return (
      <Badge color="green" className="fixed-badge">
        <Link
          to={`/?query=${encodeURIComponent(
            trackingId
          )}&stage=${encodeURIComponent(stage)}`}
          target={ '_blank'}
          style={{ color: '#FFF' }}
        >
          {'Succeeded'}
        </Link>
      </Badge>
    )
  else if (
      status === statuses.partially_succeeded &&
      messageType === messageTypes.invoice
  )
      return (
          <Badge color="grey" className="fixed-badge">
              <Link
                  to={`/?query=${encodeURIComponent(
                      trackingId
                  )}&stage=${encodeURIComponent(stage)}`}
                  target={ '_blank'}
                  style={{ color: '#FFF' }}
              >
                  {'Partially Succeeded'}
              </Link>
          </Badge>
      )
    else if (status === statuses.failed && messageType === messageTypes.invoice && aggregationFailedDisplayFlag === true)
    return(
      <div>
        <Badge color="red" className="fixed-badge">
          <button
            className="audit-badge"
            onClick={() => setVisible(true)}
          >
            Failed
          </button>
        </Badge>
        <Modal
          header="Event Metadata"
          visible={visible}
          onDismiss={() => setVisible(false)}
        >
            {eventMetadata || "Error Fetching Metadata" }
        </Modal>
      </div>
    )
  else if (status === statuses.failed && messageType === messageTypes.invoice)
    return (
      <Badge color="red" className="fixed-badge">
        <Link
          to={`/?query=${encodeURIComponent(
            trackingId
          )}&stage=${encodeURIComponent(stage)}`}
          target={ '_blank'}
          style={{ color: '#FFF' }}
        >
          {'Failed'}
        </Link>
      </Badge>
    )
  
  else if (status === statuses.succeeded)
    return (
      <Badge color="green" className="fixed-badge">
        Succeeded
      </Badge>
    )
  else if (status === statuses.failed)
    return (
      <Badge color="red" className="fixed-badge">
        Failed
      </Badge>
    )
  else if (status === statuses.partially_succeeded)
      return (
          <Badge color="grey" className="fixed-badge" >
              Partially Succeeded
          </Badge>
      )
  else
    return (
      <Popover
        size="small"
        content={
          <>
            The message's status is not recognized. If this happens on
            production data, please{' '}
            <a
              href="https://sim.amazon.com/issues/create?template=e8425949-0eab-4762-a81f-ab2b82c70647"
              target="_blank"
              rel="noopener noreferrer"
            >
              submit a ticket
            </a>
            .
          </>
        }
        className="popover-invalid-message"
        position="left"
      >
        <Badge color="grey" className="fixed-badge">
          Invalid
        </Badge>
      </Popover>
    )
}

export default AuditStatus
