import React, { useState } from 'react'
import {
  Form,
  FormField,
  ColumnLayout,
  Input,
  Button,
  Select,
  Modal,
  Flashbar,
  Tooltip,
} from '@amzn/awsui-components-react/polaris'
import { addAddressToCustomer } from '../../../api'
import { CustomerAndAddressRequest } from '../../CreateCustomer/CreateCustomer'
import { Address, SharedStates } from '../../../types'
import { aobIdToBusinessChannel } from '../../../Common/PredefinedValues'
import { ADDRESS_STATUS, ADDRESS_TYPES, PRIMARY_SITE } from '../../../constants'
import PendingModal from '../Modals/PendingModal'
import FailureModal from '../Modals/FailureModal'
import {
  FlashbarProps,
  addressTypes,
  getField,
  isEmpty,
  primarySiteOptions,
} from '../../../utils'
import DataField from '../../../Common/DataField'

const CreateAddress = ({
  shareStates,
  setShowCreateAddressPopup,
  showCreateAddressPopup,
}: {
  shareStates: () => SharedStates
  setShowCreateAddressPopup: any
  showCreateAddressPopup: boolean
}) => {
  const { customerData, stateToCountryMap, countryOptions, cimarronData } =
    shareStates()
  const [errorMessage, setErrorMessage] = useState('')
  const [trackingId, setTrackingId] = useState('')
  const createAddressErrorText = `Customer address creation failed.`
  const [
    showCreateAddressConfirmationPopup,
    setShowCreateAddressConfirmationPopup,
  ] = useState(false)
  const [bannerItems, setBannerItems] = useState<FlashbarProps[]>([])
  const [missingRequiredFields, setMissingRequiredFields] = useState(false)

  const [validateTicketNumber, setValidateTicketNumberMessage] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')
  const onDismiss = () => setMissingRequiredFields(false)
  const flashBarMessage = {
    header: '',
    type: '',
    content: '',
    dismissible: true,
    onDismiss: onDismiss,
  }
  const reg = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}')
  const validateTicketNumberText =
    'Enter Ticket Number, ticket Number is required to proceed.'
  const ADDRESS_TYPE_TOOLTIP_TEXT = 'Ship To address cannot be primary address'

  const initializeAddress: Address = {
    siteUseCode: '',
    defaultCurrency: customerData.maxOpenToBuyCurrency,
    paymentTerms: customerData.termsetId,
    displayableMarketplaceId: cimarronData[0].displayableAddressMarketplaceId,
    issuerAccountId: cimarronData[0].issuerAccountId,
    firstName: '',
    lastName: '',
    primaryPhoneNumber: '',
    secondaryPhoneNumber: '',
    contactEmail: '',
    secondaryEmails: [],
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    county: '',
    state: '',
    country: '',
    postalCode: '',
    primarySite: false,
    cimarronAccountId: cimarronData[0].cimarronAccountId,
    status: ADDRESS_STATUS.ACTIVE,
  }
  const [address, setAddress] = useState<Address>(initializeAddress)

  const resetFields = () => {
    setTicketNumber('')
    setValidateTicketNumberMessage('')
    setAddress(initializeAddress)
    setBannerItems([])
  }
  const validateEmptyFields = (address) => {
    if (
      isEmpty(address['firstName']) ||
      isEmpty(address['contactEmail']) ||
      isEmpty(address['addressLine1']) ||
      isEmpty(address['postalCode']) ||
      isEmpty(address['country']) ||
      isEmpty(address['city']) ||
      isEmpty(address['siteUseCode'])
    ) {
      flashBarMessage.header = 'Missing Required Fields!'
      flashBarMessage.content =
        'Please add data to fields where optional is not mentioned.'
      flashBarMessage.type = 'error'
      setBannerItems([flashBarMessage])
      return true
    }
    if (!reg.test(getField('contactEmail', address))) {
      flashBarMessage.header = 'Improper email format!'
      flashBarMessage.content = 'Primary email should end with @amazon.com'
      flashBarMessage.type = 'error'
      setBannerItems([flashBarMessage])
      return true
    }

    setBannerItems([])
    return false
  }

  const onSubmit = async () => {
    const isMissingFields = validateEmptyFields(address)
    setMissingRequiredFields(isMissingFields)
    if (isEmpty(ticketNumber)) {
      setValidateTicketNumberMessage(validateTicketNumberText)
      return
    }

    if (!isMissingFields) {
      const addressRequest: CustomerAndAddressRequest = {
        customerName: customerData.customerName,
        customerId: customerData.customerId,
        aob: aobIdToBusinessChannel.get(getField('aob', customerData)),
        primaryEmail: customerData.primaryEmail,
        customerType: customerData.customerType,
        maxOpenToBuyValue: Number(customerData.maxOpenToBuyValue),
        maxOpenToBuyCurrency: customerData.maxOpenToBuyCurrency,
        termsetId: customerData.termsetId,
        address: address,
        ticketNumber: ticketNumber,
      }

      const result = await addAddressToCustomer(addressRequest)

      const { data, error } = result
      if (error) {
        setErrorMessage(` Error: ${data?.message}`)
      } else {
        setTrackingId(data?.trackingId || '')
      }
      resetFields()
      setShowCreateAddressConfirmationPopup(true)
      return
    }
  }

  return (
    <>
      {!showCreateAddressConfirmationPopup && showCreateAddressPopup && (
        <Modal
          size="large"
          header={` Create Address`}
          visible={showCreateAddressPopup}
          onDismiss={() => {
            setShowCreateAddressPopup()
            resetFields()
          }}
        >
          <Form
            actions={
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    setShowCreateAddressPopup()
                    resetFields()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    onSubmit()
                  }}
                >
                  Create Address
                </Button>
              </div>
            }
          >
            {missingRequiredFields && <Flashbar items={bannerItems} />}
            <ColumnLayout columns={3} variant="text-grid">
              <div data-awsui-column-layout-root="true">
                <div className="awsui-util-spacing-v-s">
                  <DataField label="Customer name">
                    <div>{getField('customerName', customerData)}</div>
                  </DataField>
                </div>
                <div className="awsui-util-spacing-v-s">
                  <DataField label="Business channel">
                    <div>
                      {aobIdToBusinessChannel.get(
                        getField('aob', customerData)
                      )}
                    </div>
                  </DataField>
                </div>
                <div className="awsui-util-spacing-v-s">
                  <DataField label="Payment terms">
                    <div>{getField('termsetId', customerData)}</div>
                  </DataField>
                </div>
              </div>
            </ColumnLayout>
            <h4 className="awsui-util-mt-l awsui-util-mb-s">Contact info:</h4>
            <ColumnLayout columns={3} variant="text-grid">
              <div data-awsui-column-layout-root="true">
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <FormField label="First name">
                      <Input
                        placeholder={'First name'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            firstName: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      label={
                        <span>
                          Last name <i> - optional </i>
                        </span>
                      }
                    >
                      <Input
                        placeholder={'Last name'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            lastName: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                </div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <FormField label="Primary email">
                      <Input
                        type="email"
                        placeholder={'Primary email'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            contactEmail: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      label={
                        <span>
                          Secondary email <i> - optional </i>
                        </span>
                      }
                    >
                      <Input
                        type="email"
                        placeholder={'Secondary email'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            secondaryEmails: [e.detail.value],
                          })
                        }
                      />
                    </FormField>
                  </div>
                </div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <FormField
                      label={
                        <span>
                          Primary phone number <i> - optional </i>
                        </span>
                      }
                    >
                      <Input
                        placeholder={'Primary phone number'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            primaryPhoneNumber: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      label={
                        <span>
                          Secondary phone number <i> - optional </i>
                        </span>
                      }
                    >
                      <Input
                        placeholder={'Secondary phone number'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            secondaryPhoneNumber: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                </div>
              </div>
            </ColumnLayout>

            <h4 className="awsui-util-mt-l awsui-util-mb-s">Address info:</h4>
            <ColumnLayout columns={3} variant="text-grid">
              <div data-awsui-column-layout-root="true">
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <FormField label="Address line 1">
                      <Input
                        placeholder={'Address line 1'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            addressLine1: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      label={
                        <span>
                          Address line 2 <i> - optional </i>
                        </span>
                      }
                    >
                      <Input
                        placeholder={'Address line 2'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            addressLine2: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      label={
                        <span>
                          Address line 3 <i> - optional </i>
                        </span>
                      }
                    >
                      <Input
                        placeholder={'Address line 3'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            addressLine3: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField label="Postal code">
                      <Input
                        placeholder={'Postal code'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            postalCode: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                </div>
                <div className="awsui-util-spacing-v-s">
                  <div>
                    <FormField label="Country">
                      <Select
                        options={countryOptions}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            city: '',
                            state: '',
                            county: '',
                            country: e.detail.selectedId,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      label={
                        <span>
                          State <i> - optional </i>
                        </span>
                      }
                    >
                      {address &&
                      getField('country', address) &&
                      stateToCountryMap.get(getField('country', address))
                        .length > 0 ? (
                        <Select
                          options={
                            stateToCountryMap
                              .get(getField('country', address))
                              .map((state) => ({
                                id: state,
                                label: state,
                              })) || []
                          }
                          onChange={(e) => {
                            setAddress({
                              ...address,
                              state: e.detail.selectedOption.label,
                            })
                          }}
                        />
                      ) : (
                        <Input
                          placeholder={'State'}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              state: e.detail.value,
                            })
                          }
                        />
                      )}
                    </FormField>
                  </div>
                  <div>
                    <FormField label="City">
                      <Input
                        placeholder={'City'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            city: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      label={
                        <span>
                          County <i> - optional </i>
                        </span>
                      }
                    >
                      <Input
                        placeholder={'County'}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            county: e.detail.value,
                          })
                        }
                      />
                    </FormField>
                  </div>
                </div>

                <div className="awsui-util-spacing-v-s">
                  <div>
                    <FormField label="Address type">
                      <Select
                        options={addressTypes}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            siteUseCode: e.detail.selectedId,
                            primarySite: false,
                          })
                        }
                      />
                    </FormField>
                  </div>
                  <div>
                    <FormField label="Is primary address?">
                      {address.siteUseCode &&
                      address.siteUseCode === ADDRESS_TYPES.SHIP_TO ? (
                        <Tooltip
                          text={ADDRESS_TYPE_TOOLTIP_TEXT}
                          position="top"
                        >
                          No
                        </Tooltip>
                      ) : (
                        <Select
                          options={primarySiteOptions}
                          onChange={(e) =>
                            setAddress({
                              ...address,
                              primarySite:
                                e.detail.selectedOption.id === PRIMARY_SITE.YES,
                            })
                          }
                        />
                      )}
                    </FormField>
                  </div>
                </div>
              </div>
            </ColumnLayout>
            <br />
            <FormField label="Ticket number" errorText={validateTicketNumber}>
              <Input
                value={ticketNumber}
                placeholder={'Write your ticket number here'}
                onChange={(e) => setTicketNumber(e.detail.value)}
              />
            </FormField>
            <br />
          </Form>
        </Modal>
      )}
      {showCreateAddressConfirmationPopup && (
        <Modal
          header={`Customer address create`}
          visible={showCreateAddressConfirmationPopup}
          onDismiss={() => {
            setShowCreateAddressConfirmationPopup(false)
            setShowCreateAddressPopup()
          }}
        >
          {trackingId !== '' ? (
            <PendingModal
              content={`Customer address creation is pending...`}
              trackingId={trackingId}
            />
          ) : (
            <FailureModal
              errorText={`${createAddressErrorText}${errorMessage} `}
            />
          )}
        </Modal>
      )}
    </>
  )
}

export default CreateAddress
