import React, { useState } from 'react'
import {
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Tooltip,
} from '@amzn/awsui-components-react/polaris'

import { CustomerAndAddressRequest } from '../../CreateCustomer/CreateCustomer'
import { addressTypes, getField } from '../../../utils'
import { Address, SharedStates } from '../../../types'
import { updatePrimaryAddress } from '../../../api'
import {
  ADDRESS_TYPES,
  PRIMARY_ADDRESS_TEXT,
  SHIP_TO_ADDRESS_TEXT,
} from '../../../constants'
import DataField from '../../../Common/DataField'
import PendingModal from '../Modals/PendingModal'
import FailureModal from '../Modals/FailureModal'
import { aobIdToBusinessChannel } from '../../../Common/PredefinedValues'

export interface SetAsPrimaryAddressResponse {
  trackingId: string
  errors?: Error[]
  message?: string
}

export default function EditAddress({
  item,
  shareStates,
}: {
  item: Address
  shareStates: () => SharedStates
}) {
  const { customerData } = shareStates()

  const [trackingId, setTrackingId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')
  const [validateTicketNumber, setValidateTicketNumber] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showSetAsPrimaryModal, setShowSetAsPrimaryModal] = useState(false)
  const resetsetAsPrimaryAddressModal = () => {
    setTicketNumber('')
    setValidateTicketNumber('')
    setShowSetAsPrimaryModal(false)
  }
  const setAsPrimaryModalMessage =
    'Are you sure you want to update this address to primary address?'
  const setAsPrimaryAddressErrorText = 'Set as primary address failed.'
  const setAsPrimaryAddressPendingText = 'Set as primary address is pending...'
  const isEditDisabled =
    getField('primarySite', item) ||
    getField('siteUseCode', item) === ADDRESS_TYPES.SHIP_TO
  const editButtonTooltipText = getField('primarySite', item)
    ? PRIMARY_ADDRESS_TEXT
    : getField('siteUseCode', item) === ADDRESS_TYPES.SHIP_TO
    ? SHIP_TO_ADDRESS_TEXT
    : null

  const setAsPrimaryAddressRequest = async () => {
    if (ticketNumber === '') {
      setValidateTicketNumber(
        'Enter Ticket Number, ticket Number is required to proceed.'
      )
      return
    }
    const changeMap = new Map()
    changeMap.set('newPrimaryAddressId', item.addressId)

    const primaryAddressEdit: CustomerAndAddressRequest = {
      customerName: customerData.customerName,
      ticketNumber: ticketNumber,
      customerId: customerData.customerId,
      aob: aobIdToBusinessChannel.get(customerData.aob),
      termsetId: customerData.termsetId,
      primaryEmail: customerData.primaryEmail,
      customerType: customerData.customerType,
      maxOpenToBuyValue: Number(customerData.maxOpenToBuyValue),
      maxOpenToBuyCurrency: customerData.maxOpenToBuyCurrency,
      address: customerData.addresses[0],
      cimarronAccountId: customerData.cimarronAccounts[0].cimarronAccountId,
      customerChangeMap: Object.fromEntries(changeMap),
    }

    const result = await updatePrimaryAddress(primaryAddressEdit)
    const { data, error } = result
    if (error) {
      setErrorMessage(` Error: ${data?.message}`)
    } else {
      setTrackingId(data?.trackingId || 'Error updating set as primary address')
    }
    resetsetAsPrimaryAddressModal()
    setShowConfirmationModal(true)
    return
  }

  return (
    <div>
      <Tooltip text={editButtonTooltipText} position="top">
        <Button
          onClick={() => setShowSetAsPrimaryModal(true)}
          disabled={isEditDisabled}
        >
          Set as primary
        </Button>
      </Tooltip>
      {showSetAsPrimaryModal && (
        <Modal
          header={` Set address as primary`}
          visible={showSetAsPrimaryModal}
          onDismiss={() => {
            setShowSetAsPrimaryModal(false)
            resetsetAsPrimaryAddressModal()
          }}
          footer={
            <span className="awsui-util-f-r">
              <Button
                variant="link"
                onClick={() => {
                  setShowSetAsPrimaryModal(false)
                  resetsetAsPrimaryAddressModal()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setAsPrimaryAddressRequest()
                }}
              >
                Set address as primary
              </Button>
            </span>
          }
        >
          {
            <>
              <div>
                <h4> {setAsPrimaryModalMessage}</h4>
                <br />
              </div>
              <ColumnLayout columns={1} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Address Id">
                      <div>{getField('addressId', item)}</div>
                    </DataField>
                  </div>
                </div>
              </ColumnLayout>
              <h4 className="awsui-util-mt-l awsui-util-mb-s">Contact info:</h4>
              <ColumnLayout columns={3} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Name">
                      <div>
                        {getField('firstName', item)} &nbsp;
                        {getField('lastName', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Primary email">
                      <div>{getField('contactEmail', item)}</div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Primary phone number">
                      <div>{getField('primaryPhoneNumber', item)}</div>
                    </DataField>
                  </div>
                </div>
              </ColumnLayout>

              <h4 className="awsui-util-mt-l awsui-util-mb-s">Address info:</h4>
              <ColumnLayout columns={4} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Address">
                      <div>
                        {getField('addressLine1', item)} &nbsp;
                        {getField('addressLine2', item)} &nbsp;
                        {getField('addressLine3', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="City & State">
                      <div>
                        {getField('city', item)} &nbsp;
                        {getField('state', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Postal code & Country">
                      <div>
                        {getField('postalCode', item)} &nbsp;
                        {getField('country', item)}
                      </div>
                    </DataField>
                  </div>
                  <div className="awsui-util-spacing-v-s">
                    <DataField label="Address type">
                      <div>
                        {
                          addressTypes.find(
                            (site) => site.id === getField('siteUseCode', item)
                          )?.label
                        }
                      </div>
                    </DataField>
                  </div>
                </div>
              </ColumnLayout>
              <br />
              <FormField label="Ticket number" errorText={validateTicketNumber}>
                <Input
                  value={ticketNumber}
                  placeholder={'Write your ticket number here'}
                  onChange={(e) => setTicketNumber(e.detail.value)}
                />
              </FormField>
            </>
          }
        </Modal>
      )}
      <Modal
        header={`Set as primary address`}
        visible={showConfirmationModal}
        onDismiss={() => {
          setShowConfirmationModal(false)
          resetsetAsPrimaryAddressModal()
        }}
      >
        {trackingId !== '' ? (
          <PendingModal
            content={setAsPrimaryAddressPendingText}
            trackingId={trackingId}
          />
        ) : (
          <FailureModal
            errorText={`${setAsPrimaryAddressErrorText}${errorMessage} `}
          />
        )}
      </Modal>
    </div>
  )
}
