import React, { useState } from 'react'
import {
  Button,
  FormField,
  Input,
  Modal,
  Select,
  Flashbar,
} from '@amzn/awsui-components-react/polaris'

import PendingModal from '../Modals/PendingModal'
import { updateCustomer } from '../../../api'
import { CustomerAndAddressRequest } from '../../CreateCustomer/CreateCustomer'
import { aobIdToBusinessChannel } from '../../../Common/PredefinedValues'
import FailureModal from '../Modals/FailureModal'
import { CUSTOMER_STATUS } from '../../../constants'
import { FlashbarProps } from '../../../utils'
import { SharedStates } from '../../../types'

const updateCustomerErrorText = 'Update Customer failed.'

export default function EditCustomerHeaderInfo({
  shareStates,
}: {
  shareStates: () => SharedStates
}) {
  const { customerData, termsetIDOptions } = shareStates()

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
  const [trackingId, setTrackingId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [termSetId, setTermSetId] = useState(customerData.termsetId)
  const [customerName, setCustomerName] = useState(customerData.customerName)
  const [selectedOption, setSelectedOption] = useState({
    label: customerData.termsetId,
    id: customerData.termsetId,
  })
  const [ticketNumber, setTicketNumber] = useState('')
  const [validateTicketNumber, setValidateTicketNumber] = useState('')
  const [bannerItems, setBannerItems] = useState<FlashbarProps[]>([])
  const [showFlashBar, setShowFlashBar] = useState(false)
  const onDismiss = () => setShowFlashBar(false)
  // Intialize Flashbar message as empty
  const flashBarMessage = {
    header: '',
    type: '',
    content: '',
    dismissible: true,
    onDismiss: onDismiss,
  }

  // Reset the filed on dismiss and submit
  const resetFields = () => {
    setValidateTicketNumber('')
    setShowFlashBar(false)
    setBannerItems([])
    setCustomerName(customerData.customerName)
    setTermSetId(customerData.termsetId)
    setSelectedOption({
      label: customerData.termsetId,
      id: customerData.termsetId,
    })
    setTicketNumber('')
    setIsEditing(false)
  }

  // reset Popup flags on dismiss
  const resetOnDismiss = () => {
    setShowConfirmationPopup(false)
    setTrackingId('')
    resetFields()
  }

  const onSubmit = async () => {
    const changeMap = new Map()
    if (customerName !== customerData.customerName) {
      changeMap.set('customerName', customerName)
    }

    if (termSetId !== customerData.termsetId) {
      changeMap.set('termsetId', termSetId)
    }

    if (ticketNumber === '') {
      setValidateTicketNumber(
        'Enter Ticket Number, ticket Number is required to proceed.'
      )
      return
    }

    // Following takes place when user didn't edit either for Customer Info
    if (changeMap.size === 0) {
      flashBarMessage.header = 'No changes were made!'
      flashBarMessage.content =
        'Please edit either Customer Name or Payment Terms to update Customer Info'
      flashBarMessage.type = 'warning'
      setBannerItems([flashBarMessage])
      setShowFlashBar(true)
      return
    }

    // Following takes place when user edits both Customer Name and Payment Terms for Customer Info
    if (changeMap.size > 1) {
      flashBarMessage.header = 'You cannot update both!'
      flashBarMessage.content =
        'Please edit Customer Name OR Payment Terms to update Customer Info. You cannot update both Customer Name & Payment Terms at the same time'
      flashBarMessage.type = 'warning'
      setBannerItems([flashBarMessage])
      setShowFlashBar(true)
      return
    }

    const customerEdit: CustomerAndAddressRequest = {
      customerName: customerData.customerName,
      ticketNumber: ticketNumber,
      customerId: customerData.customerId,
      aob: aobIdToBusinessChannel.get(customerData.aob),
      termsetId: customerData.termsetId,
      primaryEmail: customerData.primaryEmail,
      customerType: customerData.customerType,
      maxOpenToBuyValue: Number(customerData.maxOpenToBuyValue),
      maxOpenToBuyCurrency: customerData.maxOpenToBuyCurrency,
      address: customerData.addresses[0],
      cimarronAccountId: customerData.cimarronAccounts[0].cimarronAccountId,
      customerChangeMap: Object.fromEntries(changeMap),
    }

    const result = await updateCustomer(customerEdit)
    const { data, error } = result
    if (error || !data) {
      setErrorMessage(updateCustomerErrorText)
    } else {
      setTrackingId(data?.trackingId || '')
      setErrorMessage(String(data?.errors) || 'Error updating Customer Info')
    }
    setShowConfirmationPopup(true)
    resetFields()
    return
  }

  return (
    <div>
      {!isEditing && (
        <Button
          className="awsui-util-ml-m"
          onClick={() => setIsEditing(true)}
          icon="edit"
          variant="primary"
          disabled={
            customerData.status === CUSTOMER_STATUS.ACTIVE ? false : true
          }
        >
          Edit
        </Button>
      )}
      <Modal
        header={`Edit Customer(${customerData.customerId}) Info`}
        visible={isEditing}
        onDismiss={() => resetOnDismiss()}
        footer={
          <span className="awsui-util-f-r">
            <Button
              variant="link"
              onClick={() => {
                resetOnDismiss()
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onSubmit()
              }}
            >
              Update
            </Button>
          </span>
        }
      >
        {
          <>
            {showFlashBar && <Flashbar items={bannerItems} />}
            <FormField label="Customer Name">
              <Input
                value={customerName}
                onChange={(e) => setCustomerName(e.detail.value)}
              ></Input>
            </FormField>
            <br />
            <FormField label="Payment Terms">
              <Select
                options={termsetIDOptions}
                selectedOption={selectedOption}
                selectedLabel={termSetId}
                onChange={(e) => {
                  setTermSetId(e.detail.selectedOption.id)
                  setSelectedOption(e.detail.selectedOption)
                }}
              ></Select>
            </FormField>
            <br />
            <FormField
              label="Ticket Number"
              description="Required"
              errorText={validateTicketNumber}
            >
              <Input
                value={ticketNumber}
                placeholder={'Write your ticket number here'}
                onChange={(e) => setTicketNumber(e.detail.value)}
              ></Input>
            </FormField>
            <br />
          </>
        }
      </Modal>

      <Modal
        header="Customer Details Edit Confirmation"
        visible={showConfirmationPopup}
        onDismiss={() => setShowConfirmationPopup(false)}
      >
        {trackingId !== '' ? (
          <PendingModal
            content={'Edit Customer is pending...'}
            trackingId={trackingId}
          />
        ) : (
          <FailureModal errorText={errorMessage} />
        )}
      </Modal>
    </div>
  )
}
