import {
  Button,
  FormField,
  Input,
  Modal,
} from '@amzn/awsui-components-react/polaris'
import React, { useState } from 'react'

import { activateCustomer, deactivateCustomer } from '../../../api'
import { ActivateDeactivateCustomerRequest } from '.'
import PendingModal from '../Modals/PendingModal'
import FailureModal from '../Modals/FailureModal'
import { CUSTOMER_STATUS } from '../../../constants'
import { ActivateDeactivateCustomerInterface } from '../../../types'

const ActivateDeactivateCustomerModal = ({
  activateDeactivateCustomer,
  showActivateDeactivatePopup,
  activateDeactivatePopupStatus,
}: {
  activateDeactivateCustomer: () => ActivateDeactivateCustomerInterface
  showActivateDeactivatePopup: boolean
  activateDeactivatePopupStatus: any
}) => {
  const {
    customerData: { cimarronAccounts, customerId, status: customerStatus },
  } = activateDeactivateCustomer()
  const [ticketNumber, setTicketNumber] = useState('')
  const [validateTicketNumber, setValidateTicketNumber] = useState('')
  const [showDeactivateCustomerModal, setShowDeactivateCustomerModal] =
    useState(false)
  const [trackingId, setTrackingId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const actionForCustomerStatus =
    customerStatus !== CUSTOMER_STATUS.ACTIVE ? 'Re-Activate' : 'Deactivate'
  const deactivationModalMessage = `Are you sure, you want to ${actionForCustomerStatus} Customer ${customerId} ?`
  const deactivateErrorText = `${actionForCustomerStatus} Customer failed.`

  const resetDeactivateCustomerModal = () => {
    setTicketNumber('')
    setValidateTicketNumber('')
    activateDeactivatePopupStatus()
  }

  const deactivateCustomerRequest = async () => {
    if (ticketNumber === '') {
      setValidateTicketNumber(
        'Enter Ticket Number, ticket Number is required to proceed.'
      )
      return
    }
    const activateDeactivateCustomerRequestData: ActivateDeactivateCustomerRequest =
      {
        marketplaceId: cimarronAccounts[0].displayableAddressMarketplaceId,
        cimarronAccountId: cimarronAccounts[0].cimarronAccountId,
        ticketNumber,
      }

    const result =
      customerStatus !== CUSTOMER_STATUS.ACTIVE
        ? await activateCustomer(activateDeactivateCustomerRequestData)
        : await deactivateCustomer(activateDeactivateCustomerRequestData)

    const { data, error } = result
    if (error) {
      setErrorMessage(` Error: ${data?.message}`)
    } else {
      setTrackingId(data?.trackingId || '')
    }
    setShowDeactivateCustomerModal(true)
    return
  }

  return (
    <>
      {!showDeactivateCustomerModal && (
        <Modal
          header={`${actionForCustomerStatus} Customer ${customerId}`}
          visible={showActivateDeactivatePopup}
          onDismiss={() => resetDeactivateCustomerModal()}
          footer={
            <span className="awsui-util-f-r">
              <Button
                variant="link"
                onClick={() => {
                  resetDeactivateCustomerModal()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  deactivateCustomerRequest()
                }}
              >
                {actionForCustomerStatus} Customer
              </Button>
            </span>
          }
        >
          {
            <>
              <h3> {deactivationModalMessage} </h3>
              <br />
              <FormField
                label="Ticket Number"
                description="Required"
                errorText={validateTicketNumber}
              >
                <Input
                  value={ticketNumber}
                  placeholder={'Write your ticket number here'}
                  onChange={(e) => setTicketNumber(e.detail.value)}
                />
              </FormField>

              <br />
            </>
          }
        </Modal>
      )}

      <Modal
        header={`Customer ${actionForCustomerStatus}`}
        visible={showDeactivateCustomerModal}
        onDismiss={() => {
          setShowDeactivateCustomerModal(false)
          resetDeactivateCustomerModal()
        }}
      >
        {trackingId !== '' ? (
          <PendingModal
            content={`Customer ${actionForCustomerStatus} is pending...`}
            trackingId={trackingId}
          />
        ) : (
          <FailureModal errorText={`${deactivateErrorText}${errorMessage} `} />
        )}
      </Modal>
    </>
  )
}

export default ActivateDeactivateCustomerModal
