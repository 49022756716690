import React, { useEffect, useState } from 'react'
import { ColumnLayout } from '@amzn/awsui-components-react/polaris'
import { Message as MessageType } from '../../../types'
import { printCurrency, printDate } from '../../../utils'
import DataField from '../../../Common/DataField'
import { getCimarronPersistedInformation } from '../../../api'
import { useParams } from 'react-router-dom'

export interface GetCimarronPersistedInformationRequest {
  billingHubMessageId: string
}

export interface GetCimarronPersistedInformationResponse {
  cimarronStatementId: string
  bill2PayUrl: string
  addressId: string
  aob: string
  transactionDate: string
  transactionAmount: string
  paymentTerm: string
  cimarronStatementNumber: string
  cimarronAccountId: string
}

const HeaderCimarron = ({
  header,
  message
}: {
  header?: any
  message?: MessageType
  control?: any
}) => {
  const { messageId, stage } = useParams<{ messageId: string; stage: string }>()

  const [cimarronInfo, setCimarronInfo] = useState<any>(undefined)

  useEffect(() => {
    async function getCimarronInfo() {
      try {
        const cimarronRequest: GetCimarronPersistedInformationRequest = {
          billingHubMessageId: messageId,
        }
        const { data, error } = await getCimarronPersistedInformation(
          cimarronRequest,
          stage!.toLowerCase()
        )
        if (!data || error) {
          throw new Error('Failed to fetch Cimarron Information')
        }
        setCimarronInfo(data)
      } catch (e) {
        console.log(e)
        //Cimarron Persist not loading will not break the page. Continue to display everything else.
        return
      }
    }
    getCimarronInfo()
  }, [messageId, stage])

  if (!message) return null

  return (
    <ColumnLayout columns={4} variant="text-grid">
      <div data-awsui-column-layout-root="true">
        <div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Billing Hub Message ID">
              {message?.messageId}
            </DataField>
            <DataField label="Invoice Header ID">
              {header?.invoiceHeaderId}
            </DataField>
            <DataField label="Issuer ID">
              {header?.customAttributes.issuer_id}
            </DataField>
            <DataField label="Amazon Operating Business">
              {header?.customAttributes.aob}
            </DataField>
          </div>
        </div>
        <div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Customer Name">
              {header?.customAttributes.customer_name}
            </DataField>
            <DataField label="Customer Account Number">
              {cimarronInfo?.cimarronAccountId}
            </DataField>
            <DataField label="Cimarron Statement ID">
              {cimarronInfo?.cimarronStatementId}
            </DataField>
            <DataField label="Cimarron Statement Number">
              {cimarronInfo?.cimarronStatementNumber}
            </DataField>
            <DataField label="Cimarron Address ID">
              {cimarronInfo?.addressId}
            </DataField>
          </div>
        </div>
        <div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Transaction Date">
              {printDate(new Date(cimarronInfo?.transactionDate))}
            </DataField>
            <DataField label="Transaction Amount">
              {cimarronInfo?.transactionAmount
                ? printCurrency(
                    Number(cimarronInfo?.transactionAmount),
                    header?.currencyCode
                  )
                : ''}
            </DataField>
            {message.messageType !== 'CREDIT_MEMO' ? (
              <DataField label="Terms">{cimarronInfo?.paymentTerm}</DataField>
            ) : (
              <DataField label="Related Invoice Number">
                {header?.customAttributes.original_invoice_number}
              </DataField>
            )}
            <DataField label="Payment Method">
              {header?.customAttributes.payment_method}
            </DataField>
            {/* TODO: Contract Type might be Contract Number. Will need to reconfirm with Zia/FinOps the naming convention */}
            <DataField label="Contract Type">
              {header?.customAttributes.contract_type}
            </DataField>
          </div>
        </div>
        <div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="TT Number">
              {header?.customAttributes.tt_number}
            </DataField>
            <DataField label="PO Number">{header?.purchaseOrder}</DataField>
            <DataField label="Bill2Pay Link">
              <a
                href={cimarronInfo?.bill2PayUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {cimarronInfo?.bill2PayUrl ? 'Link' : ''}
              </a>
            </DataField>
          </div>
        </div>
      </div>
    </ColumnLayout>
  )
}

export default HeaderCimarron
