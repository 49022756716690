import React from 'react'
import { Button } from '@amzn/awsui-components-react/polaris'
import { useHistory } from 'react-router-dom'

const Step3 = () => {
  const history = useHistory()
  return (
    <div>
      <p>
        Your file was uploaded successfully and will now be processed by Billing
        Hub. You can see the file status and also view the Billing Hub messages
        that the file generates.
      </p>
      <br />
      <Button onClick={() => history.push('/webbilling/filesLog')}>
        Back to Files List
      </Button>
    </div>
  )
}

export default Step3
