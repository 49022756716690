import React from 'react'
import { Badge, Popover } from '@amzn/awsui-components-react/polaris'

import './Status.scss'

const Status = ({ status }: { status?: string }) => {
  if (!status)
    return (
      <Badge color="grey" className="fixed-badge">
        Missing
      </Badge>
    )
  else if (status === 'PENDING')
    return (
      <Badge color="blue" className="fixed-badge">
        Pending
      </Badge>
    )
  else if (status === 'SUCCEEDED')
    return (
      <Badge color="green" className="fixed-badge">
        Succeeded
      </Badge>
    )
  else if (status === 'FAILED')
    return (
      <Badge color="red" className="fixed-badge">
        Failed
      </Badge>
    )
  else
    return (
      <Popover
        size="small"
        content={
          <>
            The message's status is not recognized. If this happens on
            production data, please{' '}
            <a
              href="https://sim.amazon.com/issues/create?template=e8425949-0eab-4762-a81f-ab2b82c70647"
              target="_blank"
              rel="noopener noreferrer"
            >
              submit a ticket
            </a>
            .
          </>
        }
        className="popover-invalid-message"
        position="left"
      >
        <Badge color="grey" className="fixed-badge">
          Invalid
        </Badge>
      </Popover>
    )
}

export default Status
