import React from 'react'
import ClientMessageRequest from './ClientMessageRequest'
import BillingHubRequest from './BillingHubRequest'
import ProcessLog from './ProcessLog'
import PageTitle from '../../Common/PageTitle'
import { Message as MessageType } from '../../types'
import { useParams } from 'react-router-dom'
import Alert from '@amzn/awsui-components-react/polaris/alert'
import { useMessage, ErrorObject } from '../../api'

const Message = () => {
  const { messageId, stage } = useParams<{ messageId: string; stage: string }>()
  const { data, loading, error } = useMessage(messageId, stage)

  const message = data?.message || undefined
  console.log(message)
  const canReadRestrictedData = !!data?.canReadRestrictedData

  return (
    <div className="awsui-grid awsui-util-p-m">
      <StatusMessage message={message} loading={loading} error={error} />
      <br />
      <PageTitle title={decodeURIComponent(messageId || '')} />
      <ClientMessageRequest message={message} loading={loading} />
      <BillingHubRequest
        message={message}
        loading={loading}
        canReadRestrictedData={canReadRestrictedData}
      />
      <div className="awsui-grid">
        <div className="awsui-row">
          <ProcessLog message={message} loading={loading} canReadRestrictedData={canReadRestrictedData}/>
        </div>
      </div>
    </div>
  )
}

const StatusMessage = ({
  loading,
  message,
  error,
}: {
  loading: boolean
  message?: MessageType
  error?: ErrorObject
}) => {
  if (loading) return <div style={{ height: 60 }} />
  // placeholder, prevents the page from shifting around after the status loads
  else if (error?.unauthorized)
    return (
      <Alert header="Message Not Found" type="warning">
        You do not have permission to view this message. Permissions are handled
        by Bindles where each client ID has a separate Bindle resource. Please{' '}
        <a
          href="https://sim.amazon.com/issues/create?template=e8425949-0eab-4762-a81f-ab2b82c70647"
          target="_blank"
          rel="noopener noreferrer"
        >
          submit a ticket
        </a>{' '}
        for help with permissions. .
      </Alert>
    )
  else if (!message)
    return (
      <Alert header="Message Not Found" type="error">
        The message could be not located. Check the message ID and try again. If
        you are experiencing a bug,{' '}
        <a
          href="https://sim.amazon.com/issues/create?template=e8425949-0eab-4762-a81f-ab2b82c70647"
          target="_blank"
          rel="noopener noreferrer"
        >
          submit a ticket
        </a>
        .
      </Alert>
    )
  else if (message?.messageStatus === 'PENDING')
    return <Alert type="info">This message is currently being processed.</Alert>
  else if (message?.messageStatus === 'SUCCEEDED')
    return <Alert type="success">This message was successfully processed.</Alert>
  else if (message?.messageStatus === 'FAILED')
    return (
      <Alert type="error" header="There was a problem processing this message.">
        <span className="monospace">{getErrorDescription(message)}</span>
      </Alert>
    )
  else {
    console.log(message?.messageStatus)
    return <Alert type="warning">This message has an invalid status.</Alert>
  }
}

// if the message log has an event matching the message status and if that event has a longer
// description, show it.
const getErrorDescription = (message: MessageType): String => {
  if (!message.messageStatusRaw) return ''

  try {
    const event = message?.messageLog?.find(
      (entry) => entry.status === message.messageStatusRaw
    )
    const detail = JSON.parse(event?.detail || '')
    return `${message.messageStatusRaw}:${detail.eventDescription}`.substr(
      0,
      1000
    )
  } catch (e) {
    return message.messageStatusRaw
  }
}

export default Message
