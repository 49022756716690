import React, { useState } from 'react'
import { Spinner, Icon} from '@amzn/awsui-components-react/polaris'
import Lines from './Lines'
import HeaderOFA from './HeaderOFA'
import { Message as MessageType } from '../../../types'
import useFetch, { CachePolicies } from 'use-http'
import IonViewer from '../../../Common/IONViewer'
import HeaderCimarron from './HeaderCimarron'
import JSONViewer from '../../../Common/JSONViewer'
// import * as ionjs from 'ion-js'
import CimarronLines from './CimarronLines'

enum PayloadView {
  Parsed,
  JSON,
  Ion,
}

const BillingHubRequest = ({
  message,
  loading,
  canReadRestrictedData,
}: {
  message?: MessageType
  loading: boolean
  canReadRestrictedData: boolean
}) => {
  const {
    data,
    loading: loadingData,
  } = useFetch(message?.invoiceUri || message?.clientRequestUri || '', { cachePolicy: CachePolicies.NO_CACHE }, [message])
  const [payloadView, setPayloadView] = useState(PayloadView.Parsed)
  console.log(data)
  let jsonData = data
  let ionData: any = null

  if (typeof data === 'string') {
    // useFetch automatically tries parsing response as JSON, if not JSON it fails and parses as string. This condition detects Ion response
    // ionData = ionjs.load(data)
    // jsonData = ionToJSON(ionData)
  }

  const isCimarron = (message?.subledger === 'Cimarron')

  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>
              Billing Hub <Icon name="angle-right" /> {loading ? '' : (isCimarron ? 'Cimarron' : 'OFA')}
            </h2>
          </div>
          <div className="awsui-util-action-stripe-action">
            <div className="awsui-util-container-header-description">
              {!message ? null : (message?.invoiceUri || message?.clientRequestUri) ? (
                <>
                  <span
                    className="link link-bold"
                    onClick={() =>
                      payloadView === PayloadView.JSON
                        ? setPayloadView(PayloadView.Parsed)
                        : setPayloadView(PayloadView.JSON)
                    }
                  >
                    {payloadView === PayloadView.JSON ? 'View Parsed Data' : 'View Raw JSON'}
                  </span>
                  {isCimarron ? ( // Cimarron payloads can also be viewed in Ion format
                    <span
                      className="link link-bold awsui-util-ml-m"
                      onClick={() =>
                        payloadView === PayloadView.Ion
                          ? setPayloadView(PayloadView.Parsed)
                          : setPayloadView(PayloadView.Ion)
                      }
                    >
                      {payloadView === PayloadView.Ion ? 'View Parsed Data' : 'View Raw Ion'}
                    </span>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {loading || loadingData ? (
        <div className="awsui-util-container awsui-util-t-c">
          <Spinner />
        </div>
      ) : (
        <>
          {renderHeader(canReadRestrictedData, isCimarron, message, jsonData)}
          {renderBillingHubPayload(payloadView, isCimarron, jsonData, ionData)}
        </>
      )}
    </div>
  )
}

const renderHeader = (canReadRestrictedData: boolean, isCimarron: boolean, message?: MessageType, jsonData?: any) => {
  console.log(jsonData?.invoiceHeader)
  return isCimarron ? (
    <HeaderCimarron
      message={message}
      header={jsonData?.header}
    />
  ) : (
    <HeaderOFA
      message={message}
      header={jsonData?.invoiceHeader}
      canReadRestrictedData={canReadRestrictedData}
    />
  )
}

const renderBillingHubPayload = (payloadView: PayloadView, isCimarron: boolean, jsonData?: any, ionData?: any) => {
  switch (payloadView) {
    case PayloadView.Parsed:
      return isCimarron ? (<CimarronLines lines={jsonData?.lines} />) : (<Lines lines={jsonData?.invoiceLines || jsonData?.creditMemoLines} />)
    case PayloadView.Ion:
      return <IonViewer ion={ionData} />
    case PayloadView.JSON:
      return <JSONViewer json={jsonData} />
  }
}

// const ionToJSON = (ion: any) => {
//   return JSON.parse(JSON.stringify(ion))
// }

export default BillingHubRequest