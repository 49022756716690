import React from 'react'
import { Badge, Popover } from '@amzn/awsui-components-react/polaris'

const MigrationStatus = (
    {status}:{
    status?: string
}) => {

    const statuses = {
        inProgress: 'SENT_TO_BHUB',
        pending: 'NEW',
        succeeded: 'PROCESSED_BY_CIMARRON',
        inProgressAdj: 'APPLY_ADJ',
        inProgressReceipt: 'APPLY_RECEIPT',
        failed: 'FAILED',
      }

    if (status === statuses.pending)
    return (
        <Badge color="grey">
          Pending
        </Badge>
      )
    else if (status === statuses.inProgress)
    return (
        <Badge color="blue">
          Migration in Progress: Sent to Billing Hub
        </Badge>
      )
    else if (status === statuses.succeeded)
    return (
        <Badge color="green" >
          Succeeded: Migrated to Cimarron
        </Badge>
      )
    else if (status === statuses.inProgressReceipt)
    return (
        <Badge color="blue" >
          Migration in Progress: Sent to Billing Hub
        </Badge>
      )  
    else if (status === statuses.inProgressAdj)
      return (
          <Badge color="blue" >
            Migration in Progress: Sent to Billing Hub
          </Badge>
        )  
    else if (status === statuses.failed)
    return (
        <Badge color="red">
        Migration Failed: Reason Pending
        </Badge>
    )
    else
    return (
      <Popover
        size="small"
        content={
          <>
            The message's status is not recognized. If this happens on
            production data, please{' '}
            <a
              href="https://sim.amazon.com/issues/create?template=e8425949-0eab-4762-a81f-ab2b82c70647"
              target="_blank"
              rel="noopener noreferrer"
            >
              submit a ticket
            </a>
            .
          </>
        }
        className="popover-invalid-message"
        position="left"
      >
        <Badge color="grey" className="fixed-badge">
          Invalid
        </Badge>
      </Popover>
    )
    
}

export default MigrationStatus;
