// import { useState, useEffect } from 'react'
import qs from 'qs'
import { useState, useEffect } from 'react'
import { ADDRESS_TYPES, ADDRESS_TYPES_LABELS, PRIMARY_SITE } from './constants'

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
]
const toDoubleDigits = (n: number) => ('0' + n).slice(-2)
export const printDate = (d?: Date) =>
  !d || isNaN(d.getTime())
    ? ''
    : `${toDoubleDigits(d.getDate())}-${
        months[d.getMonth()]
      }-${d.getFullYear()}`

export const printDateTime = (d?: Date) =>
  !d || isNaN(d.getTime())
    ? ''
    : `${printDate(d)} ${toDoubleDigits(d.getHours())}:${toDoubleDigits(
        d.getMinutes()
      )}:${toDoubleDigits(d.getSeconds())}`

export const printCurrency = (c?: number, currencyCode?: string): string =>
  typeof c === 'number' ? `${c.toFixed(2)} ${currencyCode || ''}` : ''

const getQueryParams = () => qs.parse(window.location.search.slice(1))
export const useQueryParams = getQueryParams

export function downloadString(
  filename: string,
  text: string,
  contentType: string
) {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:${contentType};charset=utf-8,${encodeURIComponent(text)}`
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

type UsePersistedState<T> = (
  initialValue: T,
  key: string
) => {
  0: T
  1: (value: T) => void
}
export const usePersistedState = (initialValue: any, key: string) => {
  const [state, setState] = useState(initialValue)
  const setPersistedState = (value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
    setState(value)
  }

  useEffect(() => {
    try {
      const persistedState = localStorage.getItem(key)
      if (persistedState) {
        const value = JSON.parse(persistedState as string)
        setState(value)
      }
    } catch (e) {
      // do nothing
    }
  }, [key])

  return [state, setPersistedState]
}

export const openWindowWithJSON = (json: any) => {
  const w = window.open(
    '',
    'JSON',
    'width=600,height=800,scrollbars=1,resizeable=1'
  )
  w?.document.open()
  w?.document.write(`<pre>${JSON.stringify(json, null, 2)}</pre>`)
  w?.document.close()
}

export const fetchFileSize = async (url: string): Promise<number | null> => {
  const res = await fetch(url, { method: 'HEAD' })
  return getFileSizeFromResponse(res)
}

export const getFileSizeFromResponse = (response: Response): number | null => {
  if (response?.headers) {
    const headers = response.headers.entries()
    let header = headers.next()
    while (!header.done) {
      if (header.value[0].toLowerCase() === 'content-length')
        return parseFloat(header.value[1]) / 1024 / 1024
      header = headers.next()
    }
  }
  return null
}

export const parseToken = (tokenString: string | null): any => {
  try {
    if (!tokenString) throw new Error('Token is null or empty')
    const part = tokenString?.split('.')[1]
    const jsonString = atob(part as string)
    const json = JSON.parse(jsonString as string)
    const exp = json.exp
    if (exp < Date.now() / 1000) throw new Error('Token is expired.')
    return json
  } catch (e) {
    return false
  }
}

/**
 * Converts a UTC date in the form yyyy-mm-dd to the corresponding epoch time (seconds since January 1. 1970)
 * @param {string} standardTime - A string UTC date in the form yyyy-mm-dd
 * @return {number} The epoch time representation of standardTime
 */
export const standardToEpoch = (standardTime?: string) => {
  if (!standardTime) {
    return 0
  }
  var [year, month, day] = standardTime.split('-')

  var dateObject = Date.UTC(Number(year), Number(month) - 1, Number(day))

  return dateObject / 1000
}

/**
 *  Parses date fields from an invoice header object retrieved from the requestURI from the BillingHub S3 bucket into a format usable by Polaris date UI component
 * @param {string} date A string UTC date in the invoice header format (i.e. Fri Jan 31 23:35:52 UTC 2020)
 * @return {string} A string UTC date in the form yyyy-mm-dd
 */
export const parseInvoiceHeaderDate = (date: string) => {
  if (!date) {
    return ''
  }

  var splitArray = date.split(' ')
  var month = String(months.indexOf(splitArray[1].toUpperCase()) + 1)
  var day = splitArray[2]
  var year = splitArray[5]

  if (month.length === 1) {
    month = '0' + month
  }

  return year + '-' + month + '-' + day
}

export const dateStringToTimestamp = (str?: string): number | null => {
  if (!str) return null

  try {
    const [year, month, day] = str
      .split('-')
      .map((part: string) => parseInt(part))
    const date = new Date(year, month - 1 /* month is 0 indexed */, day)
    return (date.getTime() / 1000) >> 0 || null
  } catch (e) {
    return null
  }
}

const { hostname } = window.location

export const getStage = () => {
  if (hostname === 'card.fintech.amazon.dev') {
    return STAGES.PROD
  } else if (hostname === 'dev.card.fintech.amazon.dev') {
    return STAGES.BETA
  } else if (hostname.includes('localhost')) {
    return STAGES.LOCALHOST
  } else return STAGES.ZOOM
}

export const STAGES = {
  PROD: 'PROD',
  BETA: 'BETA',
  LOCALHOST: 'BETA',
  ZOOM: 'ZOOM',
}

export const stage = getStage()

export const isProd = stage === STAGES.PROD || stage === STAGES.ZOOM

export const AobTransformation = (aob: string, aobToAobIdMap: any) => {
  let aobName = ''
  aobToAobIdMap.forEach(function (value: string, key: string) {
    if (value === aob) aobName = key
  })
  return aobName
}

export const addressTypes = [
  { id: ADDRESS_TYPES.BILL_TO, label: ADDRESS_TYPES_LABELS.BILL_TO },
  { id: ADDRESS_TYPES.SHIP_TO, label: ADDRESS_TYPES_LABELS.SHIP_TO },
]

export const primarySiteOptions = [
  { id: PRIMARY_SITE.YES, label: PRIMARY_SITE.YES },
  { id: PRIMARY_SITE.NO, label: PRIMARY_SITE.NO },
]

export const getField = (field: string, data: any) => {
  return data[field]
}

export const isEmpty = (fieldValue) =>
  fieldValue === null || fieldValue === '' || fieldValue === undefined

export interface FlashbarProps {
  header: string
  type: string
  content: string
  dismissible: boolean
  onDismiss: () => void
}
