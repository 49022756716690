import { ColumnLayout } from '@amzn/awsui-components-react'
import React from 'react'
import DataField from '../../../Common/DataField'
import { addressTypes } from '../../../utils'

export default function ViewAddressDetails({
  addressData,
  getField,
}: {
  addressData?: any
  getField: any
}) {
  return (
    <div className="awsui-util-pb-xxl">
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          <div className="awsui-util-spacing-v-s">
            <DataField label="Address ID">
              <div>{getField('addressId', addressData)}</div>
            </DataField>
          </div>
        </div>
      </ColumnLayout>
      <h4 className="awsui-util-mt-l awsui-util-mb-s">Contact info:</h4>
      <ColumnLayout columns={3} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          <div className="awsui-util-spacing-v-s">
            <DataField label="Name">
              <div>
                {getField('firstName', addressData)} &nbsp;
                {getField('lastName', addressData)}
              </div>
            </DataField>
            <DataField label="Primary phone number">
              <div>{getField('primaryPhoneNumber', addressData)}</div>
            </DataField>
          </div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Primary email">
              <div>{getField('contactEmail', addressData)}</div>
            </DataField>
            <DataField label="Secondary phone number">
              <div>{getField('secondaryPhoneNumber', addressData)}</div>
            </DataField>
          </div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Status">
              <div className="capitalize">
                <b>{getField('status', addressData)}</b>
              </div>
            </DataField>
            <DataField label="Secondary email">
              <div>
                {getField('secondaryEmails', addressData)
                  ? String(getField('secondaryEmails', addressData))
                  : ''}
              </div>
            </DataField>
          </div>
        </div>
      </ColumnLayout>

      <h4 className="awsui-util-mt-l awsui-util-mb-s">Address info:</h4>
      <ColumnLayout columns={4} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          <div className="awsui-util-spacing-v-s">
            <DataField label="Street address">
              <div>
                {getField('addressLine1', addressData)} &nbsp;
                {getField('addressLine2', addressData)} &nbsp;
                {getField('addressLine3', addressData)}
              </div>
            </DataField>
          </div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="City, State & Postal code">
              <div>
                {getField('city', addressData)} &nbsp;
                {getField('state', addressData)} &nbsp;
                {getField('postalCode', addressData)}
              </div>
            </DataField>
          </div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Country">
              <div>{getField('country', addressData)}</div>
            </DataField>
          </div>
          <div className="awsui-util-spacing-v-s">
            <DataField label="Address type">
              <div>
                {
                  addressTypes.find(
                    (site) => site.id === getField('siteUseCode', addressData)
                  )?.label
                }
              </div>
            </DataField>
          </div>
        </div>
      </ColumnLayout>
    </div>
  )
}
