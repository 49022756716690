import React, { useEffect, useState } from 'react'
import Table from '@amzn/awsui-components-react/polaris/table'
import { printCurrency, downloadString } from '../../utils'
import {
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
} from '@amzn/awsui-components-react/polaris'
import { useHistory } from 'react-router-dom'
import { Message } from '../../types'
import papaparse from 'papaparse'
import { useSearchAudit } from '../../api'
import { useQueryParams } from '../../utils'

const AuditSearchResults = ({
  getColumnDefinitions,
  getSearchParams,
  searchSettings,
}: {
  getColumnDefinitions: any
  getSearchParams: any
  searchSettings: any
}) => {
  const queryParams = useQueryParams()
  const searchParams: any = getSearchParams(queryParams)

  const history = useHistory()

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [pagesCount, setPagesCount] = useState(1)
  const [sortBy, setSortBy] = useState('messageId')
  const [sortAscending, setSortAscending] = useState(true)
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [startKeyMap, setStartKeyMap] = useState()
  const [paginationKeyMap, setPaginationKeyMap] = useState(new Map())

  // when data finishes loading, update the pages count.
  // pages count needs to be stored in state so that while a new page
  // is loading and the result count isn't availble on the data object,
  // the page count isn't forgotten
  useEffect(() => {
    // Allow calling function within hook
    // eslint-disable-next-line
    useSearchAudit({
      before: searchParams.before,
      after: searchParams.after,
      query: searchParams.query,
      messageType: searchParams.messageType,
      status: searchParams.status,
      start: searchParams.query ? 0 : pageSize * (page - 1),
      limit: pageSize,
      sortBy,
      sortAscending,
      startKeyMap
    }).then(({ data }) => {
      if (!data) {
        setLoading(false)
        return
      }
      setLoading(false)
      setResults(data!.results || [])
      setPagesCount(data ? Math.ceil(data.count / pageSize) : 1)
      setPaginationKeyMap(data!.lastKeyMap)
    })
  }, [
    searchParams.before,
    searchParams.after,
    searchParams.query,
    searchParams.messageType,
    searchParams.status,
    sortBy,
    sortAscending,
    page,
    pageSize,
      startKeyMap
  ])

  const onDownloadResults = async () => {
    const results: Message[] = []
    const string = papaparse.unparse(
      results.map((result) => ({
        'Message ID': result.messageId,
        'Client ID': result.clientId,
        'Client Request ID': result.clientRequestId,
        'Message Type': result.messageType,
        'Message Status': result.messageStatus,
        'Transaction Date': result.trxDate
          ? new Date(result.trxDate * 1000).toISOString()
          : '',
        Amount: printCurrency(
          result.controlTotalLineAmount,
          result.currencyCode
        ),
      }))
    )
    downloadString(`results.csv`, string, 'text/csv')
  }

  return (
    <div className="awsui-grid awsui-util-p-m util-full-width">
      <div className="awsui-util-container-header">
        <h1>Event Log</h1>
      </div>
      {/*<PageTitle title={query ? `"${query}"` : 'Search'} />*/}
      <Table
        header={searchSettings(queryParams, history, onDownloadResults)}
        loading={loading}
        loadingText="Searching"
        columnDefinitions={getColumnDefinitions}
        items={results}
        wrapLines={false}
        features={['pagination', 'sorting']}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
          </div>
        }
      >
        <TablePagination
          pageSize={pageSize}
          pagesCount={pagesCount}
          openEnd={false}
          onPaginationChange={(e) => {
            setPage(e.detail.currentPageIndex)
            setPageSize(e.detail.pageSize)
            setStartKeyMap((paginationKeyMap && paginationKeyMap[e.detail.currentPageIndex]) || null)
          }}
        />
        <TableSorting
          sortingColumn={sortBy}
          sortableColumns={getColumnDefinitions.map((c: any) => ({
            id: c.id as string,
          }))}
          onSortingChange={(e) => {
            setSortBy(e.detail.sortingColumn)
            setSortAscending(!e.detail.sortingDescending)
          }}
        />
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TableWrapLines
            label="Wrap text in lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          <TableContentSelector
            title="Visible Columns"
            options={[
              {
                label: 'Content',
                options: getColumnDefinitions.map((c: any) => ({
                  id: c.id as string,
                  label: c.header as string,
                  visible: c.id !== 'messageStatusRaw',
                })),
              },
            ]}
          />
          <TablePageSizeSelector
            title="Lines Per Page"
            options={[
              { value: 10, label: '10 lines' },
              { value: 25, label: '25 lines' },
              { value: 100, label: '100 lines' }
            ]}
          />
        </TablePreferences>
      </Table>
    </div>
  )
}

export default AuditSearchResults
