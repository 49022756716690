import React from 'react'
import Table from '@amzn/awsui-components-react/polaris/table'
import { printDate, } from '../../utils'
import { TableSorting } from '@amzn/awsui-components-react'
import { EventLogRow, SharedStates } from './AuditSearch'
import AuditStatus from './AuditStatus'

const columnDefinitionsAudit = (shareStates: () => SharedStates): Table.ColumnDefinition[] => {

  let columns = [
    {
      id: 'clientRequestId',
      header: 'Tracking ID',
      cell: (item: EventLogRow) => item.trackingId,
    },
    {
      id: 'userAlias',
      header: 'User Alias',
      cell: (item: EventLogRow) => item.user,
    },
    {
      id: 'billingChannel',
      header: 'Billing Channel',
      cell: (item: EventLogRow) => item.billingChannel,
    },
    {
      id: 'marketplace',
      header: 'Marketplace',
      cell: (item: EventLogRow) => item.marketplace,
    },
    {
      id: 'messageType',
      header: 'Message Type',
      cell: (item: EventLogRow) => item.messageType,
    },
    {
      id: 'processingDate',
      header: 'Processing Date',
      cell: (item: EventLogRow) =>
        item.processingDate && printDate(new Date(item.processingDate * 1000)),
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item: EventLogRow) => <AuditStatus status={item.status} trackingId = {item.trackingId} messageType = {item.messageType} eventMetadata = {item.eventMetadata} aggregationFailedDisplayFlag = {item.aggregationFailedDisplayFlag}/>,
    },
  ]

  return columns.map((c) => ({
    ...c,
    // generate label functions based on column attributes
    label: (sortState: TableSorting.SortingState) => {
      const columnIsSorted = sortState.sortingColumn === c.id
      const ascending = !sortState.sortingDescending
      return `${c.header}, ${
        columnIsSorted ? `sorted ${ascending ? 'ascending' : 'descending'}` : 'not sorted'
      }.`
    },
  }))
}

export default columnDefinitionsAudit