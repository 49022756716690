// Add Is-It-Down link on each render
import React from 'react'

import './Logo.scss'

export default () => {
  return (
    <div className="logo-is-it-down">
      <a
        href="https://is-it-down.amazon.com/CARD"
        target="_blank"
        rel="noopener noreferrer"
      >
        Is BillingHub Down?
      </a>
      <img
        src="https://is-it-down.amazon.com/CARD/components/17658.gif"
        alt="Is It Down"
      />
    </div>
  )
}
