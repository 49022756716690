import React, { useEffect, useState } from 'react'
import NotificationForm from './NotificationForm'
import UploadInfoForm from './UploadInfoForm'
import Authentication, { permissions } from '../../Common/Authentication'
import Restricted from '../../Common/Restricted'
import Spinner from '@amzn/awsui-components-react/polaris/spinner'

const SpreadsheetUpload = () => {
  const [formInputs, setFormInputs] = useState(new Email())
  const [permissionSet, setPermissionSet] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getPermissionSet() {
      const data = await Authentication()
      setPermissionSet(data)
      setLoading(false)
    }
    getPermissionSet()
  }, [])

  if (loading) {
    return (
      <div className="awsui-util-container awsui-util-t-c">
        <Spinner />
      </div>
    )
  } else {
    if (
      permissionSet &&
      (permissionSet.includes(permissions.UPLOAD_INVOICE) ||
        permissionSet.includes(permissions.PRIVILEGED_ACCESS))
    )
      return (
        <div className="awsui-grid awsui-util-p-m">
          <h1>Spreadsheet Upload</h1>
          <NotificationForm
            formInputs={formInputs}
            setFormInputs={setFormInputs}
          />
          <UploadInfoForm
            notificationFormInputs={formInputs}
            permissionSet={permissionSet}
          />
        </div>
      )
    else {
      return Restricted()
    }
  }
}

export class Email {
  emailId?: string
  ccAlias?: string
}

export default SpreadsheetUpload
