import { Button } from '@amzn/awsui-components-react/polaris'
import React, { useState } from 'react'
import { CUSTOMER_STATUS } from '../../../constants'
import ActivateDeactivateCustomerModal from './ActivateDeactivateCustomerModal'
import { ActivateDeactivateCustomerInterface } from '../../../types'

export interface ActivateDeactivateCustomerRequest {
  marketplaceId: any
  cimarronAccountId: string
  ticketNumber: string
}

export interface ActivateDeactivateCustomerResponse {
  trackingId: string
  errors?: Error[]
  message?: string
}

const ActivateDeactivateCustomer = ({
  activateDeactivateCustomer,
}: {
  activateDeactivateCustomer: () => ActivateDeactivateCustomerInterface
}) => {
  const {
    customerData: { status: customerStatus },
  } = activateDeactivateCustomer()
  const [showActivateDeactivatePopup, setShowActivateDeactivatePopup] =
    useState(false)
  const toggleActivateDeactivatePopupStatus = () => {
    setShowActivateDeactivatePopup(!showActivateDeactivatePopup)
  }
  return (
    <>
      {showActivateDeactivatePopup && (
        <ActivateDeactivateCustomerModal
          activateDeactivateCustomer={activateDeactivateCustomer}
          showActivateDeactivatePopup={showActivateDeactivatePopup}
          activateDeactivatePopupStatus={toggleActivateDeactivatePopupStatus}
        />
      )}
      {customerStatus !== CUSTOMER_STATUS.ACTIVE ? (
        <div>
          <Button
            className="awsui-util-ml-m"
            onClick={() => {
              setShowActivateDeactivatePopup(true)
            }}
            icon="status-positive"
            variant="primary"
          >
            Re-activate Customer
          </Button>
        </div>
      ) : (
        <div>
          <Button
            className="awsui-util-ml-m"
            onClick={() => setShowActivateDeactivatePopup(true)}
            icon="status-negative"
          >
            Deactivate Customer
          </Button>
        </div>
      )}
    </>
  )
}

export default ActivateDeactivateCustomer
