import React, { useEffect, useState } from 'react'
import Table from '@amzn/awsui-components-react/polaris/table'
import {dateStringToTimestamp, stage} from '../../utils'
import AuditSettings from './AuditSettings'
import AuditSearchResults from './UseAuditSearch'
import columnDefinitionsAudit from './AuditColumnDefinitions'
import Authentication, { permissions } from '../../Common/Authentication'
import Restricted from '../../Common/Restricted'
import Spinner from '@amzn/awsui-components-react/polaris/spinner'

export interface ViewEventLogRequest {
  query: string
  messageType: string
  status: string
  startDate: number
  endDate: number
  PaginationInfo: PaginationInfo
  startKeyMap?: any
}

export interface ViewEventLogResponse {
  results: EventLogRow[]
  count: number
  lastKeyMap: any
}

export interface EventLogRow {
  trackingId: string
  user: string
  billingChannel: string
  marketplace: string
  messageType: string
  processingDate: number
  status: string
  eventMetadata: string
  aggregationFailedDisplayFlag: boolean
  
}

export interface PaginationInfo {
  start: number
  limit?: number
  sortBy?: string
  sortAscending?: boolean
}


export interface SharedStates {
  modalData: any
  setModalData: any
}

export default function AuditSearch() {
  const [modalData, setModalData] = useState<Table.Item | undefined>(undefined)
  const shareStates = (): SharedStates => {
    return {
      modalData,
      setModalData,
    }
  }
  const [permissionSet, setPermissionSet] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getPermissionSet() {
      const data = await Authentication()
      setPermissionSet(data)
      setLoading(false)
    }
    getPermissionSet()
  }, [])

  if (loading) {
    return (
      <div className="awsui-util-container awsui-util-t-c">
        <Spinner />
      </div>
    )
  } else {
    if (
      permissionSet &&
      (permissionSet.includes(permissions.READ_EVENT_LOG_SCREEN) ||
        permissionSet.includes(permissions.PRIVILEGED_ACCESS))
    )
      return (
        <AuditSearchResults
          getColumnDefinitions={columnDefinitionsAudit(shareStates)}
          getSearchParams={getSearchParams}
          searchSettings={getSettings}
        />
      )
    else {
      return Restricted()
    }
  }
}

const getSearchParams = (queryParams: any) => {
  const getBefore = (before: any) => {
    if (before) {
      let date = dateStringToTimestamp(before)
      if (date) date += 24 * 60 * 60 - 1
      return date
    }
  }

  return {
    query: queryParams.query || '',
    before: getBefore(queryParams.before) || undefined,
    after: dateStringToTimestamp(queryParams.after) || undefined,
    status:
      queryParams.status === 'ALL' || !queryParams.status
        ? undefined
        : queryParams.status,
    messageType:
      queryParams.messageType === 'ALL' || !queryParams.messageType
        ? undefined
        : queryParams.messageType,
    stage: queryParams.stage || stage,
  }
}

const getSettings = (params: any, history: any, onDownloadResults: any) => {
  const initialParams = {
    query: params.query || '',
    before: params.before || '',
    after: params.after || '',
    status: params.status || 'ALL',
    messageType: params.messageType || 'ALL',
    stage: params.stage || stage,
  }

  return (
    <AuditSettings
      initialParams={initialParams}
      onSearch={(params) => {
        const newParams = new URLSearchParams(window.location.search)
        Object.entries(params).forEach(([key, value]) =>
          newParams.set(key, ('' + value) as string)
        )
        history.push(`${window.location.pathname}?${newParams.toString()}`)
      }}
      onDownloadResults={onDownloadResults}
    />
  )
}
