import React, { useEffect, useState, useMemo } from 'react'
import Table from '@amzn/awsui-components-react/polaris/table'
import { printCurrency, downloadString} from '../../utils'
import {
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
} from '@amzn/awsui-components-react/polaris'
import { useHistory } from 'react-router-dom'
import { Message } from '../../types'
import papaparse from 'papaparse'
import { useQueryParams } from '../../utils'

const SearchResults = ({
  getColumnDefinitions,
  useSearch,
  getSearchParams,
  searchSettings,
}: {
  getColumnDefinitions: any
  useSearch: any
  getSearchParams: any
  searchSettings: any
}) => {
  const queryParams = useQueryParams()
  const searchParams: any = getSearchParams(queryParams)

  const history = useHistory()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [pagesCount, setPagesCount] = useState(1)
  const [sortBy, setSortBy] = useState('messageId')
  const [sortAscending, setSortAscending] = useState(true)
  const { data, loading } = useSearch({
    ...searchParams,
    start: pageSize * (page - 1),
    limit: pageSize,
    sortBy,
    sortAscending,
  })
  const results = data?.results || []

  // when data finishes loading, update the pages count.
  // pages count needs to be stored in state so that while a new page
  // is loading and the result count isn't availble on the data object,
  // the page count isn't forgotten
  useEffect(() => {
    if (!loading) setPagesCount(data ? Math.ceil(data.count / pageSize) : 1)
    // disabling linter for clarity purposes. Only these dependencies trigger rerender
    // eslint-disable-next-line
  }, [loading, pageSize])

  const columnDefinitions = useMemo(() => getColumnDefinitions(searchParams), [searchParams, getColumnDefinitions])

  const onDownloadResults = async () => {
    const csvResults: Message[] = results
    const string = papaparse.unparse(
      csvResults.map((result) => ({
        'Message ID': result.messageId,
        'Client ID': result.clientId,
        'Client Request ID': result.clientRequestId,
        'Message Type': result.messageType,
        'Message Status': result.messageStatus,
        'OFA Invoice Number': result.ofaTrxNumber,
        'Transaction Date': result.trxDate ? new Date(result.trxDate * 1000).toISOString(): '',
        'Ingestion Time (PST)': result.ingestionTime ? new Date(result.ingestionTime * 1000).toLocaleString('en-US', { timeZone: 'PST' }) : '',
        Amount: printCurrency(result.controlTotalLineAmount, result.currencyCode),
      }))
    )
    downloadString(`results.csv`, string, 'text/csv')
  }

  return (
    <div className="awsui-grid awsui-util-p-m util-full-width">
      <h1>Search</h1>
      <Table
        header={searchSettings(queryParams, history, onDownloadResults)}
        loading={loading}
        loadingText="Searching"
        columnDefinitions={columnDefinitions}
        items={results}
        wrapLines={false}
        features={['pagination', 'sorting']}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
          </div>
        }
      >
        <TablePagination
          pageSize={pageSize}
          pagesCount={pagesCount}
          openEnd={false}
          onPaginationChange={(e) => {
            setPage(e.detail.currentPageIndex)
            setPageSize(e.detail.pageSize)
          }}
        />
        <TableSorting
          sortingColumn={sortBy}
          sortableColumns={columnDefinitions.map((c: any) => ({
            id: c.id as string,
          }))}
          onSortingChange={(e) => {
            setSortBy(e.detail.sortingColumn)
            setSortAscending(!e.detail.sortingDescending)
          }}
        />
        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
          <TableWrapLines
            label="Wrap text in lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          <TableContentSelector
            title="Visible Columns"
            options={[
              {
                label: 'Content',
                options: columnDefinitions.map((c: any) => ({
                  id: c.id as string,
                  label: c.header as string,
                  visible: c.id !== 'messageStatusRaw',
                })),
              },
            ]}
          />
          <TablePageSizeSelector
            title="Lines Per Page"
            options={[
              { value: 10, label: '10 lines' },
              { value: 25, label: '25 lines' },
              { value: 100, label: '100 lines' },
              { value: 1000, label: '1000 lines' }
            ]}
          />
        </TablePreferences>
      </Table>
    </div>
  )
}

export default SearchResults